import { gql } from "@apollo/client";

export const LIST_FUNDS = gql`
  query Funds($page: Int, $keywords: String) {
    funds (page: $page, keywords: $keywords) {
      page
      hasMore
      funds {
        ...FundFields
      }
    }
  }
`;

export const SET_FUND_USER_AND_ORGANIZATION = gql`
  mutation SetFundUserAndOrganization($fundId: ID!, $organizationId: ID, $userId: ID!) {
    setFundUserAndOrganization(fundId: $fundId, organizationId: $organizationId, userId: $userId) {
      ...FundFields
    }
  }
`;