import { Typography, Grid, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";

/**
 *
 * A link on the dashboard page.
 */
export default function DashboardLink({ title, description, icon, route } : { title: string; description: string; icon: any; route: string;}) {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={6}>
      <Card sx={{ mb: 2, cursor: 'pointer' }} onClick={() => navigate(route)}>
        <CardContent>
          <Grid container>
            <Grid item xs={2}>
              { icon }
            </Grid>
            <Grid item xs={10}>
              <Typography component="p" color="primary" variant="body1">
                { title }
              </Typography>
              <Typography component="p" variant="body2">
                { description }
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
