import { Button, Container, Grid, Stack, Link, Typography, Divider } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { CURRENT_CART } from "./cart.queries";
import CartEmpty from "./CartEmpty";
import LoadingError from "../Layout/LoadingError";
import Loading from "../Layout/Loading";
import CartItem from "./CartItem";
import CartSummary from "./CartSummary";
import { useAuth0 } from "@auth0/auth0-react";
/**
 *
 * CartPage
 */

export default function CartPage() {
  const { loading, error, data } = useQuery(CURRENT_CART);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  if (loading) return <Loading />;
  if (error) return <LoadingError />;

  const currentCart = data.currentCart;
  
  if (!currentCart || currentCart?.items?.length < 1) {
    return <CartEmpty />;
  }

  function goBack(e: any) {
    e.preventDefault();
    if (!currentCart.fund?.isWishlist) {
      navigate(`/donate/${currentCart.fund?._id}`);
    } else {
      navigate(-1);
    }
  }

  return (
    <Container sx={{ pt: 4 }}>
      <Grid container>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Typography variant="overline">
            Your donation to: <strong><Link component={RouterLink} color="secondary" to=".." onClick={goBack}>
            {currentCart.fund?.name}</Link></strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack>
            {currentCart.items?.map((item: any, index: number) => {
              return <CartItem key={index} cartItem={item} />;
            })}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Typography variant="body1" paragraph>
              <strong><Link component={RouterLink} color="secondary"  to=".." onClick={goBack}>{'<'} Go Back To {currentCart.fund?.name}</Link></strong>
              </Typography>

              <Typography variant="body1" paragraph>
                Got questions or concerns?<br />
                Feel free to <Link component={RouterLink} color="secondary" to="/contact">shoot us a message</Link>.
              </Typography>

              <Typography variant="body1" paragraph><strong>*Service Fee:</strong> We charge a service (convenience) fee of 5%. This means that when you donate $100.00's worth of items, it costs $5.00 for us to purchase and deliver those items on your behalf.</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                <CartSummary />
              </Grid>
              <Grid item xs={12} textAlign="right">
                <div
                    hidden={isAuthenticated}>
                  <Button
                    onClick={() => loginWithRedirect({ redirectUri: `${process.env.REACT_APP_BASE_URL}/cart` })}
                    variant="contained"
                    color="primary"
                  >
                    Login to complete checkout
                  </Button>
                </div>
                <Link hidden={!isAuthenticated} component={RouterLink} to="/checkout">
                  <Button color="secondary" variant="contained">
                    Checkout
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sx={{ display: { sm: 'none' }, mt: 2 }}>
                <Divider />
                <Typography variant="body1" paragraph sx={{ mt: 2 }}>
                  Got questions or concerns?<br />
                  Feel free to <Link component={RouterLink} color="secondary" to="/contact">shoot us a message</Link>.
                </Typography>

                <Typography variant="body1" paragraph><strong>*Service Fee:</strong> We charge a service (convenience) fee of 5%. This means that when you donate $100.00's worth of items, it costs $5.00 for us to purchase and deliver those items on your behalf.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
