import { Typography, Container, Grid } from "@mui/material";

const LoadingError = () => {
  return (
    <Container sx={{ pt: 4 }}>
      <Grid container>
        <Grid item>
          <Typography color="primary" align="center" textAlign="center">
            Whoops! That was unexpected. Please reach out to support to resolve
            this.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoadingError;
