import { Divider, Grid } from "@mui/material";
import DashboardBreadcrumbs from "./DasboardBreadcrumbs";
import DashboardMenu from "./DashboardMenu";

export default function DashboardHeader({ organizations, refreshOrgs, isAdmin }: { organizations?: any[], refreshOrgs: any, isAdmin: boolean }) {
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <DashboardBreadcrumbs organizations={organizations ?? []} />
      </Grid>
      <Grid item xs={12} sm={6} textAlign="right">
        <DashboardMenu organizations={organizations ?? []} refreshOrgs={refreshOrgs} isAdmin={isAdmin} />
      </Grid>
      <Grid item xs={12} sx={{ pt: 1 }}>
        <Divider />
      </Grid>
    </Grid>
  )
}