import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { IItemOptions } from "./item.model";
import FundItemPrice from "../Funds/FundItemPrice";
import { fundItemStyles } from "./item.styles";

/**
 *
 */
export default function ItemSearchSummary({ item, onSelectItem, itemId }: { item: IItemOptions, onSelectItem?: any, itemId: string|null|undefined }) {
  const isSelected = item._id === itemId;

  function selectItem() {
    if (onSelectItem) {
      onSelectItem(item);
    }
  }

  return (
    <Card sx={{
        maxWidth: 150,
        border: isSelected ? '2px solid green' : 'none'
      }} onClick={() => selectItem()}>
      <CardMedia
        component="img"
        sx={fundItemStyles}
        src={item.imageUrl}
        alt="Donation item"
      />
      <CardContent>
        <Typography paragraph>
          { item.name }
        </Typography>
        <FundItemPrice price={item.price} />
      </CardContent>
    </Card>
  );
}
