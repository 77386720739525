// TODO: Better handling / internationalization.
export const CURRENCIES_BY_COUNTRY: Record<string, string> = {
  Canada: 'CAD',
  Mexico: 'MXN',
}

// TODO - get this config on load from the server
export const REGIONS = [
  'ca',
  'us',
  'mx'
];

export const CURRENCIES = [
  'MXN',
  'CAD',
  'USD'
]

export const VENDOR_SOURCES = [
  'MERCADO_LIBRE',
  'AMAZON',
  'AMAZON_CA',
]