import { Container, Grid } from "@mui/material";

import { useQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import { GET_PAYMENT_INTENT_STATUS } from "./cart.queries";
import CheckoutConfirmationError from "./CheckoutConfirmationError";
import CheckoutConfirmationNotFound from "./CheckoutConfirmationNotFound";
import CheckoutConfirmationSuccess from "./CheckoutConfirmationSuccess";
import Loading from "../Layout/Loading";

export default function CheckoutConfirmationPage() {
  const [searchParams] = useSearchParams();
  const paymentIntentId = searchParams.get("payment_intent");

  const { data, loading } = useQuery(GET_PAYMENT_INTENT_STATUS, {
    variables: { paymentIntentId },
  });

  if (loading) {
    return <Loading />;
  }

  if (!data?.paymentIntentStatus?.id) {
    return (
      <Container sx={{ pt: 4 }}>
        <Grid container>
          <CheckoutConfirmationNotFound />
        </Grid>
      </Container>
    );
  }

  if (data?.paymentIntentStatus?.status === "succeeded") {
    return (
      <Container sx={{ pt: 4 }}>
        <CheckoutConfirmationSuccess />
      </Container>
    );
  } else {
    return (
      <Container sx={{ pt: 4 }}>
        <Grid container>
          <CheckoutConfirmationError />
        </Grid>
      </Container>
    );
  }
}
