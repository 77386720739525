import { TwitterShareButton, TwitterIcon } from 'react-share';

export default function TwitterShare({ url, title }: { url: string, title: string }) {

  return (
    <TwitterShareButton
      url={url}
      title={title}
      hashtags={['donatic', 'donate']}
    >
      <TwitterIcon size={32} round />
    </TwitterShareButton>
  );
};
