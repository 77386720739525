import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormatDate from "../shared/FormatDate";
import Price from "../shared/Price";

import { DonationModel } from "./donation.model";

/**
 * A summary of a Donation (to be shown in the DonationsList)
 */
export default function DonationSummary({ donation, hideButton }: { donation: DonationModel, hideButton?: boolean }) {
  // console.log('donation', donation);
  const navigate = useNavigate();

  function goToFund() {
    // TODO - check if is wishlist...
    navigate(`/funds/${donation.fund?._id}`);
  }

  function goToDonation() {
    navigate(`/dashboard/donations/${donation._id}`);
  }

  return (
  <Card sx={{ mb: 2 }} variant="outlined">
    <CardContent>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1"><strong>Donated on <FormatDate date={ donation?.createdAt } /></strong></Typography>

          <Typography variant="body1">Fund: { donation.fund?.name ?? 'Unknown fund' }</Typography>
          <Typography variant="body1">Total Items: { donation.items.length }</Typography>
          
          {
            !hideButton && (
              <Button variant="outlined" sx={{ mt: 1 }} onClick={goToDonation}>View Donation Details</Button>
            )
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>
            Subtotal: <Price value={donation.subtotal} />
          </Typography>
          <Typography>
            Service Fee: <Price value={donation.serviceFee} />
          </Typography>
          <Typography>
            Taxes: <Price value={donation.taxes} />
          </Typography>
          <Typography>
            Total: <Price value={donation.total} /> ({ donation.currency })
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
  );
}
