import { Accordion, AccordionDetails, AccordionSummary, Grid, Stack, Typography } from "@mui/material";
import { DonationItemModel } from "../../Donations/donation-item.model";
import AdminDonationItem from "../../Donations/AdminDonationItem";
import "react-datepicker/dist/react-datepicker.css";
import AdminDonationItemTableOrdered from "./AdminDonationItemTableOrdered";
import AdminDonationItemTablePending from "./AdminDonationItemTablePending";
import { getOrderedAndPendingItems } from "../../VendorOrderDonation/utils";

/**
 *
 */
export default function AdminDonationItems({ donation }: { donation: any }) {
  const { orderedItems, pendingItems } = getOrderedAndPendingItems({ donation });

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography component="h6" variant="h6" paragraph>Ordered Items</Typography>
        <AdminDonationItemTableOrdered donationItems={orderedItems} vendorOrderDonations={donation.vendorOrderDonations} />
      </Grid>
      <Grid item xs={12}>
        <Typography component="h6" variant="h6" paragraph>Pending Items</Typography>
        <AdminDonationItemTablePending donationItems={pendingItems} vendorOrders={donation.vendorOrders} />
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary>All Donation Items</AccordionSummary>
          <AccordionDetails>
              <Stack>
              {donation?.items?.map((item: DonationItemModel, index: number) => {
                const vendorOrders = donation.vendorOrders?.filter((vo: any) => vo.orderedItems.find((oi: any) => oi.item.itemId === item.itemId));
                
                return <AdminDonationItem key={index} donationItem={item} vendorOrders={vendorOrders} />;
              })}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
