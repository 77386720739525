import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Divider, Grid, Typography, Tabs, Tab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { GET_FUND, PUBLISH_FUND, UNPUBLISH_FUND } from "./fund.queries";
import Loading from "../Layout/Loading";
import LoadingError from "../Layout/LoadingError";
import { FiberManualRecord, Inventory, Settings, ShoppingBasket } from "@mui/icons-material";
import { useState } from "react";
import CampaignDetailDonationsComponent from "./CampaignDetailComponents/CampaignDetailDonations.component";
import CampaignDetailSettingsComponent from "./CampaignDetailComponents/CampaignDetailSettings.component";
import CampaignDetailItemsComponent from "./CampaignDetailComponents/CampaignDetailItems.component";
import MaxLengthText from "../shared/MaxLengthText";
import { displayNewLineStyles } from "../shared/displayNewLine.styles";
/**
 *
 * The Campaign CRUD.
 */
export default function CampaignDetailPage() {
  const params = useParams();
  const { organizationId, campaignId } = params;
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useQuery(GET_FUND, { variables: { fundId: campaignId } });
  const campaign = data?.fund;

  const [ publishFund ] = useMutation(PUBLISH_FUND, { variables: { fundId: campaignId } });
  function attemptPublishFund() {
    publishFund().then(() => refetch());
  }

  const [ unpublishFund ] = useMutation(UNPUBLISH_FUND, { variables: { fundId: campaignId } });
  function attemptUnpublishFund() {
    unpublishFund().then(() => refetch());
  }


  function attemptSaveCampaign() {
    // TODO.
  }

  const [ tab, setTab ] = useState(0);
  function onSwitchTab(_: React.SyntheticEvent, newValue: number) {
    setTab(newValue);
  }

  const ItemsTab = {
    component: (<CampaignDetailItemsComponent campaign={campaign} refetch={refetch} />),
    icon: (<Inventory />),
    title: 'Items'
  };

  const DonationsTab = {
    component: (<CampaignDetailDonationsComponent campaignId={campaignId} />),
    icon: (<ShoppingBasket />),
    title: 'Donations'
  };

  const SettingsTab = {
    component: (<CampaignDetailSettingsComponent campaign={campaign} refetch={refetch} />),
    icon: (<Settings />),
    title: 'Settings'
  };

  const tabs = [DonationsTab, ItemsTab, SettingsTab];

  function goToCampaignPage() {
    navigate(`/donate/${campaign._id}`);
  }

  if (loading) return <Loading />
  if (error || !campaign) return <LoadingError />

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={4}>
        <Box
          component="img"
          sx={{
            maxHeight: '150px',
          }}
          alt={campaign.name}
          src={campaign.imageUrl}
        />
          <Typography component="h5" variant="h5">
          { campaign.name }
        </Typography>
        <Typography paragraph variant="body1" sx={displayNewLineStyles}>
          <MaxLengthText maxLength={200} text={ campaign.description } />
        </Typography>

        <Button variant="outlined" color="primary" onClick={goToCampaignPage}>Go To Campaign Page</Button>

        <Divider sx={{ mt: 2, mb: 2 }} />

        {
          campaign.isPublished ?
          (
            <Box>
              <Typography paragraph><FiberManualRecord color="success" /> Your campaign is live.</Typography>
              <Button variant="outlined" color="primary" onClick={attemptUnpublishFund}>Turn Campaign Off</Button>
            </Box>
          ) :
          (
            <Box>
              <Typography paragraph><FiberManualRecord sx={{ color: '#999' }} /> Your campaign is not live.</Typography>
              <Button disabled={campaign.items.length < 1} variant="outlined" color="secondary" onClick={attemptPublishFund}>Publish Campaign</Button>
            </Box>
          )
        }

        {
          campaign.items.length < 1 ? (
            <Typography variant="body2">You cannot publish your campaign until you have items in your campaign.</Typography>
          ) : ''
        }
      </Grid>

      <Grid item xs={12} md={8}>
        <Grid container columnSpacing={4}>
          <Grid item xs={12}>
            <Tabs value={tab} onChange={onSwitchTab}>
              {
                tabs.map((tabEl, index) => {
                  return (<Tab label={tabEl.title} id={tabEl.title} icon={tabEl.icon} iconPosition="start" key={index} />)
                })
              }
            </Tabs>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <Grid container columnSpacing={4}>
                {
                  tabs[tab].component
                }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
