import { useAuth0 } from "@auth0/auth0-react";
import { FileUpload } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { ChangeEvent, useState } from "react";
import APIService from "../services/api.service";
import ErrorMessage from "../shared/ErrorMessage";

/**
 * Main Dash for an Org.
 */
export default function FileUploader({ promptText, property, organizationId, fundId, refetch } : { promptText: string, organizationId?: string; fundId?: string; property: string; refetch?: any }) {
  const [file, setFile] = useState<File>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hasError, setError] = useState<any>(null);
  const { getAccessTokenSilently } = useAuth0();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = async () => {
    if (!file) {
      return;
    }

    setLoading(true);
    setError(false);

    const token = await getAccessTokenSilently();

    const api = new APIService();

    const body = {
      organizationId,
      fundId,
      property
    };

    api.uploadFile({ file, authorization: token, body })
      .then(() => {
        setLoading(false);
        if (refetch) refetch();
      })
      .catch(() => {
        setError(true);
      });
  }

  return (
    <Box>
      <ErrorMessage error={hasError} message="We couldn't upload your image." />
      <Button
        variant="outlined"
        component="label"
        color="secondary"
      >
        <FileUpload />
        {
          file ? file.name : 'Select a File'
        }
        <input
          accept="image/*"
          type="file"
          hidden
          onChange={handleFileChange}
        />
      </Button>

      <Button
        variant="contained"
        disabled={!file && !isLoading}
        color="secondary"
        onClick={handleUploadClick}
      >
        {
          isLoading ? 'Uploading...' : `Upload ${promptText}`
        }
      </Button>
    </Box>
  );
}
