import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import CreateVendorOrderDonationComponent from "../CreateVendorOrderDonation/CreateVendorOrderDonationComponent";
import VendorOrderDonationListComponent from "./VendorOrderDonationListComponent";
import { useQuery } from "@apollo/client";
import { GET_LATEST_VENDOR_ORDERS } from "../../VendorOrder/vendor-order.queries";
import CreateVendorOrderFormComponent from "../../VendorOrder/CreateVendorOrderFormComponent";
import { ExpandMore } from "@mui/icons-material";
import { getOrderedAndPendingItems } from "../utils";

export default function VendorOrderDonationByDonationContainer({ donation }: { donation: any }) {
  const { loading, error, data, refetch } = useQuery(GET_LATEST_VENDOR_ORDERS);

  function onVendorOrderCreated() {
    refetch();
  }

  const { pendingItems } = getOrderedAndPendingItems({ donation });

  return (
    <Box>
      <Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Current Vendor Orders
        </AccordionSummary>
        <AccordionDetails>
          <VendorOrderDonationListComponent vendorOrderDonations={donation?.vendorOrderDonations} />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Create a Vendor Order
        </AccordionSummary>
        <AccordionDetails>
          <CreateVendorOrderFormComponent onVendorOrderCreated={onVendorOrderCreated} />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Add Items to A Vendor Order
        </AccordionSummary>
        <AccordionDetails>
          <CreateVendorOrderDonationComponent donationId={donation._id} donationItems={pendingItems} latestVendorOrders={data?.latestVendorOrders} />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}