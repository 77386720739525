import { Grid, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";

import { CURRENT_CART } from "./cart.queries";
import Price from "../shared/Price";

const CURRENCY_BY_REGION: Record<string, string> = {
  mx: 'MXN',
  ca: 'CAD',
  us: 'USD'
}

export default function CartSummary() {
  const { data } = useQuery(CURRENT_CART);

  const currentCart = data.currentCart;

  if (!currentCart || currentCart?.items?.length < 1) {
    return <div></div>;
  }
  const { subtotal, serviceFee, taxes, total } = currentCart.totals;
  const currency = CURRENCY_BY_REGION[currentCart.fund?.region] ?? 'MXN';

  return (
    <Grid item xs={12}>
      <Grid item xs={12} sx={{ marginTop: 2 }} textAlign="right">
        <Typography>
          <strong>
            Subtotal: <Price value={subtotal} />
          </strong>
        </Typography>

        <Typography>
          <strong>
            *Service Fee: <Price value={serviceFee} />
          </strong>
        </Typography>

        <Typography>
          <strong>
            Taxes: <Price value={taxes} />
          </strong>
        </Typography>
        <Typography color="primary">
          <strong>
            Total: <Price value={total} />
          </strong>
        </Typography>
      </Grid>
    </Grid>
  );
  // TODO: add suggested funds.
}
