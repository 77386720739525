import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormatDate from "../shared/FormatDate";
import Price from "../shared/Price";

import { DonationModel } from "./donation.model";
import { capitalizeStringTwo } from "../shared/capitalizeString";

/**
 * A summary of a Donation shown on the CampaignDetailPage (viewed by the fundraiser).
 */
export default function FundDonationSummary({ donation, viewDonation }: { donation: DonationModel, viewDonation: any }) {
  // console.log('donation', donation);
  const navigate = useNavigate();

  function goToFund() {
    // TODO - check if is wishlist...
    navigate(`/funds/${donation.fund?._id}`);
  }

  function goToDonation() {
    viewDonation(donation);
  }

  return (
  <Card sx={{ mb: 2 }} variant="outlined">
    <CardContent>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1"><strong>Donated on <FormatDate date={ donation?.createdAt } /></strong> by {donation?.user?.givenName} {donation?.user?.familyName}</Typography>

          <Button variant="outlined" sx={{ mt: 1, mb: 1 }} onClick={goToDonation}>View Donation Details</Button>

          <Typography>
            Delivery Status: { capitalizeStringTwo(donation?.deliveryStatus) }
          </Typography>
          <Typography>
            Total Items: { donation.items?.length }
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
  );
}
