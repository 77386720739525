import { Box, Button, Divider, Grid, Modal, Typography } from "@mui/material";
import DisplayAddress from "../../Address/DisplayAddress";
import CampaignImage from "../Steps/CampaignImage";
import { useState } from "react";
import { addFundItemModalStyle } from "../Steps/add-fund-item.styles";
import CampaignDetailInfoModal from "./CampaignDetailInfoModal.component";
import { displayNewLineStyles } from "../../shared/displayNewLine.styles";

export default function CampaignDetailSettingsComponent({ campaign, refetch }: { campaign: any, refetch: any }) {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Grid item xs={12}>
      <Typography variant="body1" paragraph textAlign="center">Campaign Information</Typography>
      <Typography variant="body2"><strong>Campaign Name:</strong> {campaign.name}</Typography>
      <Typography variant="body2"><strong>Campaign Description:</strong></Typography>
      <Typography variant="body2" sx={displayNewLineStyles}>{campaign.description}</Typography>

      <Button sx={{ mt: 1 }} fullWidth variant="outlined" onClick={handleOpenModal}>Edit Campaign Details</Button>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Typography variant="body1" paragraph textAlign="center">Address</Typography>
      <Typography paragraph variant="body2">Donations from this campaign will get delivered to the following address:</Typography>
      <DisplayAddress address={campaign?.address} />

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Typography variant="body1" paragraph textAlign="center">Campaign Image</Typography>
      <CampaignImage fund={campaign} saveCampaign={refetch} />

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={addFundItemModalStyle}>
          <CampaignDetailInfoModal campaign={campaign} refetch={refetch} closeModal={handleCloseModal} />
        </Box>
      </Modal>
    </Grid>
  )
}