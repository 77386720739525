import { useMutation } from "@apollo/client";
import { Edit, Email, Password, Person } from "@mui/icons-material";
import { Button, Grid, List, ListItemAvatar, ListItemText, Divider, ListItem, Avatar, Alert, AlertTitle, CircularProgress, IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import EditEmail from "./EditEmail";
import EditName from "./EditName";
import UserModel from "./user.model";
import { START_RESET_PASSWORD } from "./user.queries";
import { modalStyle } from "./user.styles";

export default function Profile({ user, onUserUpdated } : { user: UserModel, onUserUpdated: any }) {
  const [startResetPassword, { error, data, loading }] = useMutation(START_RESET_PASSWORD, { variables: { email: user.email } });

  const [ isNameModalOpen, setIsNameModalOpen ] = useState(false);
  const [ isEmailModalOpen, setIsEmailModalOpen ] = useState(false);

  const showSuccess = !error && data?.startResetPassword?.success;
  const showError = error || (!error && data?.startResetPassword?.errorMessage);

  function openNameModal() {
    setIsNameModalOpen(true);
  }

  function closeNameModal() {
    setIsNameModalOpen(false);
  }

  function openEmailModal() {
    setIsEmailModalOpen(true);
  }

  function closeEmailModal() {
    setIsEmailModalOpen(false);
  }

  return (
    <Grid item>
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
        }}
      >
        <ListItem
          secondaryAction={
            <IconButton onClick={openNameModal} edge="end" aria-label="edit">
              <Edit color="primary" />
            </IconButton>
          }
          >
          <ListItemAvatar>
            <Avatar>
              <Person />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={`${user.givenName} ${user.familyName}`} />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem
          secondaryAction={
            <IconButton onClick={openEmailModal} edge="end" aria-label="edit">
              <Edit color="primary" />
            </IconButton>
          }
          >
          <ListItemAvatar>
            <Avatar>
              <Email />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={user.email} />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Password />
            </Avatar>
          </ListItemAvatar>
          <Button disabled={loading} variant="outlined" onClick={() => startResetPassword()}>
            {
              loading ? (<CircularProgress />) : 'Reset Password'
            }
          </Button>
        </ListItem>
      </List>

      <Alert severity="success" sx={{ display: showSuccess ? 'block' : 'none' }}>
        <AlertTitle>Password Reset Email Sent</AlertTitle>
        Success! We have sent you an email to reset your password. Please check your email and follow the instructions there.
      </Alert>

      <Alert severity="error" sx={{ display: showError ? 'block' : 'none' }}>
        <AlertTitle>Could not reset password</AlertTitle>
        There was an error resetting your password. Please contact support.
      </Alert>

      <Modal open={isNameModalOpen} onClose={closeNameModal}>
        <Box sx={modalStyle}>
          <EditName givenName={user.givenName } familyName={user.familyName } onUserUpdated={onUserUpdated} closeModal={closeNameModal} />
        </Box>
      </Modal>

      <Modal open={isEmailModalOpen} onClose={closeEmailModal}>
        <Box sx={modalStyle}>
          <EditEmail email={user.email} onUserUpdated={onUserUpdated} closeModal={closeEmailModal} />
        </Box>
      </Modal>
    </Grid>
  );
};
