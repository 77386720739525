/**
 *
 * A ListItem for the Funds list.
 */
import { Typography, CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link as RouterLink } from "react-router-dom";

import MaxLengthText from "../shared/MaxLengthText";
import { displayNewLineStyles } from "../shared/displayNewLine.styles";
import { Organization } from "./organization.model";

interface IProps {
  organization: Organization;
}

export default function OrganizationListItem({ organization }: IProps) {
  return (
    <Card sx={{ maxWidth: 345, margin: "0 auto" }}>
      <CardActionArea component={RouterLink} to={`/o/${organization.uuid}`}>
        <CardMedia
          component="img"
          height="140"
          image={organization.logoUrl}
          alt={organization.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" color="primary" component="div">
            {organization.name}
          </Typography>
          <Typography gutterBottom variant="body2" color="text.secondary" sx={displayNewLineStyles}>
            <MaxLengthText maxLength={200} text={organization.description} />
          </Typography>
          <Typography color="primary" variant="body2" component="div">
            {/* Location: TODO: REGION */}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
