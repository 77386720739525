import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { StyledTableCell, StyledTableRow, IColumnWithElement } from "../../AdminDash/shared";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Checkbox, Grid, Link, Paper, TextField, Typography } from '@mui/material';
import Price from '../../shared/Price';
import { useState } from 'react';

const columns: IColumnWithElement[] = [
  {
    header: 'Image',
    getValue: (donationItem: any) => {
      return donationItem.item.imageUrl ? (
        <img src={donationItem.item.imageUrl} alt={donationItem.item.name} style={{ width: '50px', height: '50px' }} />
      ) : (
        <img src="https://via.placeholder.com/50" alt={donationItem.item.name} style={{ width: '50px', height: '50px' }} />
      )
    }
  },
  {
    header: 'Name',
    getValue: (donationItem: any) => {
      return (<Typography>
        {donationItem.item?.name ?? ''}<br />
        <Link href={donationItem.item.vendorUrl} target="_blank" color="secondary">Vendor Link</Link>
      </Typography>)
    }
  },
  {
    header: 'Quantity',
    getValue: (donationItem: any) => donationItem?.quantity ?? ''
  },
  {
    header: 'Donatic Price',
    getValue: (donationItem: any) => {
      // TODO - show price in the currency of the donation. For now, is only USD
      return (<Price price={donationItem.item.price} />)
    }
  },
];

interface ISelectedItem {
  donationItemId: string,
  vendorValue: string,
  quantity: number,
}

export default function VendorOrderDonationItemTableForm({ donationItems, onSelectedItems }: { donationItems: any[], onSelectedItems: any }) {
  const [ selectedItems, setSelectedItems ] = useState<ISelectedItem[]>(donationItems?.slice().map((donationItem: any) => {
    return {
      donationItemId: donationItem._id,
      itemId: donationItem.item._id,
      vendorValue: '0',
      quantity: donationItem.quantity,
    }
  }));

  const [isChecked, setIsChecked] = useState(donationItems?.slice().fill(false));

  const toggleCheckboxValue = (index: number) => {
    setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)));
  }


  if (onSelectedItems) {
    const selected = donationItems.map((_, index) => {
      if (isChecked[index]) {
        return selectedItems[index]
      }
    }).filter((v) => v);
    // Ensure we pass the vendorValue as a number.
    onSelectedItems(selected.map((selected: any) => {
      return { ...selected, vendorValue: Number(selected.vendorValue) }
    }));
  }

  function updateVendorValue(index: number, e: any) {
    const value = e.target.value;
    setSelectedItems(selectedItems.map((v, i) => (i === index ? {...v, vendorValue: value} : v)));
  }

  return (
  <Grid container>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {
                columns.map((column: any, index: number) => {
                  return (<StyledTableCell key={index}>{column.header}</StyledTableCell>)
                })
              }
              <StyledTableCell>Vendor Price</StyledTableCell>
              <StyledTableCell>Quantity (VO)</StyledTableCell>
              <StyledTableCell>Select</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {donationItems?.map((donationItem: any, index: number) => {
            return (
              <StyledTableRow key={index}>
                {
                  columns.map((column: any, i: number) => {
                    return (<StyledTableCell key={i}>{column.getValue(donationItem)}</StyledTableCell>)
                  })
                }
                <StyledTableCell>
                  <TextField value={selectedItems[index].vendorValue} disabled={!isChecked[index]} onChange={(e) => updateVendorValue(index, e)} />
                  <Price value={Number(selectedItems[index].vendorValue)} />
                </StyledTableCell>
                <StyledTableCell>
                  <TextField value={selectedItems[index].quantity} disabled={!isChecked[index]} />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Checkbox
                    key={index}
                    checked={isChecked[index]}
                    onClick={() => toggleCheckboxValue(index)}
                  />
                </StyledTableCell>
              </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
);
}