import { gql } from "@apollo/client";

export const GET_DONATIONS_BY_USER = gql`
  query GetDonationsByUser {
    donationsByUser {
      _id
      createdAt
      fund {
        ...FundFields
      }
      items {
        item {
          ...ItemFields
        }
        quantity
      }
      currency,
      subtotal,
      serviceFee,
      taxes,
      total,
      status,
      deliveryStatus
    }
  }
`;

export const GET_DONATIONS_BY_FUND = gql`
  query GetDonationsByFund($fundId: ID) {
    donationsByFund(fundId: $fundId) {
      _id
      createdAt
      thankYouNoteSentAt
      deliveryStatus
      items {
        item {
          ...ItemFields
        }
        quantity
      }
      user {
        givenName
        familyName
        email
      }
      currency,
      subtotal,
      serviceFee,
      taxes,
      total
    }
  }
`;

export const GET_DONATIONS_BY_ORG = gql`
  query GetDonationsByOrg($orgId: ID) {
    donationsByOrg(orgId: $orgId) {
      _id
      createdAt
      fund {
        _id
        name
      }
      items {
        item {
          ...ItemFields
        }
        quantity
      }
      user {
        givenName
        familyName
        email
      }
      status,
      deliveryStatus
      currency,
      subtotal,
      serviceFee,
      taxes,
      total
    }
  }
`;

export const GET_DONATION = gql`
  query GetDonation($donationId: ID) {
    donation(donationId: $donationId) {
      _id
      createdAt
      fund {
        _id
        name
        isWishlist
        organization {
          name
          uuid
        }
      }
      items {
        item {
          ...ItemFields
        }
        quantity
      }
      user {
        givenName
        familyName
        email
      }
      currency,
      subtotal,
      serviceFee,
      taxes,
      total,
      status,
      deliveryStatus
      thankYouNoteSentAt
      thankYouNote
    }
  }
`;

export const GET_DONATION_AUTHENTICATED = gql`
  query GetDonation($donationId: ID) {
    getDonation(donationId: $donationId) {
      _id
      createdAt
      fund {
        ...FundFields
      }
      items {
        item {
          ...ItemFields
        }
        quantity
      }
      currency,
      subtotal,
      serviceFee,
      taxes,
      total,
      status,
      deliveryStatus
      address {
        ...AddressFields
      }
    }
  }
`;

export const SEND_DONOR_THANK_YOU = gql`
  mutation SendDonorThankYou($donationId: ID!, $message: String!) {
    sendDonorThankYou(donationId: $donationId, message: $message) {
      success
      errorMessage
    }
  }
`;
