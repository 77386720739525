/**
 *
 * List of Campaigns Only
 */
import { Container, Divider, Grid, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";

import { GET_CAMPAIGNS } from "./fund.queries";

import Fund from "./Fund";
import FundModel from "./fund.model";
import LoadingError from "../Layout/LoadingError";
import Loading from "../Layout/Loading";
import PaginationButtons from "./components/PaginationButtons";
import { useState } from "react";

export default function CampaignList() {
  const [ page, setPage ] = useState(0);

  const { loading, error, data, fetchMore } = useQuery(GET_CAMPAIGNS, { variables: { page } });

  function loadMore() {
    const nextPage = page + 1;
    fetchMore({ variables: { page: nextPage } });
    setPage(page + 1);
  }

  if (loading) return <Loading />;
  if (error) return <LoadingError />;

  const funds = data.publicCampaigns?.funds ?? [];

  return (
    <Container sx={{ pt: 4 }}>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Typography
            sx={{ mb: 2 }}
            color="primary"
            variant="h5" component="h1">
            Latest Campaigns
          </Typography>
          <Typography paragraph variant="body1">
            Browse the campaigns below to see how you can make a direct impact.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        {funds.map((fund: FundModel, index: number) => {
          return (
            <Grid key={index} item xs={12} sm={12} md={4}>
              <Fund fund={fund} />
            </Grid>
          );
        })}
        {funds.length < 1 ? (

          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography>No campaigns currently running. Check back later, or sign up to start one.</Typography>
          </Grid>
        ) : (
          <div></div>
        )}
      </Grid>
      <PaginationButtons loadMore={loadMore} hasMore={data.publicCampaigns?.hasMore} sx={{ mt: 2 }} />
    </Container>
  );
}
