/**
 *
 * Breadcrumbs for the FundPage
 */
import { Link, Breadcrumbs } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import FundModel from "../Funds/fund.model";

export default function FundPageBreadcrumbs({ fund }: { fund: FundModel }) {
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          component={RouterLink}
          to="/donate"
          color="inherit"
          underline="hover"
        >
          Browse Drives
        </Link>
        <Link
          component={RouterLink}
          to="/donate"
          color="secondary"
          underline="hover"
          aria-current="page"
        >
          {fund.name}
        </Link>
      </Breadcrumbs>
    </div>
  );
}
