import { Button, Grid, Typography } from "@mui/material";
import FundModel from "../fund.model";
import { useParams } from "react-router-dom";
import SocialShare from "../../Social/SocialShare";
import { Link as RouterLink } from "react-router-dom";
/**
 *
 */
export default function SaveFundConfirmation({ fund, saveCampaign }: { fund: FundModel, saveCampaign: any }) {
  const params = useParams();
  // TODO: Show overview of Fund.
  // TODO: show confirmation modal: go to public page, or go back to dashboard.
  const isPublished = fund.isPublished;

  const shareURL = window.location.href;
  const shareTitle = `Donatic Campaign: ${fund.name}`;
  const shareEmailSubject = `Check out this Donatic campaign: ${fund.name}`;
  const shareEmailBody = `Help out by donating items to the campaign ${fund.name} on Donatic!`;

  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {
        isPublished ?
        (
          <Grid item xs={12}>
            <Typography gutterBottom component="h5" variant="h5">
              Your campaign is live!
            </Typography>
            <Button component={RouterLink} to={`/donate/${fund._id}`} variant="outlined">Go to campaign page</Button>
            <Typography sx={{ mt: 2 }} gutterBottom variant="body1" color="primary" component="div">
              Share your campaign.
            </Typography>
            <Typography gutterBottom variant="body1" component="div">
              Help spread the word for your campaign by sharing below.
            </Typography>
            <SocialShare url={shareURL} title={shareTitle} quote={shareEmailBody} emailSubject={shareEmailSubject} emailBody={shareEmailBody} />
          </Grid>
        ) :
        (
          <Grid item xs={12}>
            <Typography gutterBottom component="h5" variant="h5">
              Your campaign is not published.
            </Typography>

            <Typography gutterBottom component="h5" variant="body1">
              Donors will not be able to view your campaign until you publish it by clicking the button below.
            </Typography>

            <Button variant="contained" color="secondary" onClick={saveCampaign}>Publish Campaign</Button>
          </Grid>
        )
      }
    </Grid>
  );
}
