/**
 *
 * GoalCompleted
 */
import { Verified } from "@mui/icons-material";
import {
  Typography,
  Grid,
} from "@mui/material";



export default function GoalCompleted() {

  return (
    <Grid item xs={12}>
      <Typography
        variant="body1"
        color="success.contrastText"
        component="p"
        textAlign="center"
        sx={{ pt: 2 }}
      >
        <Verified />
      </Typography>
      <Typography
        variant="body1"
        color="success.contrastText"
        component="p"
        textAlign="center"
      >
      <strong>
        Goal completed!
        </strong>
      </Typography>
    </Grid>
  );
}
