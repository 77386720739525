import { useMutation } from "@apollo/client";
import { Box, Button, Card, CardActions, CardContent, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { IAddress } from "./address.model";
import { DELETE_ADDRESS } from "./address.queries";
import SaveAddress from "./SaveAddress";
import { saveAddressModalStyle } from "./address.styles";

export default function Address({ address, refreshAddresses, showButtons }: { address: IAddress, refreshAddresses: any, showButtons?: boolean }) {
  const [ deleteAddress ] = useMutation(DELETE_ADDRESS, { variables: { addressId: address._id } });

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  async function removeAddress() {
    deleteAddress()
      .then(() => refreshAddresses());
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Card sx={{ width: '100%' }}>
        <CardContent>
          <Typography variant="body1">{address.addressee}</Typography>
          <Typography variant="body1">{address.line1}</Typography>
          <Typography variant="body1">{address.line2}</Typography>
          <Typography variant="body1">{address.city}</Typography>
          <Typography variant="body1">{address.country}</Typography>
          <Typography variant="body1">{address.zipcode}</Typography>
          <Typography variant="body1">{address.province}</Typography>
          <Typography variant="body1">{address.specialInstructions}</Typography>
        </CardContent>
        {
          showButtons ?
          (
          <CardActions>
            <Button onClick={() => handleOpenModal()} variant="outlined">Edit</Button>
            <Button onClick={() => removeAddress()}  variant="outlined">Delete</Button>
          </CardActions>
          ) : ''
        }
        
        </Card>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={saveAddressModalStyle}>
          <SaveAddress address={address} closeModal={handleCloseModal} />
        </Box>
      </Modal>
    </Box>
  );
}
