/**
 *
 * GoalCompleted
 */

import { Box, FormControl, FormGroup, FormLabel } from "@mui/material";
import FundModel from "../fund.model";
import NumberInput from "../../shared/NumberInput";

export default function SetFundItemQuantity({ quantity, setQuantity }: { quantity: number, setQuantity: any }) {

  function updateQuantity(e: any, value: any) {
    e.preventDefault();

    setQuantity(value);
  }

  return (
    <Box sx={{
      '& > :not(style)': { m: 1, width: '25ch' },
    }}>
      <FormControl>
        <FormGroup sx={{ mb: 2 }}>
          <FormLabel sx={{ mb: 2 }}>
            How many do you want to raise?
          </FormLabel>
          <NumberInput
            onChange={(e: any, value: any) => updateQuantity(e, value)}
            value={quantity}
            min={0}
            max={FundModel.MAX_ITEM_GOAL} />
        </FormGroup>
      </FormControl>
    </Box>
  );
}
