import { Box, Grid, Typography, ImageList, ImageListItem, Link, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import './sales.css'

import headlineBackground from '../../assets/bahia-banderas/headline.jpg';
import pelicanos from '../../assets/bahia-banderas/pelicanos.jpg';
import sayulitaAnimals from '../../assets/bahia-banderas/sayulita-animals.jpg';
import sayulitaMusic from '../../assets/bahia-banderas/sayulita-music.jpg';
import laLancha from '../../assets/bahia-banderas/la-lancha.jpg';
import waves from '../../assets/bahia-banderas/waves.jpg';
import bonfire from '../../assets/bahia-banderas/bonfire.jpg';
import northside from '../../assets/bahia-banderas/northside-beach.jpg';

// Logos
import { themeColours } from "../../theme";
import { LocalMall, PanToolAlt, VolunteerActivism } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { GET_SALES_PAGE } from "../sales-page.queries";
import Loading from "../../Layout/Loading";
import LoadingError from "../../Layout/LoadingError";
import Fund from "../../Funds/Fund";

export default function BahiaBanderasPage() {
  const { data, loading, error } = useQuery(GET_SALES_PAGE, { variables: { identifier: 'bahia-banderas' } });

  if (loading) return <Loading />;
  if (error) return <LoadingError />;

  // Image Grid
  const itemData = [
    {
      img: sayulitaAnimals,
      title: 'Breakfast',
      rows: 2,
      cols: 2,
    },
    {
      img: pelicanos,
      title: 'Hats',
    },
    {
      img: sayulitaMusic,
      title: 'Camera',
      rows: 2
    },
    {
      img: laLancha,
      title: 'Coffee',
    },
    {
      img: headlineBackground,
      title: 'Burger',
      rows: 2
    },
    {
      img: waves,
      title: 'Honey',
      author: '@arwinneil',
      rows: 2,
      cols: 2,
    },
    {
      img: bonfire,
      title: 'Basketball',
    },
    {
      img: northside,
      title: 'Fern',
    },
  ];

  const logos = data?.salesPage?.organizations.map((org: any) => {
    return {
      img: org.logoUrl,
      title: org.name,
      path: `/o/${org.uuid}`
    }
  });

  const campaigns = data?.salesPage?.organizations?.filter((org: any) => !!org.latestCampaign).map((org: any) => {
    const { latestCampaign } = org;
    return {
      name: latestCampaign.name,
      _id: latestCampaign._id,
      description: latestCampaign.description,
      imageUrl: latestCampaign.imageUrl,
      organization: org
    }
  });

  function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <Grid container sx={{ mt: '2rem' }}>

      <Grid item xs={12} md={6} sx={{ padding: '2rem', paddingLeft: '4rem', paddingRight: '4rem' }}>
        <Typography variant="h5" component="h2" align="left" color="primary">
          Give back to the community that gives each of us so much.
        </Typography>

        <Typography sx={{ mt: '2rem' }} variant="subtitle1" align="left" color="textSecondary">
          Support the local community of Bahía Banderas by donating to local causes and organizations.
        </Typography>

        <Typography sx={{ mt: '1rem' }} variant="subtitle1" align="left" color="textSecondary">
          Donatic provides a unique platform to donate physical items online to local organizations in Bahía Banderas. <strong>Choose a campaign</strong>, <strong>select the items you wish to donate</strong>, and <strong>we deliver them directly to the organizations</strong>.
        </Typography>

        <Box sx={{ width: '100%', minHeight: '100px', mt: '1rem', mb: '2rem' }}>
          {
            logos.map((logo: any) => {
              return (
                <Link component={RouterLink} key={logo.img} to={logo.path}>
                  <img src={logo.img} key={logo.img} alt={logo.title} style={{ width: 'auto', height: '50px', margin: '1rem' }} />
                </Link>
              )
            })
          }
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <ImageList
          sx={{ width: '100%', height: 450 }}
          variant="quilted"
          cols={4}
          rowHeight={121}
        >
          {itemData.map((item) => (
            <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
              <img
                {...srcset(item.img, 121, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>

      <Grid item xs={12} sx={{ overflow: 'hidden', marginBottom: '-50px', paddingTop: '50px' }}>
        <Box sx={{ backgroundColor: themeColours.secondary, transform: 'rotate(1deg)', minHeight: '100px' }}>
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ backgroundColor: themeColours.secondary, padding: '0', zIndex: 100 }} alignContent="center" alignItems="center">

        <List sx={{ maxWidth: '300px', margin: '0 auto' }}>
          <ListItem>
            <ListItemIcon sx={{ color: 'white' }}>
              <LocalMall />
            </ListItemIcon>
            <ListItemText>
              <Typography color="white" fontWeight="bold">Donate supplies online now.</Typography>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ color: 'white' }}>
              <PanToolAlt />
            </ListItemIcon>
            <ListItemText>
              <Typography color="white">Choose your impact.</Typography>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ color: 'white' }}>
              <VolunteerActivism />
            </ListItemIcon>
            <ListItemText>
              <Typography color="white">Support your community.</Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={12} sx={{ overflow: 'hidden', marginTop: '-50px', paddingBottom: '50px', zIndex: 0 }}>
        <Box sx={{ backgroundColor: themeColours.secondary, transform: 'rotate(1deg)', minHeight: '100px' }}>
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ padding: '2rem' }}>
        <Typography textAlign="center" color="primary" sx={{ mb: 5 }}>Select a campaign below to select supplies to donate now.</Typography>
      
        <Grid container spacing={4}>
        {
          campaigns.map((campaign: any) => {
            return (
              <Grid key={campaign.name} item xs={12} sm={12} md={4}>
                <Fund fund={campaign} />
              </Grid>
            )
          })
        }
        </Grid>
      </Grid>
    </Grid>
  )
}