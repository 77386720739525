import { Typography, Grid, Link, Button, Card, CardMedia, CardContent, Alert, Snackbar } from "@mui/material";
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { Flag, VolunteerActivism } from "@mui/icons-material";
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardLink from "./DashboardLink";
import { useQuery } from "@apollo/client";
import Loading from "../Layout/Loading";
import LoadingError from "../Layout/LoadingError";
import { GET_ORGANIZATION } from "../Organizations/organization.queries";
import { useParams } from "react-router-dom";
import SocialShare from "../Social/SocialShare";
import FileUploader from "../File/FileUploader";
import LatestDonation from "./LatestDonation";
import { Box } from "@mui/system";
import { useState } from "react";

/**
 * Main Dash for an Org.
 */
export default function OrganizationDashboard() {
  const params = useParams();
  const { organizationId } = params;
  // TODO: Query for OrgSummary (latest donation, active funds.)
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { data, loading, error, refetch } = useQuery(GET_ORGANIZATION, { variables: { uuid: organizationId } });

  if (loading) return <Loading />
  if (error) return <LoadingError />

  const { organization } = data;
  
  // Social Share content
  const orgPath = `/o/${organizationId}`;
  const shareURL = window.location.protocol + '//' + window.location.host + orgPath;
  const shareTitle = `We're on Donatic! Donate items to ${organization.name} on our Donatic page.`;
  const shareEmailSubject = `Checkout ${organization.name} on Donatic`;
  const shareEmailBody = `Donate physical goods to use on our Donatic page.`;

  function onCopyLink() {
    navigator.clipboard.writeText(shareURL);
    setOpenSnackbar(true);
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12} sm={6}>
        <Typography component="h4" variant="h4">{ organization.name } Dashboard</Typography>
      </Grid>
      <Grid item xs={12} sm={6} textAlign="right">
        {
          organization.logoUrl ? <img height="60" src={organization.logoUrl} /> :
          <FileUploader promptText="Logo" property="logoUrl" organizationId={organization
          ._id} refetch={refetch} />
        }
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <LatestDonation />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} textAlign={{ xs: 'left', sm: 'left', md: 'right', lg: 'right' }}>
        <Button href={`/o/${organizationId}`} variant="outlined">View Your Donatic Page</Button>
      </Grid>
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 3, mb: 2 }}>
        <DashboardLink
          title="Campaigns"
          description="Create, edit and manage campaigns for your organization."
          icon={(<Flag />)}
          route={`/dashboard/organizations/${organizationId}/campaigns`}
        />

        {<DashboardLink
          title="Donations"
          description="View all donations you've received on Donatic."
          icon={(<VolunteerActivism />)}
          route={`/dashboard/organizations/${organizationId}/donations`}
        />}

        <DashboardLink
          title="Organization Addresses"
          description="View, create, edit and delete your organization addresses."
          icon={(<ImportContactsIcon />)}
          route={`/dashboard/organizations/${organizationId}/addresses`}
        />

        <DashboardLink
          title="Organization Settings"
          description="View, edit and manage settings like email, logo and default address."
          icon={(<SettingsIcon />)}
          route={`/dashboard/organizations/${organizationId}/settings`}
        />
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h5">Share Your Donatic Page</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography component="p" variant="body1" paragraph>Your Donatic page link is:</Typography>
          <Alert severity="info" sx={{ mb: 2 }}>{shareURL}</Alert>
          <Button variant="contained" onClick={onCopyLink}>Copy Link</Button>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleClose}
            message="Link copied"
          />

          <Typography sx={{ mt: 2 }} component="p" variant="body1">Spread the word that you're on Donatic!</Typography>
          <SocialShare url={shareURL} title={shareTitle} quote={shareEmailBody} emailSubject={shareEmailSubject} emailBody={shareEmailBody} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card sx={{ display: 'flex' }}>
            <CardMedia
              component="img"
              sx={{ width: 151, height: 151 }}
              image={organization.qrCode}
            ></CardMedia>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography>
                  This is your custom QR Code for <Link href={`/o/${organizationId}`}>your Donatic page</Link>.
                </Typography>
                <Typography>
                  Share this QR code to bring donors to your Donatic wishlist and campaigns.
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
