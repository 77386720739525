/**
 *
 * View a single Fund
 */
import { Box, Button, Container, Link, Grid, Typography } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { useParams, Link as RouterLink } from "react-router-dom";

import { GET_FUND } from "./fund.queries";

import FundItemModel from "./fund-item.model";
import FundItem from "./FundItem";
import LoadingError from "../Layout/LoadingError";
import FundPageBreadcrumbs from "./FundPageBreadcrumbs";
import FundPageSkeleton from "./FundPageSkeleton";
import { CURRENT_CART, RESET_CART } from "../Cart/cart.queries";
import SocialShare from "../Social/SocialShare";
import { useEffect } from "react";
import FloatingCartButton from "../Cart/FloatingCartButton";
import { displayNewLineStyles } from "../shared/displayNewLine.styles";
import FundCompletedBanner from "./components/FundCompletedBanner";

export default function FundPage() {
  const params = useParams();

  const { loading, error, data } = useQuery(GET_FUND, {
    variables: { fundId: params.fundId },
  });

  const { data: currentCartData } = useQuery(CURRENT_CART);
  const cartItems = currentCartData.currentCart?.items ?? [];

  const [ resetCart ] = useMutation(RESET_CART);

  // On load, reset the cart to the current fund.
  // This should get updated via subscription.
  useEffect(() => {
    if (data?.fund && currentCartData.currentCart?._id && currentCartData.currentCart?.fundId !== data?.fund._id) {
      resetCart({ variables: { cartId: currentCartData.currentCart._id, fundId: params.fundId }});
    }
  }, [data?.fund, currentCartData?.currentCart._id]);

  // TODO: on leave - show alert - "Are you sure you want to navigate away? You may lose items in your cart."

  if (error) return <LoadingError />;

  if (loading) {
    return (
      <Container sx={{ pt: 4 }}>
        <Grid container columnSpacing={2}>
          <FundPageSkeleton />
        </Grid>
      </Container>
    );
  }

  const { fund } = data;

  const completedFundItems = fund.items.filter((item: FundItemModel) => {
    return item.totalGoal - item.raised < 1;
  });

  // Still raising.
  const fundItems = fund.items.filter((item: FundItemModel) => {
    return item.totalGoal - item.raised > 0;
  });

  const isFundCompleted = completedFundItems.length === fund.items.length;

  const defaultUrl: string =
    "https://mui.com/static/images/cards/contemplative-reptile.jpg";
  const imageUrl: string = fund.imageUrl || defaultUrl;

  const defaultDescription: string = "No description.";
  const description: string = fund.description || defaultDescription;

  const shareURL = window.location.href;
  const shareTitle = `Donatic Campaign: ${fund.name}`;
  const shareEmailSubject = `Check out this Donatic campaign: ${fund.name}`;
  const shareEmailBody = `Help out by donating items to the campaign ${fund.name} on Donatic!`;

  if (!fund)
    return (
      <Container sx={{ pt: 4 }}>
        <Grid container>
          <Grid item xs={12}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              Something went wrong, we couldn't find that campaign. Please contact us if this problem persists.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );

  return (
    <Container sx={{ pt: 4 }}>
      {
        !isFundCompleted && (<FloatingCartButton isDisabled={!fund.isPublished} count={cartItems.length} />)
      }
      <Grid container>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <FundPageBreadcrumbs fund={fund} />
        </Grid>
      </Grid>
      {
        isFundCompleted && (<FundCompletedBanner />)
      }
      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={4}>
          <img alt={fund.name} src={imageUrl} />
          <Typography gutterBottom variant="h5" color="primary" component="div">
            {fund.name}
          </Typography>
          <Typography gutterBottom variant="body2" color="text.secondary" sx={displayNewLineStyles}>
            {description}
          </Typography>
          <Typography  color="primary" variant="body2" sx={{ mb: 3 }} component="div">
            Organized by: {
              fund.organization?.name && fund.organization?.uuid ?
              (<Link color="secondary" component={RouterLink} to={`/o/${fund.organization.uuid}`}>{fund.organization.name}</Link>) :
              fund.user?.givenName
            }
          </Typography>

          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Typography gutterBottom variant="h5" color="primary" component="div">
              Help spread the word
            </Typography>
            <SocialShare url={shareURL} title={shareTitle} quote={shareEmailBody} emailSubject={shareEmailSubject} emailBody={shareEmailBody} />
          </Box>
        </Grid>

        {
          fund.isPublished ? 
          (
            <Grid item xs={12} md={8}>
              {fundItems.map((item: FundItemModel, index: number) => {
                return <FundItem key={index} fundItem={item} fundId={fund._id} cartItems={cartItems} />;
              })}

              {completedFundItems.map((item: FundItemModel, index: number) => {
                return <FundItem key={index} fundItem={item} fundId={fund._id} cartItems={cartItems} />;
              })}
            </Grid>
          ) : (
            <Grid item xs={12} md={8}>
              <Typography variant="h6" sx={{ mb: 2 }} color="text.secondary" component="div">
                Hello! Looks like this campaign has completed fundraising.
              </Typography>
                {
                  fund.organization?.name && fund.organization?.uuid &&
                  (<Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }} color="primary" component="div">
                      You may be looking for another campaign by {fund.organization.name}.
                    </Typography>

                    <Button
                      variant="contained"
                      color="secondary"
                      component={RouterLink} to={`/o/${fund.organization.uuid}`}>
                      View other campaigns by {fund.organization.name}.
                    </Button>
                  </Box>)
                }
            </Grid>
          )
        }

        <Grid item xs={12} md={4} sx={{ display: { xs: "block", md: "none" } }}>
          <Typography gutterBottom variant="h5" color="primary" component="div">
            Help spread the word
          </Typography>
          <SocialShare url={shareURL} title={shareTitle} quote={shareEmailBody} emailSubject={shareEmailSubject} emailBody={shareEmailBody}  />
        </Grid>
      </Grid>
    </Container>
  );
}
