/**
 *
 * A ListItem for the Funds list.
 */
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";

// Assets
import { CartItemModel } from "./cart-item.model";
import Price from "../shared/Price";
import Quantity from "../shared/Quantity";
import CartItemQuantity from "./CartItemQuantity";

interface ICartItemProps {
  cartItem: CartItemModel;
}

export default function CheckoutItem({ cartItem }: ICartItemProps) {
  // TODO: Future - use this from internationalization.
  const quantityDelimiter = ",";

  return (
    <Card sx={{ display: "flex", marginBottom: 2 }}>
      <Box sx={{ display: "block", flexDirection: "column", width: "100%" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={8}>
              <Typography component="div" variant="body1">
                {cartItem.item.name}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Grid container spacing={0}>
                <Grid
                  item
                  sx={{ pt: { xs: 2, sm: 0 } }}
                  xs={12}
                  textAlign="right"
                >
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    <strong>
                      Price:{" "}
                      <Price
                        price={cartItem.item.price}
                      />
                    </strong>
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    Quantity:{" "}
                    <Quantity
                      quantity={cartItem.quantity}
                      delimiter={quantityDelimiter}
                    />
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    component="div"
                  >
                    <strong>
                      Total:{" "}
                      <CartItemQuantity price={cartItem.item.price} quantity={cartItem.quantity} />
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </Card>
  );
}
