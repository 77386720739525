import { Box, Grid, Link, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { cartVar } from "../apollo/cache";
import { CURRENT_CART } from "./cart.queries";
import TrustBox from "../Dashboard/trustbox";

export default function CheckoutConfirmationSuccess() {
  const { data } = useQuery(CURRENT_CART);

  useEffect(() => {
    cartVar({
      _id: data.currentCart._id,
      items: [],
      totals: { subtotal: 0, taxes: 0 },
      fundId: data.currentCart.fundId
    });
  }, [data.currentCart._id]);

  return (
    <Grid container>
      <Grid item xs={12} md={3}></Grid>
      <Grid item xs={12} md={6}>
        <Box>
          <Typography component="h1" variant="h4">
            Success!
          </Typography>
        </Box>
        <Box sx={{ marginTop: 3 }}>
          <Typography paragraph sx={{ typography: "body1" }} color="primary">
            <span className="emoji" role="img">
              🙌
            </span>{" "}
            Your donation has been received. Thank you for donating!
          </Typography>

          <Typography paragraph sx={{ typography: "body1" }}>
            You will receive a confirmation email shortly to the address provided
            during checkout.
          </Typography>

          <Typography paragraph sx={{ typography: "body1" }}>
            We will also update you via email when your donation has been delivered!
          </Typography>

          <Typography paragraph sx={{ typography: "body1" }}>
            In the meantime, why not share your experience with us?
          </Typography>

          <Typography paragraph sx={{ typography: "body1" }}>
            We would love to hear from you on our <Link href="https://n3vzf8bnvz2.typeform.com/to/Gl9WPjl2" color="secondary">donor feedback form</Link>.
          </Typography>

          <TrustBox />
        </Box>
      </Grid>
    </Grid>
  );
}
