import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  List,
  ListItem,
  Link,
  Grid,
  Typography,
} from "@mui/material";
import instaLogo from "../assets/insta.jpg";

// Assets
import logo from "../logo.png";
import CurrentCurrency from "../Currency/CurrentCurrency";
import CurrencySelector from "../Currency/CurrencySelector";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer
      style={{
        paddingTop: "2rem",
        paddingBottom: "2rem",
        borderTop: "1px solid grey",
        marginTop: "5rem",
      }}
    >
      <Container>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={4}>
            <Grid item xs={12}>
              <Link component={RouterLink} to="/">
                <img src={logo} alt="logo" />
              </Link>
              <Typography>&copy; {year} Donatic App Inc.</Typography>
            </Grid>

            <Grid item xs={12}>
              <img src="/donatic-payment-methods.png" alt="logo" height="45px" />
            </Grid>

          </Grid>
          <Grid item xs={12} sm={4}>
            <List>
              <ListItem>
                <Link component={RouterLink} color="secondary" to="/about">
                  Company
                </Link>
              </ListItem>
              <ListItem>
                <Link component={RouterLink} color="secondary" to="/contact">
                  Contact
                </Link>
              </ListItem>
              <ListItem>
                <Link component={RouterLink} color="secondary" to="/faq">
                  FAQs
                </Link>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} sm={4}>
            <List>
              <ListItem>
                <Link component={RouterLink} color="secondary" to="/privacy">
                  Privacy Policy
                </Link>
              </ListItem>
              <ListItem>
                <Link component={RouterLink} color="secondary" to="/terms">
                  Terms and Conditions
                </Link>
              </ListItem>
              <ListItem>
                <Link href="https://instagram.com/donatic.app" target="_blank">
                  <img src={instaLogo} width="25px" height="25px" /></Link> &nbsp;<Link href="https://instagram.com/donatic.app" target="_blank" color="secondary">@donatic.app
                </Link>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
