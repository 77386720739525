import { Box, Grid, Typography } from "@mui/material";
import FundModel from "../fund.model";
import FileUploader from "../../File/FileUploader";
/**
 *
 */
export default function CampaignImage({ fund, saveCampaign }: { fund: FundModel, saveCampaign: any }) {
  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography component="h5" variant="body1">
          Your campaign image:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: '400px', height: '200px', backgroundImage: `url(${fund.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }} />
      </Grid>

      <Grid item xs={12}>
        <Typography component="h5" variant="body1">
          Upload a new photo:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FileUploader
          promptText="photo"
          property="imageUrl"
          fundId={fund._id}
          refetch={saveCampaign}
        />
      </Grid>
    </Grid>
  );
}
