import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Box, Modal } from "@mui/material";
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Add, GroupOutlined, Person } from '@mui/icons-material';
import { saveAddressModalStyle } from "../Address/address.styles";
import SaveOrganization from '../Organizations/SaveOrganization';
import { IOrganization } from '../Organizations/organization.model';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

// See docs: https://mui.com/material-ui/react-menu/
export default function DashboardMenu({ organizations, refreshOrgs, isAdmin }: { organizations: IOrganization[], refreshOrgs: any, isAdmin: boolean }) {
  const navigate = useNavigate();
  const params = useParams();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  function goToDash(orgUUID: string) {
    navigate(`/dashboard/organizations/${orgUUID}`);
    setOpenModal(false);
  }

  let currentText = 'Your Dashboard';

  if (params.organizationId) {
    const org = organizations.find(organization => organization.uuid === params.organizationId);

    if (org) {
      currentText = `${org.name} Dashboard`;
    }
  }

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {currentText}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => navigate('/dashboard')}><Person />Your Dashboard</MenuItem>
        {
          organizations.map((org: IOrganization, index: number) => {
            return <MenuItem key={index} onClick={() => goToDash(org.uuid)}><GroupOutlined />{ org.name }</MenuItem>
          })
        }
        <Divider sx={{ my: 0.5 }} />

        {
          isAdmin && (
            <MenuItem onClick={() => navigate('/admin')}><Person />Admin Dashboard</MenuItem>
          )
        }

        {
          isAdmin && (
            <MenuItem onClick={() => handleOpenModal()} disableRipple>
            <Add />
            Create Organization 
          </MenuItem>
          )
        }
       
      </StyledMenu>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={saveAddressModalStyle}>
          <SaveOrganization refreshOrgs={refreshOrgs} closeModal={handleCloseModal} />
        </Box>
      </Modal>
    </div>
  );
}