import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
// See docs: https://reactrouter.com/docs/en/v6/getting-started/tutorial
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

// Core App stuff
import "./index.css";
import App from "./App";
import { RequireAuth } from "./Layout/RequireAuth";
import theme from "./theme";

// Pages
import AboutPage from "./pages/AboutPage";
import CartPage from "./Cart/CartPage";
import AccountPage from "./Account/AccountPage";
import ContactPage from "./pages/ContactPage";
import FundPage from "./Funds/FundPage";
import Home from "./pages/home";
import Login from "./pages/login";
import NotFound from "./pages/not-found";
import PrivacyPolicy from "./pages/privacy-policy";
import Terms from "./pages/terms";

// Sales Pages
import BahiaBanderasPage from "./SalesPages/BahiaBanderas/BahiaBanderasPage";

// Utils
import ScrollToTop from "./utils/scroll-top-top";
import CheckoutPage from "./Cart/CheckoutPage";
import CheckoutConfirmationPage from "./Cart/CheckoutConfirmationPage";
import DashboardPage from "./Dashboard/DashboardPage";
import DonationPage from "./Donations/DonationPage";
import OrganizationDashboard from "./Dashboard/OrganizationDashboard";
import OrganizationSettingsPage from "./Organizations/OrganizationSettingsPage";
import OrganizationDonaticPage from "./Organizations/OrganizationDonaticPage";
import CustomApolloProvider from "./apollo/ApolloProvider";
import Auth0ProviderWithHistory from "./apollo/ApolloClientWithHistory";
import AddressesPage from "./Address/AddressesPage";
import EmbeddedSandbox from "./apollo/ApolloSandbox";
import DashboardLayout from "./Dashboard/DashboardLayout";
import CampaignManagementPage from "./Funds/CampaignManagementPage";
import CreateCampaignPage from "./Funds/CreateCampaignPage";
import WishlistManagementPage from "./Funds/WishlistManagementPage";
import CampaignDetailPage from "./Funds/CampaignDetailPage";
import UserDonationsPage from "./Donations/UserDonationsPage";
import DonatePage from "./Funds/DonatePage";

import * as Sentry from "@sentry/react";
import AdminDashPage from "./AdminDash/AdminDashPage";
import OrganizationReceivedDonationsPage from "./Donations/OrganizationReceivedDonationsPage";
import FaqPage from "./pages/FaqPage";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/api.donatic\.ca/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <Auth0ProviderWithHistory>
      <BrowserRouter>
        <ScrollToTop />
        <CustomApolloProvider>
          <ThemeProvider theme={theme}>
            <Routes>
              <Route path="/" element={
                <App />
              }>
                <Route index element={<Home />} />
                <Route path="/apollo" element={<EmbeddedSandbox />} />
                <Route path="/login" element={<Login />} />
                <Route path="/about" element={<AboutPage />}></Route>
                <Route path="/company" element={<AboutPage />}></Route>
                <Route path="/faq" element={<FaqPage />}></Route>
                <Route path="/contact" element={<ContactPage />}></Route>
                <Route path="/privacy" element={<PrivacyPolicy />}></Route>
                <Route path="/terms" element={<Terms />}></Route>
                <Route path="/cart" element={<CartPage />}></Route>
                <Route path="/checkout" element={<CheckoutPage />}></Route>
                <Route
                  path="/confirm"
                  element={<CheckoutConfirmationPage />}
                ></Route>


                <Route path="/s/bahiabanderas" element={<BahiaBanderasPage />} />
                <Route path="/s/sayulita" element={<BahiaBanderasPage />} />
                <Route path="/s/san-pancho" element={<BahiaBanderasPage />} />


                <Route path="/donate" element={<DonatePage />}></Route>
                <Route path="/donate/:fundId" element={<FundPage />} />
                <Route path="/d/:fundId" element={<FundPage />}></Route>

                <Route
                  path="o/:organizationId"
                  element={<OrganizationDonaticPage />} />

                <Route
                  path="admin"
                  element={<AdminDashPage />} />

                <Route
                  path="/dashboard"
                  element={
                    <RequireAuth>
                      <DashboardLayout />
                    </RequireAuth>
                  }
                >
                  <Route index element={<DashboardPage />} />
                  
                  <Route
                    path="addresses"
                    element={<AddressesPage />}
                  />
                  <Route
                    path="account"
                    element={<AccountPage />}
                  ></Route>
                  <Route
                    path="donations"
                    element={<UserDonationsPage />}
                  ></Route>
                  <Route
                    path="donations/:donationId"
                    element={<DonationPage />}
                  ></Route>
                  <Route
                    path="campaigns"
                  >
                    <Route index
                    element={<CampaignManagementPage />} />
                    <Route
                      path="create"
                      element={<CreateCampaignPage />}
                    ></Route>
                    <Route
                      path=":campaignId"
                      element={<CampaignDetailPage />}
                    ></Route>
                  </Route>

                  <Route path="organizations">
                    <Route
                      path=":organizationId"
                      element={<OrganizationDashboard />}
                    />
                    <Route
                      path=":organizationId/settings"
                      element={<OrganizationSettingsPage />}
                    />
                    <Route
                      path=":organizationId/addresses"
                      element={<AddressesPage />}
                    />
                    <Route
                      path=":organizationId/campaigns"
                    >
                      <Route index element={<CampaignManagementPage />} />
                      <Route
                        path="create"
                        element={<CreateCampaignPage />}
                      ></Route>
                      <Route
                        path=":campaignId"
                        element={<CampaignDetailPage />}
                      ></Route>
                    </Route>
                    <Route
                      path=":organizationId/donations"
                      element={<OrganizationReceivedDonationsPage />}
                    />
                  </Route>
                </Route>

                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </ThemeProvider>
        </CustomApolloProvider>
      </BrowserRouter>
    </Auth0ProviderWithHistory>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
