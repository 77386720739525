import React from "react";
import {
  Box,
  IconButton,
  Menu,
  Button,
  MenuItem,
  Link,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import '../Layout/NavBarButton.css';

// Assets
import logo from "../logo-white.png";

export default function MainMenu() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (path?: string) => {
    setAnchorElNav(null);
    if (path) {
      navigate(path);
    }
  };

  const donatePage = { title: "Donate", path: "/donate" };

  const pages = [
    donatePage,
    { title: "Company", path: "/company" },
    { title: "Contact", path: "/contact" },
  ];

  return (
    <Box component="div" sx={{ flexGrow: 1, mr: 2, display: "flex" }}>
      <Box
        component="div"
        sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
      >
        <Button>
          <Link component={RouterLink} to="/">
            <img src={logo} alt="logo" style={{ maxHeight: "55px" }} />
          </Link>
        </Button>
      </Box>

      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>

        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={() => handleCloseNavMenu()}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          {pages.map((page) => (
            <MenuItem key={page.title} onClick={() => handleCloseNavMenu(page.path)}>
              <Typography className="NavBarButton" color="primary">
                { page.title }
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box
        component="div"
        sx={{
          flexGrow: 1,
          display: { xs: "flex", md: "none", textAlign: "center" },
        }}
      >
        <Link component={RouterLink} to="/">
          <img src={logo} alt="logo" style={{ maxWidth: "75%" }} />
        </Link>
      </Box>

      <Box sx={{ flexGrow: 0, display: { xs: "none", md: "grid" } }}>
        <Button
          component={RouterLink}
          to={donatePage.path}
          variant="contained"
          color="secondary"
          onClick={() => handleCloseNavMenu()}
          sx={{ display: "block", m: 0, pt: 3, borderRadius: 0, color: "#fff" }}
        >
          <Typography>
            { donatePage.title }
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
