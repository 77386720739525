import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import FundModel from "../fund.model";
import { useState } from "react";
import AddFundItem from "./AddFundItem";
import { addFundItemModalStyle } from "./add-fund-item.styles";
import FundItemSummary from "../components/FundItemSummary";

/**
 *
 */
export default function FundItemsList({ fund, saveCampaign, refetchFund }: { fund: FundModel, saveCampaign?: any, refetchFund?: any }) {
  const fundType = fund.isWishlist ? 'wishlist' : 'campaign';

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    if (saveCampaign) {
      saveCampaign();
    }
    // TODO - we need to reload the campaign. OR use subscription?
    setOpenModal(false);

    if (refetchFund) {
      refetchFund();
    }
  };

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography component="h5" variant="body1">
          Add or remove items to your { fundType }
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {
          fund?.items?.length > 0 ?
          (
            <Box>
            {
              fund.items.map((fundItem: any, index: number) => {
                return (<FundItemSummary fund={fund} key={index} fundItem={fundItem} refetchFund={refetchFund} />)
              })
            }
            </Box>
          )
          :
          (<Typography component="p" variant="body1">
            You don't have any items in your { fundType }, yet.
          </Typography>)
        }
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={fund?.items?.length >= FundModel.MAX_ITEM_COUNT}
          variant="contained" color="secondary" onClick={handleOpenModal}>
          { fund?.items?.length >= FundModel.MAX_ITEM_COUNT ? `The maximum items per campaign is ${FundModel.MAX_ITEM_COUNT}.` : 'Add an item'}
        </Button>
      </Grid>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={addFundItemModalStyle}>
          <AddFundItem fundType={fundType} fund={fund} closeModal={handleCloseModal} />
        </Box>
      </Modal>
    </Grid>
  );
}
