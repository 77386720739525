import { FacebookShareButton, FacebookIcon } from 'react-share';

export default function FacebookShare({ url, quote }: { url: string, quote: string }) {

  return (
    <FacebookShareButton
      url={url}
      quote={quote}
      hashtag="#donatic"
    >
      <FacebookIcon size={32} round />
    </FacebookShareButton>
  );
};
