import { EmailShareButton, EmailIcon } from 'react-share';

export default function EmailShare({ url, subject, body }: { url: string, subject: string , body: string}) {

  return (
    <EmailShareButton
      url={url}
      subject={subject}
      body={body}
    >
      <EmailIcon size={32} round />
    </EmailShareButton>
  );
};
