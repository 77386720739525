import { FormattedDate } from "react-intl";
import { Typography, Grid, Box, Paper, Button, Modal } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { StyledTableCell, StyledTableRow, IColumn } from "./shared";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from "react";
import Loading from "../Layout/Loading";
import LoadingError from "../Layout/LoadingError";
import { useQuery } from "@apollo/client";
import { LIST_ORGANIZATIONS } from "./admin.organization.queries";
import { Edit, Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AdminOrganizationModal from "./AdminOrganizationModal";
import { saveAddressModalStyle } from "../Address/address.styles";

/**
 *
 */
export default function AdminOrganizationListComponent() {
  const navigate = useNavigate();
  const [ page, setPage ] = useState(0);

  const { loading, error, data, fetchMore } = useQuery(LIST_ORGANIZATIONS, { variables: { page } });

  const [ org, setOrg ] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (org: any) => {
    setOrg(org);
    setOpenModal(true);
  }
  const handleCloseModal = () => setOpenModal(false);

  function goToOrgDash(org: any) {
    navigate(`/dashboard/organizations/${org.uuid}`);
  }

  if (loading) return <Loading />
  if (error) return <LoadingError />

  const columns: IColumn[] = [
    {
      header: 'Name',
      getValue: (org: any) => org.name ?? ''
    },
    {
      header: 'ID',
      getValue: (org: any) => org._id ?? ''
    },
    {
      header: 'User',
      getValue: (org: any) => org.user?.givenName ?? ''
    },
    {
      header: 'Website',
      getValue: (org: any) => org.website ?? ''
    },
    {
      header: 'Is Verified',
      getValue: (org: any) => org.isVerified ? 'Yes' : 'No'
    },
  ];

  return (
    <Grid container rowSpacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={6}>
        <Typography component="h5" variant="h5" paragraph>Organizations</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {
                  columns.map((column: any, index: number) => {
                    return (<StyledTableCell key={index}>{column.header}</StyledTableCell>)
                  })
                }
                <StyledTableCell>Created At</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.organizations?.organizations?.map((organization: any, index: number) => {
              return (
                <StyledTableRow key={index}>
                  {
                    columns.map((column: any, i: number) => {
                      return (<StyledTableCell key={i}>{column.getValue(organization)}</StyledTableCell>)
                    })
                  }
                  <StyledTableCell>
                    <FormattedDate value={organization.createdAt} />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Button onClick={() => goToOrgDash(organization)}><Visibility /></Button>
                    <Button onClick={() => handleOpenModal(organization)}><Edit /></Button>
                  </StyledTableCell>
                </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {
          data?.organizations?.hasMore ? (<Button variant="contained">Load More</Button>): ''
        }
      </Grid>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={saveAddressModalStyle}>
          <AdminOrganizationModal organization={org} closeModal={handleCloseModal} />
        </Box>
      </Modal>
    </Grid>
  );
}
