import { Box, Container, Grid, Typography, Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

export default function Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Container sx={{ pt: 4 }}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography component="h4" variant="h4">
              Login
            </Typography>
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <Typography paragraph sx={{ typography: "body1" }}>
              You need to be logged in to access that page. Please click the button below to login.
            </Typography>
            <Typography paragraph sx={{ typography: "body1" }}>
              <Button
                onClick={() => loginWithRedirect({ redirectUri: `${process.env.REACT_APP_BASE_URL}/dashboard` })}
                color="secondary"
                variant="contained"
              >
                Login
              </Button>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
