import { Link as RouterLink } from "react-router-dom";
import { Box, Container, Grid, Typography, Link } from "@mui/material";

export default function NotFound() {
  return (
    <Container sx={{ pt: 4 }}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography component="h2" variant="h2">
              Oops...
            </Typography>
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <p>
              Something went wrong. We can't seem to find what you're looking
              for. Please{" "}
              <Link component={RouterLink} color="secondary" to="/contact">
                contact us
              </Link>{" "}
              if this issue persists.
            </p>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
