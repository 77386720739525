import { createTheme } from "@mui/material/styles";
import { blue, green, cyan } from "@mui/material/colors";

// See docs: https://bareynol.github.io/mui-theme-creator/#Accordion
declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const primary = "#1a2877";
const secondary = "#F50057";

export const themeColours = { primary, secondary };

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      dark: "#1a2877",
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: secondary,
      light: "#F73378",
      dark: "#AB003C",
      contrastText: "#FFFFFF",
    },
    success: {
      main: green[800],
      contrastText: cyan[500]
    }
  },
  status: {
    danger: blue[100],
  },
});

export default theme;
