import { useMutation } from "@apollo/client";
import { Typography, Button, Grid, Alert, Stack, List, ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import { CalendarMonth, Email, LocalShipping, ShoppingBasket, VolunteerActivism } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import Loading from "../../Layout/Loading";
import { capitalizeString } from "../../shared/capitalizeString";
import FormatDate from "../../shared/FormatDate";
import DisplayAddress from "../../Address/DisplayAddress";
import { MARK_DONATION_DELIVERED, MARK_DONATION_SENT } from "./admin.donation.queries";
import { useState } from "react";
import { format } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import DonationSummary from "../../Donations/DonationSummary";

/**
 *
 */
export default function AdminDonationInfo({ donation }: { donation: any }) {
  const [ deliveryDate, setDeliveryDate ] = useState<Date|null>(new Date());
  const [markDonationDelivered, { loading, error }] = useMutation(MARK_DONATION_DELIVERED, { variables: { donationId: donation._id } });
  const [markDonationSent, { loading: sentLoading, error: sentError }] = useMutation(MARK_DONATION_SENT);

  function attemptMarkDonationDelivered() {
    markDonationDelivered();
  }

  function attemptMarkDonationSent() {
    if (!deliveryDate) return;

    markDonationSent({ variables: { donationId: donation._id, date: format(deliveryDate, 'MMMM d, yyyy') } })
  }

  if (loading) return <Loading />

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <List>
              <ListItem>
                <ListItemIcon><CalendarMonth /></ListItemIcon>
                <ListItemText>Donated On: <FormatDate date={donation.createdAt} /></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon><LocalShipping /></ListItemIcon>
                <ListItemText>Delivery Status: {capitalizeString(donation.deliveryStatus)}</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon><VolunteerActivism /></ListItemIcon>
                <ListItemText>Donor: {donation.user?.givenName} {donation.user?.familyName}</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon><Email /></ListItemIcon>
                <ListItemText>{donation.user?.email}</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon><ShoppingBasket /></ListItemIcon>
                <ListItemText>Donated To: {donation.fund.organization?.name ?? donation.fund.user?.givenName}</ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6}>
            <List>
              <ListItem>
                <ListItemIcon><LocalShipping /></ListItemIcon>
                <DisplayAddress address={donation.fund?.address} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <DonationSummary hideButton={true} donation={donation} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DatePicker selected={deliveryDate} onChange={(date) => setDeliveryDate(date)}/>
        <br />
        <Button type="button" variant="contained" disabled={!deliveryDate} onClick={attemptMarkDonationSent}>Mark As Sent</Button>
        <Typography>
        Date: {String(deliveryDate)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button type="button" variant="contained" onClick={attemptMarkDonationDelivered}>Mark As Delivered</Button>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Button sx={{ ml: 2, mr: 2 }} type="button" variant="contained" disabled>Mark As Refunded</Button>
        <Button type="button" variant="contained" disabled>Start Partial Refund</Button>
      </Grid>

      <Grid item xs={12}>
        <Typography>Donation ID: {donation._id}</Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: error ? 'block': 'none' }}>
        <Alert severity="error">Something went wrong.</Alert>
      </Grid>
    </Grid>
  );
}
