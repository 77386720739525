import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useMutation, useSubscription } from "@apollo/client";

import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import {
  CART_UPDATE_SUBSCRIPTION,
  FIND_OR_CREATE_CART,
} from "./Cart/cart.queries";
import { cartVar } from "./apollo/cache";
import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { SUPPORTED_LOCALES, TRANSLATIONS } from "./lang/translations";
import CartNotification from "./shared/CartNotification";

// Root template.
function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const [locale, setLocale] = useState<SUPPORTED_LOCALES>('en');

  const cartId = localStorage.getItem("dcid");

  const [findOrCreateCart, { error: findOrCreateCartError, data }] =
    useMutation(FIND_OR_CREATE_CART, { variables: { cartId } });

  useEffect(() => {
    findOrCreateCart();
    if (location.pathname === "/checkout") {
      navigate("/cart");
    }
  }, []);

  if (data) {
    const { findOrCreateCart: cart } = data;
    if (cart) {
      localStorage.setItem("dcid", cart._id);
      cartVar(cart);
    }
  }

  if (findOrCreateCartError) {
    // TODO - show error./handle.
  }

  const { data: update } = useSubscription(CART_UPDATE_SUBSCRIPTION, {
    variables: { cartId },
  });

  // Update the local cache when we receive subscriptions.
  if (update?.cartUpdated?.cart) {
    cartVar(update.cartUpdated.cart);
  }

  // TODO: subscribe to general errors?

  const messages = TRANSLATIONS[locale];

  return (
    <div className="App">
      <IntlProvider locale={navigator.language} messages={messages} defaultLocale="en-US">
        <Header />
        <Box component="div" sx={{ position: "relative", minHeight: "70vh", width: "100%" }}>
          <Outlet />
        </Box>
        <CartNotification update={update} />
        <Footer />
      </IntlProvider>
    </div>
  );
}


export default App;
