import { Container, Grid, Stack, Typography, List, ListItem, ListItemIcon, ListItemText, Divider, Button } from "@mui/material";

import DonationItem from "./DonationItem";
import { DonationItemModel } from "./donation-item.model";
import { capitalizeString } from "../shared/capitalizeString";
import { IDonation } from "./donation.model";
import LoadingError from "../Layout/LoadingError";
import FormatDate from "../shared/FormatDate";
import { CalendarMonth, Email, LocalShipping, VolunteerActivism } from "@mui/icons-material";
import DonorName from "../shared/DonorName";
import PaymentSummaryList from "../shared/PaymentSummaryList";
import ThankYouNoteForm from "./ThankYouNoteForm";
import { useMutation, useQuery } from "@apollo/client";
import { GET_DONATION, SEND_DONOR_THANK_YOU } from "./donation.queries";
import Loading from "../Layout/Loading";

interface IDonationDetailModalParams {
  donationId?: string;
  closeModal?: () => any
}

/**
 * View a single donation (from Campaign page - as Fundraiser)
 */
export default function DonationDetailModal({ donationId, closeModal } : IDonationDetailModalParams) {
  const { data, loading, error, refetch } = useQuery(GET_DONATION, { variables: { donationId }, skip: !donationId });

  const donation = data?.donation;

  const [ sendDonorThankYou, { loading: sendDonorThankYouLoading, error: sendDonorThankYouError } ] = useMutation(SEND_DONOR_THANK_YOU);

  function attemptSendDonorThankYou(message: string) {
    sendDonorThankYou({ variables: { donationId: donation._id, message } })
      // Reload the donation after sending.
      .then(() => refetch());
  }

  if (loading) return <Loading />
  if (!donationId || error) {
    return (<LoadingError />);
  }

  return (
    <Container>
      <Grid container sx={{ mb: 2 }} columnSpacing={3}>
        <Grid item xs={12} sm={6}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <List>
                <ListItem>
                  <ListItemIcon><CalendarMonth /></ListItemIcon>
                  <ListItemText>Donated On: <FormatDate date={donation.createdAt} /></ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon><LocalShipping /></ListItemIcon>
                  <ListItemText>Delivery Status: {capitalizeString(donation.deliveryStatus)}</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon><VolunteerActivism /></ListItemIcon>
                  <ListItemText>Donor: {donation.user?.givenName} {donation.user?.familyName}</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon><Email /></ListItemIcon>
                  <ListItemText>{donation.user?.email}</ListItemText>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={12} sx={{ maxHeight: '300px', overflow: 'scroll' }}>
              <Typography variant="body1" paragraph>
                <span className="emoji" role="img">
                  🎉
                </span>{" "}
                <DonorName donation={donation} /> has sent you the following items:</Typography>
              <Stack>
                {donation?.items?.map((item: DonationItemModel, index: number) => {
                  return <DonationItem key={index} donationItem={item} />;
                })}
              </Stack>
              <Grid item xs={12}>
                <Divider />
                <Typography variant="body1" paragraph>Payment Summary</Typography>
                <PaymentSummaryList
                  currency={donation.currency}
                  subtotal={donation.subtotal}
                  serviceFee={donation.serviceFee}
                  taxes={donation.taxes}
                  total={donation.total}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <Grid item xs={12}>
            {
              donation.thankYouNoteSentAt && !loading ? (
                <Typography variant="body1">You sent <DonorName donation={donation} /> a thank you note on <FormatDate date={donation.thankYouNoteSentAt} />.</Typography>
              ): (<ThankYouNoteForm loading={sendDonorThankYouLoading} error={sendDonorThankYouError} attemptSendDonorThankYou={attemptSendDonorThankYou} />)
            }
          </Grid>
        </Grid>

        <Grid item xs={12} textAlign="right">
          <Button onClick={closeModal} variant="contained">Close</Button>
        </Grid>
      </Grid>
    </Container>
  );
}
