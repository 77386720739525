import { Typography, Button, Grid, Paper, styled, Modal, Box } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from "react";
import { LIST_FUNDS } from "./admin.fund.queries";
import { useQuery } from "@apollo/client";
import Loading from "../Layout/Loading";
import LoadingError from "../Layout/LoadingError";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { buildDashPath } from "../Dashboard/build-dash-path";
import { StyledTableCell, StyledTableRow } from "./shared";
import AdminCampaignModal from "./AdminCampaignModal";
import { saveAddressModalStyle } from "../Address/address.styles";

/**
 *
 */
export default function AdminCampaignListComponent() {
  const navigate = useNavigate();

  const [ page, setPage ] = useState(0);
  const [ fund, setFund ] = useState(undefined);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (fund: any) => {
    setFund(fund);
    setOpenModal(true);
  }
  const handleCloseModal = () => setOpenModal(false);

  const { loading, error, data, fetchMore } = useQuery(LIST_FUNDS, { variables: { page } });

  function loadMore() {
    const nextPage = page + 1;
    fetchMore({ variables: { page: nextPage } });
    setPage(page + 1);
  }

  const params = {};
  function goToViewCampaign(fund: any) {
    const path = buildDashPath({ params, nextPath: 'campaigns' });

    navigate(`${path}/${fund._id}`);
  }

  if (loading) return <Loading />
  if (error) return <LoadingError />

  return (
    <Grid container rowSpacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={6}>
        <Typography component="h5" variant="h5" paragraph>Campaigns</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Region</StyledTableCell>
              <StyledTableCell>User</StyledTableCell>
              <StyledTableCell>Organization</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.funds?.funds.map((fund: any, index: number) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {fund.name}
                  </StyledTableCell>
                  <StyledTableCell>
                    {fund.region}
                  </StyledTableCell>
                  <StyledTableCell>
                    {fund.user?.givenName} { !fund.user  ? 'WARNING: No user' : '' }
                  </StyledTableCell>
                  <StyledTableCell>
                    {fund.organization?.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Button onClick={() => goToViewCampaign(fund)}><Visibility /></Button>
                    <Button onClick={() => handleOpenModal(fund)}><Edit /></Button>
                  </StyledTableCell>
                </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {
          data?.funds?.hasMore ? (<Button variant="contained" onClick={loadMore}>Load More</Button>): ''
        }
      </Grid>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={saveAddressModalStyle}>
          <AdminCampaignModal fund={fund} closeModal={handleCloseModal} />
        </Box>
      </Modal>
    </Grid>
  );
}
