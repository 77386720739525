import axios, { Axios } from "axios";
/**
 * Service for making calls to our own API.
 */

class APIService {
  static FILE_UPLOAD_PATH = '/upload';
  static FILE_UPLOAD_PROPERTY = 'image';

  private http: Axios = axios.create({
    baseURL: process.env.REACT_APP_API_URL || "http://localhost:3001"
  });

  async uploadFile({ file, authorization, body } : { file: File, authorization: string; body: Record<string, any> }) {
    const headers = {
      authorization: `Bearer ${authorization}`,
      'content-type': 'multipart/form-data',
      'content-length': `${file.size}`,
    };

    const formData = new FormData();
    formData.append(APIService.FILE_UPLOAD_PROPERTY, file);
    Object.keys(body).map(key => {
      // Only append to form data if it exists (is not undefined.)
      if (body[key]) {
        formData.append(key, body[key])
      }
    });

    const response = await this.http.post(APIService.FILE_UPLOAD_PATH, formData, { headers });

    try {
      return response.data.json();
    } catch (error) {
      return response.statusText;
    }
  }
}

export default APIService;
