import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { IItemOptions } from "./item.model";
import FundItemPrice from "../Funds/FundItemPrice";

/**
 *
 */
export default function ItemSummary({ item }: { item: IItemOptions }) {
  return (
    <Card sx={{ display: "flex", marginBottom: 2 }}>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        src={item.imageUrl}
        alt="Donation item"
      />
      <Box>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography paragraph>
            { item.name }
          </Typography>
          <FundItemPrice price={item.price} />
        </CardContent>
      </Box>
    </Card>
  );
}
