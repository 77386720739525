/**
 *
 * Skeleton of a Fund while it's loading.
 */
import { Container, Grid, Skeleton } from "@mui/material";

export default function FundPageSkeleton() {
  return (
    <Container sx={{ pt: 4 }}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
      </Grid>
    </Container>
  );
}
