// config/Auth0Provider.tsx
import React from "react";
// import { useNavigate } from "react-router-dom";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";

export interface Auth0ProviderWithHistoryProps {
  children: React.ReactNode;
}

const Auth0ProviderWithHistory = ({
  children,
}: Auth0ProviderWithHistoryProps): React.ReactElement => {
  // Retrieve the previously created environment variables
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  // Fail fast if the environment variables aren't set
  if (!domain || !clientId)
    throw new Error(
      "Please set REACT_APP_AUTH0_DOMAIN and REACT_APP_AUTH0_CLIENT_ID env. variables"
    );

  /**
   * This is not needed if you're not using a custom router, like `react-router`
   */
  // const navigate = useNavigate();

  /**
   * Callback triggered when a successful login occurs.
   *
   * Here you could redirect your users to a protected route.
   *
   * This is not needed if you're not using a custom router, like `react-router`
   */
  const onRedirectCallback: Auth0ProviderOptions["onRedirectCallback"] = (
    // appState
  ) => {
    // TODO: is this useful?
    // navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      useRefreshTokens
      // Token storage option, `localstorage` gives the feature
      // to not log out your users when they close your application
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
