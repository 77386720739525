import { useMutation } from "@apollo/client";
import { Typography, Button, Grid, FormGroup, TextField, Alert } from "@mui/material";
import { useState } from "react";
import { ADD_USER_TO_ORGANIZATION } from "./admin.organization.queries";
import Loading from "../Layout/Loading";

/**
 *
 */
export default function AdminOrganizationModal({ organization, closeModal }: { organization: any, closeModal: any }) {
  const [userId, setUserId] = useState('');

  const [addUserToOrg, { loading, error }] = useMutation(ADD_USER_TO_ORGANIZATION);

  function attemptSaveOrg() {
    addUserToOrg({ variables: { organizationId: organization._id, userId } })
      .then(() => closeModal());
  }

  if (loading) return <Loading />

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5" paragraph>Edit Organization User</Typography>
        <Typography variant="body1" paragraph>This will add the organization ID to the user's organizationIds, and also set the Organization owner as the user.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" fontWeight="bold">You are modifying the user for the organization: {organization.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <TextField onChange={(e) => setUserId(e.target.value)} label="User ID" value={userId} />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <Button type="button" variant="contained" disabled={!userId} onClick={attemptSaveOrg}>Save</Button>
      </Grid>
      <Grid item xs={12} sx={{ display: error ? 'block': 'none' }}>
        <Alert severity="error">Something went wrong.</Alert>
      </Grid>
    </Grid>
  );
}
