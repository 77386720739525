import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      onClick={() => loginWithRedirect({ redirectUri: `${process.env.REACT_APP_BASE_URL}/dashboard` })}
      color="primary"
      sx={{ my: 2, color: "white", display: "block" }}
    >
      <AccountCircle />
    </Button>
  );
};

export default LoginButton;
