import { Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

export default function CartNotification({ update }: { update: any }) {
  const [message, setMessage] = useState<string|null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (update?.cartUpdated?.type) {
      // Handle repeats (if someone attempts to add too quickly) - if we already are showing the message, we don't need show it again...
      if (update.cartUpdated.type === "ITEM_ADDED") {
        openSnackbar('Item added!');
      }

      if (update.cartUpdated.type === "ITEM_REMOVED") {
        openSnackbar('Item removed from basket.');
      }
    }
  }, [update?.cartUpdated?.type]);

  function openSnackbar(nextMessage: string) {
     if (!message) {
      setMessage(nextMessage);
      setIsOpen(true);
    }
  }

  const onSnackbarClose = () => {
    setIsOpen(false);
    setMessage(null);
  }

  return (
    <Snackbar
      onClose={onSnackbarClose}
      autoHideDuration={1750}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={isOpen}
      message={message} />
  );
}