import { Box, FormControl, FormGroup, Grid, TextField, Typography } from "@mui/material";
import FundModel from "../fund.model";
import { useEffect } from "react";
/**
 *
 */
export default function CampaignInfo({ fund, setFund, setCanGoToNextStep }: { fund: FundModel, setFund: any, setCanGoToNextStep: any; }) {
  useEffect(() => {
    if (fund.name && fund.description) {
      setCanGoToNextStep(true);
    } else {
      setCanGoToNextStep(false);
    }
  }, []);

  function updateCampaign(prop: string, e: any) {
    e.preventDefault();
    const value = e.target.value;
    setFund({ ...fund, [prop]: value });
    if (fund.name && fund.description) {
      setCanGoToNextStep(true);
    }
  }

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography component="h5" variant="body1">
          Give your campaign a name and description.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{
            '& > :not(style)': { m: 1, width: '100%' },
          }}>
          <Grid item xs={12} width="100%">
            <FormControl fullWidth={true}>
              <FormGroup>
                <TextField
                  variant="outlined"
                  id="name"
                  label="Name"
                  name="name"
                  value={fund.name}
                  required={true}
                  onChange={(e) => updateCampaign('name', e)}
                ></TextField>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth={true}>
              <FormGroup>
                <TextField
                  multiline={true}
                  variant="outlined"
                  id="description"
                  label="Description"
                  name="description"
                  value={fund.description}
                  required={true}
                  onChange={(e) => updateCampaign('description', e)}
                ></TextField>
              </FormGroup>
            </FormControl>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
