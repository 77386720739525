import { Button, Container, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { IFundOptions } from "../fund.model";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { ADD_ITEM_TO_FUND } from "../fund.queries";
import { IItemOptions } from "../../Items/item.model";
import SetItemQuantityComponent from "./SetItemQuantityComponent";
import SearchItemComponent from "./SearchItemComponent";
import Loading from "../../Layout/Loading";
import LoadingError from "../../Layout/LoadingError";
/**
 *
 */
export default function AddFundItem({ fund, closeModal, fundType }: { fund: IFundOptions, closeModal: any, fundType: string }) {
  const [ item, setItem ] = useState<IItemOptions|null>(null);
  const [ currentStep, setCurrentStep ] = useState<number>(0);

  const [addItemToFund, { loading, error }] = useMutation(ADD_ITEM_TO_FUND);

  function addFundItem(totalGoal: number) {
    if (!item) return;
    addItemToFund({ variables: { fundId: fund._id!, itemId: item._id, quantity: Number(totalGoal) },
      onCompleted: () => closeModal()
    });
  }

  const region = fund.region ?? fund.address?.region;

  // TODO: handle this.
  // TODO: Make sure DB is migrated property (run script to set these values).
  if (!region) throw new Error('region missing on fund.');

  function backToStart() {
    setCurrentStep(0);
    setItem(null);
  }

  const steps = [
    {
      stepTitle: 'Search for an item',
      component: (<SearchItemComponent item={item} region={region} setItem={setItem} />)
    },
    {
      stepTitle: fund.isWishlist ? 'Confirm Item' : 'Set Goal',
      component: (<SetItemQuantityComponent isWishlist={fund.isWishlist} item={item} addFundItem={addFundItem} />)
    }
  ];

  // TODO: handle loading & error.
  if (loading) return (<Loading />);
  if (error) return (<LoadingError />);

  return (
    <Container>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Stepper activeStep={currentStep}>
            { steps.map((step: any, index: number) => (
              <Step key={index} completed={index < currentStep}>
                <StepLabel>
                  {step.stepTitle}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h5" variant="body1">
            Add an item to your {fundType}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {
            steps[currentStep].component
          }
        </Grid>
        <Grid item xs={12} alignItems="right">
          {
            currentStep === 0 ?
            (
              <Button disabled={!item} variant="contained" color="secondary" onClick={() => setCurrentStep(1)}>Next</Button>
            ) :
            (
              <Button variant="outlined" color="primary" onClick={backToStart}>Back</Button>
            )
          }
        </Grid>
      </Grid>
    </Container>
  );
}
