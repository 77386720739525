import { Button, Grid, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import CampaignInfo from "./Steps/CampaignInfo";
import { createRef, useRef, useState } from "react";
import FundModel, { IFundOptions } from "./fund.model";
import CampaignImage from "./Steps/CampaignImage";
import FundItemsList from "./Steps/FundItemsList";
import SaveFundConfirmation from "./Steps/SaveFundConfirmation";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { SAVE_CAMPAIGN } from "./fund.queries";
import SelectAddress from "./Steps/SelectAddress";
import { buildDashPath } from "../Dashboard/build-dash-path";

/**
 *
 */
export default function CreateCampaignPage() {
  const params = useParams();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [canGoToNextStep, setCanGoToNextStep] = useState(false);
  const [canGoToPreviousStep, setCanGoToPreviousStep] = useState(false);

  const [fundId, setFundId] = useState(null);

  const defaultFund = new FundModel({} as IFundOptions);

  const [fund, setFund] = useState(defaultFund);

  // TODO: handle loading & errors.
  const [ saveCampaign, { loading: isSaving, error } ] = useMutation(SAVE_CAMPAIGN);

  function attemptSaveCampaign(onComplete?: () => any) {
    saveCampaign({
      variables: { campaign: fund.toFundInput(), orgUuid: params.organizationId },
      onCompleted: (data) => {
        if (data.saveCampaign) {
          if (!fundId) setFundId(data.saveCampaign._id);
          setFund(new FundModel(data.saveCampaign));
        }

        if (onComplete) {
          onComplete();
        }
      }
    });
  }

  function attemptSaveCampaignAndGoToNextStep() {
    attemptSaveCampaign(goToNextStep);
  }

  function publishCampaign() {
    updateFund({ ...fund, isPublished: true })
    fund.isPublished = true;

    attemptSaveCampaign(() => {
      // TODO - Either show modal, or navigate to confirmation page (which links back to CamapaignList page)
      // navigate(`/donate/${fund._id}`);
    });
  }

  function updateFund(nextFund: any) {
    const options = { ...nextFund };

    setFund(new FundModel(options));
  }

  const steps = [
    {
      stepTitle: 'Campaign Name',
      component: (<CampaignInfo fund={fund} setFund={updateFund} setCanGoToNextStep={setCanGoToNextStep} />)
    },
    {
      stepTitle: 'Select Destination',
      component: (<SelectAddress fund={fund} setFund={updateFund} setCanGoToNextStep={setCanGoToNextStep} />)
    },
    {
      stepTitle: 'Campaign Image',
      component: (<CampaignImage fund={fund}  saveCampaign={attemptSaveCampaign} />)
    },
    {
      stepTitle: 'Add Items to Campaign',
      component: (<FundItemsList fund={fund} saveCampaign={attemptSaveCampaign} />)
    },
    {
      stepTitle: 'Publish Campaign',
      component: (<SaveFundConfirmation fund={fund} saveCampaign={publishCampaign} />)
    },
  ];

  function goToNextStep() {
    const nextStep = currentStep + 1;
    if (nextStep > steps.length - 1) {
      return;
    }

    // TODO - save the campaign between steps.
    // TODO: show loading spinner on next button?
    setCurrentStep(nextStep);
    setCanGoToPreviousStep(true);
    executeScroll();
  }

  function goToPreviousStep() {
    const previous = currentStep - 1;
    if (previous < 0) {
      return;
    }
    setCurrentStep(previous);
    executeScroll(false);
  }

  function goToCampaigns() {
    const path = buildDashPath({ params, nextPath: 'campaigns' });
    navigate(path);
  }

  const stepRefs = useRef<any>([]);
  stepRefs.current = steps.map((step: any, index: number) => {
    return createRef<HTMLDivElement>();
  });

  function executeScroll(next = true) {
    const ref = stepRefs.current[currentStep];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: next ? 'start': 'center' });
    }
  }

  const isBeforeLastStep = currentStep < steps.length - 1;
  const isAfterFirstStep = currentStep > 0;
  const isLastStep = currentStep === steps.length - 1;

  const showBackButton = isAfterFirstStep && !isLastStep;
  const showNextButton = isBeforeLastStep;

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5">
          Create a Campaign
        </Typography>
      </Grid>
      <Grid item>
        <Stepper activeStep={currentStep} orientation="vertical">
          {steps.map((step: any, index: number) => (
            <Step key={index}>
              <StepLabel ref={stepRefs.current[index]}>{step.stepTitle}</StepLabel>
              <StepContent>
                { step.component }
                <Grid container sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <Button
                      sx={{
                        display: showBackButton ? 'inline-flex' : 'none'
                      }}
                      disabled={!canGoToPreviousStep || isSaving}
                      onClick={goToPreviousStep}
                      variant="outlined"
                    >Back</Button>

                    <Button
                      sx={{
                        display: showNextButton ? 'inline-flex' : 'none'
                      }}
                      disabled={!canGoToNextStep}
                      onClick={attemptSaveCampaignAndGoToNextStep}
                      variant={ canGoToNextStep ? 'contained': 'outlined' }
                    >{ isSaving ? 'Saving...' : 'Next' }</Button>
                  </Grid>
                </Grid>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
}
