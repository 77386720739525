import { Link } from "@mui/material";

import instaLogo from "../assets/insta.jpg";

export default function InstagramLink() {
  return (<span>
    <Link href="https://instagram.com/donatic.app" target="_blank">
      <img src={instaLogo} width="25px" height="25px" /></Link> &nbsp;<Link href="https://instagram.com/donatic.app" target="_blank" color="secondary">@donatic.app
    </Link>
  </span>)
}