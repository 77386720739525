export const cartItemStyles = {
  width: '100%',
  minWidth: '100px',
  maxWidth: '100px',
  maxHeight: '100px'
};

export const fundItemStyles = {
  width: '100%',
  minWidth: '150px',
  maxWidth: '150px',
  maxHeight: '150px'
}

export const fundItemStylesForFundPage = {
  width: '100%',
  minWidth: '150px',
  maxWidth: { xs: '100%', md: '150px' },
  minHeight: '150px',
  maxHeight: '150px',
  backgroundSize: 'contain'
}

export const donationItemStyles  = {
  width: '100%',
  minWidth: '150px',
  maxWidth: { xs: '100%', md: '150px' },
  minHeight: '150px',
  maxHeight: '150px',
  backgroundSize: 'contain'
}