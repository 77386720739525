import { IAddress } from "../Address/address.model";

export interface IOrganization {
  _id?: string,
  uuid: string,
  name: string,
  description: string,
  website: string,
  email: string,
  logoUrl: string,
  qrCode: string,
  userId: string
  addressId: string
  addressIds: string[]
  address?: IAddress
}

export const newOrganization = (): IOrganization => {
  return {
    uuid: '',
    name: '',
    description: '',
    website: '',
    email: '',
    logoUrl: '',
    qrCode: '',
    userId: '',
    addressId: '',
    addressIds: [],
  };
};

export class Organization {
  _id?: string;
  uuid: string;
  name: string;
  description: string;
  website: string;
  email: string;
  logoUrl: string;
  qrCode: string;
  userId: string;
  addressId: string;
  addressIds: string[];
  address?: IAddress;

  constructor(options: IOrganization) {
    const {
      _id,
      uuid,
      name,
      description,
      website,
      email,
      logoUrl,
      qrCode,
      userId,
      addressId,
      addressIds,
      address
    } = options;

    this.uuid = uuid;
    this.name = name;
    this.description = description;
    this.website = website;
    this.email = email;
    this.logoUrl = logoUrl;
    this.qrCode = qrCode;
    this.userId = userId;
    this.addressId = addressId;
    this.addressIds = addressIds;

    if (_id) {
      this._id = _id;
    }

    if (address) {
      this.address = address;
    }
  }

  withoutId() {
    this._id = undefined;

    return this;
  }

  toOrganizationInput() {
    return {
      name: this.name,
      description: this.description,
      website: this.website,
      email: this.email
    }
  }
}