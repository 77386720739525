import { useMutation } from "@apollo/client";
import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IOrganization, newOrganization, Organization } from "./organization.model";
import { SAVE_ORGANIZATION } from "./organization.queries";

export default function SaveOrganization({ organization: existingOrganization, closeModal, refreshOrgs }: { organization?: IOrganization, organizationId?: string, closeModal: any, refreshOrgs?: any }) {
  const navigate = useNavigate();
  const [organization, setOrganization] = useState(existingOrganization ?? newOrganization());

  const [ saveOrganization, { loading, error } ] = useMutation(SAVE_ORGANIZATION, { variables: { organization: new Organization(organization).withoutId().toOrganizationInput(), organizationId: organization._id ?? null } });

  function updateOrganization(prop: string, e: any) {
    e.preventDefault();
    setOrganization({ ...organization, [prop]: e.target.value });
  }

  if (error) {
    // TODO: handle.
  }

  async function save() {
    saveOrganization()
      .then((result) => {
        if (!error) {
          if (!organization._id && refreshOrgs) {
            refreshOrgs()
              .then(() => {
                const uuid = result.data?.saveOrganization.uuid;
                if (uuid) {
                  navigate(`/dashboard/organizations/${uuid}`);
                }
              });
          }

          closeModal();
        }
      });
  }

  const isFormValid = () => {
    return !!organization.name && !!organization.description
  };

  return (
    <Grid item>
      <Grid item sx={{ mb: 2 }}>
       <Typography>{ organization._id ? 'Save' : 'Create A New ' } Organization</Typography>
      </Grid>
      <Grid hidden={!error} item sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ color: 'red' }}>There was an error saving your organization. Please contact us if the problem persists.</Typography>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <FormGroup>
            <TextField
              variant="standard"
              id="addressee"
              label="Name"
              name="name"
              value={organization.name}
              required={true}
              onChange={(e) => updateOrganization("name", e)}
            ></TextField>
          </FormGroup>

          <FormGroup>
            <TextField
              variant="standard"
              id="descrtipion"
              label="Description"
              name="description"
              value={organization.description}
              required={true}
              onChange={(e) => updateOrganization("description", e)}
            ></TextField>
          </FormGroup>

          <FormGroup>
            <TextField
              variant="standard"
              id="website"
              label="Website"
              name="website"
              value={organization.website}
              onChange={(e) => updateOrganization("website", e)}
            ></TextField>
          </FormGroup>

          <FormGroup>
            <TextField
              variant="standard"
              id="email"
              label="Email"
              name="email"
              value={organization.email}
              onChange={(e) => updateOrganization("email", e)}
            ></TextField>
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item sx={{ mt:2 }} textAlign="right">
        <Button disabled={!isFormValid() || loading} variant="outlined" onClick={() => save()}>Save</Button>
      </Grid>
    </Grid>
  );
}
