/**
 *
 * CartItem on the CartPage
 */
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
} from "@mui/material";

// Assets
import defaultImage from "../shoppingbag.webp";
import { CartItemModel } from "./cart-item.model";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_TO_CART, REMOVE_FROM_CART, CURRENT_CART } from "./cart.queries";
import Price from "../shared/Price";
import Quantity from "../shared/Quantity";
import { cartItemStyles } from "../Items/item.styles";
import CartItemQuantity from "./CartItemQuantity";

interface ICartItemProps {
  cartItem: CartItemModel;
}

export default function CartItem({ cartItem }: ICartItemProps) {
  const { data } = useQuery(CURRENT_CART);
  const cartId = data.currentCart._id;

  const [mutateRemoveFromCart] = useMutation(REMOVE_FROM_CART, {
    variables: {
      cartId,
      itemId: cartItem.item._id,
      quantity: 1,
    },
  });

  const [mutateRemoveCompletelyFromCart] = useMutation(REMOVE_FROM_CART, {
    variables: {
      cartId,
      itemId: cartItem.item._id,
      quantity: cartItem.quantity,
    },
  });

  const [mutateAddToCart] = useMutation(ADD_TO_CART, {
    variables: {
      cartId,
      itemId: cartItem.item._id,
      quantity: 1,
    },
  });

  function removeItem() {
    mutateRemoveFromCart();
  }

  function removeItemCompletely() {
    mutateRemoveCompletelyFromCart();
  }

  function addToCart() {
    mutateAddToCart();
  }

  // TODO: Future - use this from internationalization.
  const quantityDelimiter = ",";

  return (
    <Card sx={{ display: "flex", marginBottom: 2 }}>
      {cartItem.item.imageUrl ? (
        <CardMedia
          component="img"
          sx={cartItemStyles}
          image={cartItem.item.imageUrl}
        />
      ) : (
        <CardMedia
          sx={{ width: 80, height: 80 }}
          image={defaultImage}
        ></CardMedia>
      )}
      <Box sx={{ display: "block", flexDirection: "column", width: "100%" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4} md={4}>
              <Typography component="div" variant="body1">
                {cartItem.item.name}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={8} md={8}>
              <Grid container spacing={0}>
                <Grid item sx={{ pt: { xs: 2, sm: 0 } }} xs={12} sm={4} md={4}>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    <strong>
                      Price:{" "}
                      <Price
                        price={cartItem.item.price}
                      />
                    </strong>
                  </Typography>
                </Grid>
                <Grid item sx={{ pt: { xs: 2, sm: 0 } }} xs={12} sm={4} md={4}>
                  <Grid container>
                    <Grid item xs={4} textAlign="left">
                      <Button variant="contained" onClick={removeItem}>
                        -
                      </Button>
                    </Grid>
                    <Grid item xs={4} sx={{ borderBottom: "1px solid grey" }}>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        textAlign="center"
                      >
                        <Quantity
                          quantity={cartItem.quantity}
                          delimiter={quantityDelimiter}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={4} textAlign="right">
                      <Button variant="contained" onClick={addToCart}>
                        +
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{ pt: { xs: 2, sm: 0 } }}
                  xs={12}
                  sm={4}
                  md={4}
                  textAlign="right"
                >
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    component="div"
                    textAlign="right"
                  >
                    <strong>
                      Total:{" "}
                      <CartItemQuantity quantity={cartItem.quantity} price={cartItem.item.price} />
                    </strong>
                  </Typography>
                  <Button
                    sx={{ mt: 1 }}
                    variant="outlined"
                    onClick={removeItemCompletely}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </CardContent>
      </Box>
    </Card>
  );
}
