import { Link, Typography } from "@mui/material";

export const FaqContent = [
  {
    question: 'What is Donatic?',
    content: (
      <Typography paragraph>
        Donatic is a donation platform that allows individuals and organizations to create campaigns for tangible items instead of monetary donations. It's like an online wishlist or registry where fundraisers can list specific items they need, and donors can purchase those items directly through Donatic.
      </Typography>
    )
  },
  {
    question: 'How does Donatic work?',
    content: (
      <Typography paragraph>
        Fundraisers create campaigns by listing the tangible items they need. Donors browse through these campaigns and select the items they wish to donate. Once a donation is made, Donatic purchases the items and facilitates the delivery of the donation to the fundraisers.
      </Typography>
    )
  },
  {
    question: 'Who can use Donatic?',
    content: (
      <Typography paragraph>
        Currently, only approved nonprofits can join Donatic. These are nonprofits that Donatic has verified and worked with to ensure that they are legitimate and that the donations are going to a good cause. If you are a nonprofit and would like to join Donatic, please reach out to us <Link color="primary" href="mailto:donate@donatic.app">here</Link>.
      </Typography>
    )
  },
  {
    question: 'Is Donatic only for charitable organizations?',
    content: (
      <Typography paragraph>
        No, Donatic can be used by both charitable organizations and individuals. Whether you're fundraising for a nonprofit project, a community initiative, or personal needs, you can create a campaign on Donatic.
      </Typography>
    )
  },
  {
    question: 'What types of items can be donated through Donatic?',
    content: (
      <Typography paragraph>
        Donatic supports a wide range of tangible items, including but not limited to clothing, food, school supplies, medical equipment, household goods, and more. Campaign creators can specify their exact needs when setting up their campaigns.
      </Typography>
    )
  },
  {
    question: 'How are donations processed on Donatic?',
    content: (
      <Typography paragraph>
        Donations on Donatic are processed securely through the platform's payment system. Donors can use credit cards accepted by Donatic.
      </Typography>
    )
  },
  {
    question: 'Are donations tax-deductible?',
    content: (
      <Typography paragraph>
        Donatic itself is not a tax-exempt organization, so donations made through the platform may not be tax-deductible. However, if you donate to a registered nonprofit organization through Donatic, your donation may be tax-deductible according to local tax laws. It's recommended to consult with a tax professional for specific guidance, or to reach out to the organization you're donating to before making the donation if you require a tax receipt.
      </Typography>
    )
  },
  {
    question: 'Is there a fee for using Donatic?',
    content: (
      <Typography paragraph>
        Donatic charges a small fee on donations to cover transaction costs, platform maintenance, and for handling the purchase and delivery of your donation. Currently, that fee is 5% of the donation amount.
      </Typography>
    )
  },
  {
    question: 'What are verified organizations?',
    content: (
      <Typography paragraph>
        Verified organizations are non-profits that our team have gone personally to visit and verify that they are legitimate. We do this to ensure that your donations are going to a good cause and that the organization is real. We also love to highlight the work and the impact that these organizations do for the community, you can often see their stories on our social media.
      </Typography>
    )
  },
  {
    question: 'How can I get support if I have questions or issues with Donatic?',
    content: (
      <Typography paragraph>
        If you have any questions, concerns, or need assistance with Donatic, you can reach out to our customer support team through the contact form on our website or via email at <Link color="primary" href="mailto:donate@donatic.app">donate@donatic.app</Link>. We're here to help you every step of the way!
      </Typography>
    )
  },
];