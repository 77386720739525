export interface IUserOptions {
  _id: string;
  email: string;
  name: string;
  givenName: string;
  familyName: string;
}

export default class UserModel {
  _id: string | null;
  email: string;
  name: string;
  givenName: string;
  familyName: string;

  constructor(options: IUserOptions) {
    const { _id, email, name, givenName, familyName } = options ?? {};

    this._id = _id || null;
    this.email = email;
    this.name = name;
    this.givenName = givenName;
    this.familyName = familyName;
  }
}
