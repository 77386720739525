/**
 *
 * Home Page
 */
import { Container, Grid } from "@mui/material";
import HowItWorksIntro from "./HowItWorksIntro";
import HowItWorksChooseFund from "./HowItWorksChooseFund";
import HowItWorksChooseItemsToDonate from "./HowItWorksChooseItemsToDonate";
import HowItWorksChooseItemsSent from "./HowItWorksChooseItemsSent";
import { homeStyles } from "./home-styles";

export default function HowItWorks() {
  return (
    <Container sx={homeStyles.sectionContainer}>
      <Grid container spacing={{ md: 4 }}>
        <Grid item>
          <HowItWorksIntro />
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={homeStyles.howItWorksItem}>
          <HowItWorksChooseFund />
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={homeStyles.howItWorksItem}>
          <HowItWorksChooseItemsToDonate />
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={homeStyles.howItWorksItem}>
          <HowItWorksChooseItemsSent />
        </Grid>
      </Grid>
    </Container>
  );
}
