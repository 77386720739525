import { Container, Typography, Link, Grid } from "@mui/material";
import SocialShare from "../Social/SocialShare";
/**
 *
 * Contact
 */
export default function ContactPage() {
  const shareURL = window.location.protocol + '//' + window.location.host;
  const shareTitle = 'Donatic: Give things, not money. Donate with us today.';
  const shareEmailSubject = 'Checkout Donatic, a platform for donating (new) items.';
  const shareEmailBody = 'Donatic is a platform where fundraisers can create campaigns to receive items in new condition. Check it out!';
  
  return (
    <Container sx={{ pt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography component="h2" variant="h5" sx={{ marginBottom: 1 }}>
            Contact Donatic
          </Typography>

          <Typography paragraph sx={{ typography: "body1" }}>
            We are always happy to hear from you. Any feedback, thoughts or
            suggestions are always welcome.
          </Typography>

          <Typography paragraph sx={{ typography: "body1" }}>
            Send us a message at{" "}
            <Link color="primary" href="mailto:donate@donatic.app">
              donate@donatic.app
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography paragraph>
            Enjoying Donatic? Help us spread the word!
          </Typography>
          <SocialShare url={shareURL} title={shareTitle} quote={shareEmailBody} emailSubject={shareEmailSubject} emailBody={shareEmailBody}  />
        </Grid>
      </Grid>
    </Container>
  );
}
