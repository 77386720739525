import Item from "../Items/item.model";
import Fund from "../Funds/fund.model";

interface IDonationItemOptions {
  _id: string;
  itemId: string;
  fundId: string;
  quantity: number;
  item: Item;
  fund: Fund;
}

export class DonationItemModel {
  _id: string;
  itemId: string;
  fundId: string;
  quantity: number;
  item: Item;
  fund: Fund;

  constructor(options: IDonationItemOptions) {
    const { _id, itemId, fundId, quantity, item, fund } = options;

    this._id = _id;
    this.itemId = itemId;
    this.fundId = fundId;
    this.quantity = quantity;
    this.item = item;
    this.fund = fund;
  }
}
