export interface IAddress {
  _id?: string;
  addressee: string;
  line1: string;
  line2: string;
  city: string;
  province: string;
  country: string;
  zipcode: string;
  specialInstructions: string;
  userId?: string;
  organizationId?: string;
  region: string
}

export const newAddress = (): IAddress => {
  return {
    addressee: "",
    line1: "",
    line2: "",
    city: "",
    province: "",
    country: "",
    zipcode: "",
    specialInstructions: "",
    region: ""
  };
};

export class Address {
  _id?: string;
  addressee: string;
  line1: string;
  line2: string;
  city: string;
  province: string;
  country: string;
  zipcode: string;
  specialInstructions: string;
  userId?: string;
  organizationId?: string;
  region?: string;

  constructor(options: IAddress) {
    const {
      _id,
      addressee,
      line1,
      line2,
      city,
      province,
      country,
      zipcode,
      specialInstructions,
      userId,
      organizationId,
      region
    } = options;

    this.addressee = addressee;
    this.line1 = line1;
    this.line2 = line2;
    this.city = city;
    this.province = province;
    this.country = country;
    this.zipcode = zipcode;
    this.specialInstructions = specialInstructions;
    this.userId = userId;
    this.organizationId = organizationId;
    this.region = region;

    if (_id) {
      this._id = _id;
    }

    if (userId) {
      this.userId = userId;
    }

    if (organizationId) {
      this.organizationId = organizationId;
    }
  }

  withoutId() {
    this._id = undefined;
    this.region = undefined;

    return this;
  }
}