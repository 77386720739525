import { Typography } from "@mui/material";
import Price from "../shared/Price";
import { IItemPrice } from "../Items/item.model";

interface IFundItemPriceProps {
  price: IItemPrice;
  textAlign?: 'left' | 'right';
}

export default function FundItemPrice({
  price,
  textAlign = 'right'
}: IFundItemPriceProps) {
  return (
    <Typography
      variant="subtitle2"
      color="text.secondary"
      component="p"
      align={textAlign}
    >
      <strong>
        <Price price={price} />
      </strong>
    </Typography>
  );
}
