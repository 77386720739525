import { useMutation } from "@apollo/client";
import { Box, Button, Divider, FormControl, FormGroup, Grid, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { SAVE_CAMPAIGN } from "../fund.queries";
import FundModel from "../fund.model";
import Loading from "../../Layout/Loading";
import LoadingError from "../../Layout/LoadingError";
import ErrorMessage from "../../shared/ErrorMessage";

export default function CampaignDetailInfoModal({ campaign, refetch, closeModal }: { campaign: any, refetch: () => Promise<any>, closeModal: () => void }) {
  const [fund, setFund] = useState(new FundModel(campaign));

  const [ saveFund, { loading, error } ] = useMutation(SAVE_CAMPAIGN, { variables: { campaign: fund.toFundInput() }});

  function attemptSaveFund() {
    saveFund()
      .then(() => refetch())
      .then(() => closeModal());
  }

  function updateFund(prop: string, e: any) {
    e.preventDefault();
    const value = e.target.value;

    setFund(new FundModel({ ...fund, [prop]: value }));
  }

  if (loading) return <Loading />

  return (
    <Grid item xs={12}>
      <Box sx={{
            '& > :not(style)': { m: 1, width: '100%' },
          }}>
          <Grid item xs={12} width="100%">
            <FormControl fullWidth={true}>
              <FormGroup>
                <TextField
                  variant="outlined"
                  id="name"
                  label="Name"
                  name="name"
                  value={fund.name}
                  required={true}
                  onChange={(e) => updateFund('name', e)}
                ></TextField>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth={true}>
              <FormGroup>
                <TextField
                  multiline={true}
                  variant="outlined"
                  id="description"
                  label="Description"
                  name="description"
                  value={fund.description}
                  required={true}
                  onChange={(e) => updateFund('description', e)}
                ></TextField>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth={true}>
              <Button variant="contained" color="secondary" onClick={attemptSaveFund}>Save</Button>
              <Button variant="outlined" color="primary" onClick={closeModal}>Close</Button>
            </FormControl>
          </Grid>
          {
            error && (
              <Grid item xs={12}>
                <ErrorMessage error={!!error} message={'Something when wrong while trying to save your changes.'} />
              </Grid>
            )
          }
        </Box>
    </Grid>
  )
}