import { HomeOutlined } from "@mui/icons-material";
import { Breadcrumbs, Link } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import { IOrganization } from "../Organizations/organization.model";

export default function DashboardBreadcrumbs({ organizations }: { organizations: IOrganization[] }) {
  const params = useParams();

  const org = params.organizationId ? organizations.find(organization => organization.uuid === params.organizationId) : null;

  const DashboardLink = (
    <Link component={RouterLink}
        to="/dashboard"
        color="inherit"
        underline="hover"
      ><HomeOutlined sx={{ mr: 0.5 }} fontSize="inherit" />Your Dashboard</Link>
  );
  
  const OrgDashboardLink = (
    <Link component={RouterLink}
        to={`/dashboard/organizations/${params.organizationId}`}
        color="inherit"
        underline="hover"
      ><HomeOutlined sx={{ mr: 0.5 }} fontSize="inherit" />{org?.name} Dashboard</Link>
  );

  return (
    <Breadcrumbs sx={{ mt: 1 }}>
      {
        org ? OrgDashboardLink : DashboardLink
      }
    </Breadcrumbs>
  )
}