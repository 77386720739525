import { Grid } from "@mui/material";

import { IAddress } from "./address.model";
import Address from "./Address";

export default function AddressList({ addresses, refreshAddresses }: { addresses: IAddress[], refreshAddresses: any }) {

  return (
    <Grid container rowSpacing={3} columnSpacing={3}>
      {addresses.map((address: IAddress) => {
        return (
          <Grid item key={address._id} xs={12} sm={6} md={4}>
            <Address key={address._id} address={address} refreshAddresses={refreshAddresses} showButtons={true} />
          </Grid>
        )
      })}
    </Grid>
  );
}
