import { useMutation } from "@apollo/client";
import { Alert, AlertTitle, Button, FormControl, FormGroup, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { UPDATE_USER_NAME } from "./user.queries";

export default function EditName({ givenName, familyName, onUserUpdated, closeModal } : { givenName: string, familyName: string, onUserUpdated: any, closeModal: any }) {
  const [newGivenName, setNewGivenName] = useState(givenName);
  const [newFamilyName, setNewFamilyName] = useState(familyName);

  const [mutateUpdateUser, { error }] = useMutation(UPDATE_USER_NAME, { variables: { familyName: newFamilyName, givenName: newGivenName } });

  function updateUser() {
    mutateUpdateUser()
      .then(() => {
        onUserUpdated();
        closeModal();
      });
  }

  function updateGivenName(e: any) {
    e.preventDefault();
    setNewGivenName(e.target.value);
  }

  function updateFamilyName(e: any) {
    e.preventDefault();
    setNewFamilyName(e.target.value);
  }

  function isFormValid() {
    // TODO: don't allow special characters.
    return !!newGivenName && newGivenName.length > 1 && !!newFamilyName && newFamilyName.length > 1;
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography>Update Your Name</Typography>
      </Grid>
      <Grid display={error ? 'block' : 'none'} item xs={12} sx={{ mb: 2 }}>
        <Alert severity="error" sx={{ display: error ? 'block' : 'none' }}>
          <AlertTitle>There was an error.</AlertTitle>
          We could not update your name at this time. Please contact support if this issue persists.
        </Alert>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <FormControl fullWidth>
          <FormGroup sx={{ mb: 2 }}>
            <TextField label="Given Name" variant="standard" id="givenName" name="givenName" value={newGivenName} required onChange={updateGivenName}></TextField>
          </FormGroup>
          <FormGroup>
            <TextField variant="standard" label="Family Name" id="familyName" name="familyName" value={newFamilyName} required onChange={updateFamilyName}></TextField>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Button variant="outlined" disabled={!isFormValid()} onClick={() => updateUser()}>Save</Button>
        <Button variant="outlined" onClick={() => closeModal()}>Cancel</Button>
      </Grid>
    </Grid>
  );
};
