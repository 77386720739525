import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CheckoutPayment from "./CheckoutPayment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useMutation, useQuery } from "@apollo/client";
import { DONATE, CURRENT_CART, CURRENCY_QUERY } from "./cart.queries";
import { isEmailValid } from "../utils/is-email-valid";

// Docs: https://stripe.com/docs/payments/quickstart?client=react&lang=node
if (!process.env.REACT_APP_STRIPE_PK) {
  throw new Error("Stripe PK missing.");
}
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export default function CheckoutDetails() {
  const { user } = useAuth0();
  
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [terms, setTerms] = useState(false);
  const [isLoadingStripe, setIsLoadingStripe] = useState(false);

  function updateEmail(e: any) {
    e.preventDefault();
    setEmail(e.target.value);
  }

  function updateName(e: any) {
    e.preventDefault();
    setName(e.target.value);
  }

  function updateTerms(e: any) {
    e.preventDefault();
    setTerms(!terms);
  }

  const { data } = useQuery(CURRENT_CART);
  const { data: currencyData } = useQuery(CURRENCY_QUERY);

  const [
    mutateCreatePaymentIntent,
    { data: paymentIntentData, error: createPaymentIntentError },
  ] = useMutation(DONATE, {
    variables: { cartId: data.currentCart._id, email, name, currency: currencyData.currency.value },
  });

  function next(e: any) {
    e.preventDefault();
    setIsLoadingStripe(true);
    mutateCreatePaymentIntent();
  }

  useEffect(() => {
    if (user) {
      setEmail(user.email || "");
      if (user.name && !user.name.includes('@')) {
        setName(user.name);
      }
    }
  }, [user]);

  const isFormValid = () => {
    return !!email && isEmailValid(email) && !!name && terms;
  };

  return (
    <Grid item>
      <Grid item sx={{ mt: 0, padding: 2, pt: 0 }} xs={12}>
        <Typography sx={{ mb: 2 }} hidden={!createPaymentIntentError} color="error">Oops, there seems to be an error. Please try again, or contact us if this persists.</Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <FormGroup sx={{ mb: 2 }}>
            <TextField
              id="name"
              label="Full Name"
              variant="standard"
              type="text"
              onChange={updateName}
              value={name}
              required={true}
            ></TextField>
          </FormGroup>

          <FormGroup>
            <TextField
              id="email"
              label="Email"
              variant="standard"
              type="email"
              onChange={updateEmail}
              value={email}
              required={true}
            ></TextField>
            <FormHelperText>
              We only use your email to notify you about updates relating to
              your donations.
            </FormHelperText>
          </FormGroup>
          <FormGroup>
            <FormControlLabel control={<Checkbox required value={terms} onChange={updateTerms} />} label="I agree to the Terms of Use and Privacy Policy." />
          </FormGroup>
        </FormControl>

        {!!paymentIntentData?.donate?.clientSecret ? (
          ""
        ) : (
          <Button
            sx={{ mt: 2 }}
            onClick={next}
            variant="contained"
            color="secondary"
            disabled={!isFormValid() || isLoadingStripe}
          >
            { isLoadingStripe ? 'Loading...': 'Next' }
          </Button>
        )}
      </Grid>
      {isFormValid() && paymentIntentData?.donate?.clientSecret ? (
        <Elements
          options={{ clientSecret: paymentIntentData.donate.clientSecret }}
          stripe={stripePromise}
        >
          <CheckoutPayment
            billingName={name}
            clientSecret={paymentIntentData.donate.clientSecret}
          />
        </Elements>
      ) : (
        ""
      )}
    </Grid>
  );
}
