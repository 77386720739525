export const homeStyles = {
  titles: {
    mt: 2,
    mb: 2
  },
  descriptions: {
    mt: 5
  },
  icons: {
    mt: 5
  },
  howItWorksItem: {
    textAlign: 'center'
  },

  titleSection: {
    pt: '3rem',
  },

  sectionContainer: {
    pt: 1,
    pb: 3,
    mb: 2
  }
}