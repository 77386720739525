import { gql } from "@apollo/client";
import { ITEM_FIELDS } from "./item.queries";

export const GET_ITEM_DATA_FROM_URL = gql`
  ${ITEM_FIELDS}
  query GetItemDataFromUrl($url: String) {
    getItemDataFromUrl(url: $url) {
      ...ItemFields
      source
      sourceId
    }
  }
`;

export const IMPORT_ITEM = gql`
  ${ITEM_FIELDS}
  mutation ImportItem($item: ItemInput) {
    importItem(item: $item) {
      ...ItemFields
      source
      sourceId
    }
  }
`;