import { Badge, Box, Button, Grid, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_ADDRESSES_BY_ORG, GET_ADDRESSES_BY_USER } from "../../Address/address.queries";
import Loading from "../../Layout/Loading";
import LoadingError from "../../Layout/LoadingError";
import { IAddress } from "../../Address/address.model";
import Address from "../../Address/Address";
import FundModel from "../fund.model";
import { useEffect, useState } from "react";
import SaveAddress from "../../Address/SaveAddress";
import { Modal } from "@mui/base";
import { saveAddressModalStyle } from "../../Address/address.styles";
/**
 *
 */
export default function SelectAddress({ fund, setFund, setCanGoToNextStep }: { fund: FundModel, setFund: any, setCanGoToNextStep: any }) {
  const params = useParams();
  const { organizationId } = params;

  useEffect(() => {
    if (fund.addressId) {
      setCanGoToNextStep(true);
    } else {
      setCanGoToNextStep(false);
    }
  }, [fund.addressId])

  const query = organizationId ? GET_ADDRESSES_BY_ORG : GET_ADDRESSES_BY_USER;
  const variables: Record<string, any> = organizationId ? { orgId: organizationId } : {};

  const { loading, error, data, refetch } = useQuery(query, {
    variables,
  });

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const addresses = data?.addressesByOrg ?? data?.addressesByUser;

  async function refreshAddresses() {
    refetch();
  }

  function selectAddress(address: IAddress) {
    setFund({ ...fund, addressId: address._id });
  }

  if (loading) return <Loading />
  if (error) <LoadingError />

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography component="h5" variant="body1">
          Where would you like to receive items?
        </Typography>
      </Grid>
      <Grid container rowSpacing={3} columnSpacing={3}>
        {addresses.map((address: IAddress) => (
          <Grid item key={address._id} xs={12} sm={6} md={4}>
            <Box sx={{
              width: '100%',
              border: fund.addressId === address._id ? '1px solid green' : 'none'
            }} onClick={() => selectAddress(address)}>
              <Badge sx={{ width: '100%' }} invisible={fund.addressId !== address._id} color="success" badgeContent="&#x2713;">
                <Address address={address} refreshAddresses={refreshAddresses} />
              </Badge>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Grid item>
        <Button onClick={() => handleOpenModal()} variant="outlined">Add a new address</Button>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={saveAddressModalStyle}>
            <SaveAddress organizationId={organizationId} closeModal={handleCloseModal} refreshAddresses={refreshAddresses} />
          </Box>
        </Modal>
      </Grid>

    </Grid>
  );
}
