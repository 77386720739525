import English from './en.json';
import Spanish from './es-ES.json';

export const TRANSLATIONS = {
  // en
  'en': English,
  'en-CA': English,
  'en-US': English,
  // es
  'es': Spanish,
  'es-ES': Spanish
};

export type SUPPORTED_LOCALES = keyof typeof TRANSLATIONS;
