/**
 *
 * A ListItem for the Funds list.
 */
import FundItemModel from "./fund-item.model";
import ItemModel from "../Items/item.model";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from "@mui/material";

import FundItemProgress from "./FundItemProgress";

// Assets
import defaultImage from "../shoppingbag.webp";
import AddFundItemToCart from "./AddFundItemToCart";
import FundItemPrice from "./FundItemPrice";
import GoalCompleted from "./components/GoalCompleted";
import { fundItemStylesForFundPage } from "../Items/item.styles";

interface IFundItemProps {
  fundItem: FundItemModel;
  item?: ItemModel;
  fundId: string;
  cartItems: any[];// TODO - better typings.
}

export default function FundItem({ fundItem, fundId, cartItems }: IFundItemProps) {
  function getCurrentlyInCart(): number {
    if (cartItems) {
      const cartItem = cartItems.find(items => items.item._id === fundItem.item._id);

      if (cartItem) return cartItem.quantity;

      return 0;
    }

    return 0;
  }

  const currentlyInCart = getCurrentlyInCart();
  const leftToCompleteGoal = fundItem.totalGoal - fundItem.raised;

  return (
    <Card sx={{ display: { xs: 'block', md: 'flex' }, marginBottom: 2 }}>
      {fundItem.item?.imageUrl ? (
        <CardMedia
          sx={fundItemStylesForFundPage}
          image={fundItem.item.imageUrl}
        ></CardMedia>
      ) : (
        <CardMedia sx={{ width: 151 }} image={defaultImage}></CardMedia>
      )}

      <Box sx={{ display: 'block', flexDirection: 'column', width: '100%' }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8}>
              <Grid container>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography component="div" variant="body1" pb={3}>
                    {fundItem.item?.name}
                  </Typography>
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                  <FundItemPrice
                    price={fundItem.item.price}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FundItemProgress
                    raised={fundItem.raised}
                    totalGoal={fundItem.totalGoal}
                    inCart={currentlyInCart}
                  />
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    {fundItem.raised} of {fundItem.totalGoal} raised. {
                      leftToCompleteGoal > 0 ?
                      (`${leftToCompleteGoal} left to complete goal.`)
                      : ''
                    }
                  </Typography>
                  <Typography
                    hidden={leftToCompleteGoal < 1}
                    variant="subtitle1"
                    color={ currentlyInCart > 0 ? 'secondary' : '' }
                    component="div"
                  >
                    <strong>{currentlyInCart}</strong> currently in your cart.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              {
                leftToCompleteGoal < 1 ?
                <GoalCompleted />
                :
                <AddFundItemToCart
                  fundId={fundId}
                  itemId={fundItem.item._id}
                  max={leftToCompleteGoal}
                  inCart={currentlyInCart}
                  />
              }
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </Card>
  );
}
