import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import ItemSummary from "../../Items/ItemSummary";
import { IItemOptions } from "../../Items/item.model";
import SetFundItemQuantity from "../components/SetFundItemQuantity";
/**
 *
 */
export default function SetItemQuantityComponent({ isWishlist, item, addFundItem, initialQuantity = 10 }: { isWishlist: boolean|undefined, item?: IItemOptions|null, addFundItem: any, initialQuantity?: number }) {
  const [ totalGoal, setTotalGoal ] = useState<number>(initialQuantity);

  function isDisabled() {
    if (isWishlist) {
      return !item;
    }
    // For campaigns, we need a goal amount.
    return !item || !totalGoal;
  }

  function attemptAddFundItem() {
    addFundItem(totalGoal);
  }

  return (
    <Grid container rowSpacing={2} columnSpacing={3}>
      <Grid item xs={12} sm={6}>
        {
          item ?
          (<ItemSummary item={item} />) :
          (<Typography paragraph>No item selected.</Typography>)
        }
      </Grid>
      
        {
          isWishlist || !item ?
          (
            <Grid item xs={12} sm={6}>
              <Button fullWidth disabled={isDisabled()} color="secondary" variant="contained" onClick={attemptAddFundItem}>Add Item</Button>
            </Grid>
          )
          :
          (
          <Grid item xs={12} sm={6} textAlign="center">
            <SetFundItemQuantity quantity={totalGoal} setQuantity={setTotalGoal} />

            <Button fullWidth disabled={isDisabled()} color="secondary" variant="contained" onClick={attemptAddFundItem}>Save Item</Button>
          </Grid>
          )
        }
    </Grid>
  );
}
