import { gql } from "@apollo/client";

export const VENDOR_ORDER_FIELDS = gql`
  fragment VendorOrderFields on VendorOrder {
    _id
    vendor
    orderNumber
    currency
    orderStatus
    deliveryStatus
    orderDate
    createdAt
  }
`;

export const GET_VENDOR_ORDER = gql`
  query GetVendorOrder($id: ID!) {
    getVendorOrder(id: $id) {
      ...VendorOrderFields
    }
  }
`;

export const GET_LATEST_VENDOR_ORDERS = gql`
  query LatestVendorOrders {
    latestVendorOrders {
      ...VendorOrderFields
    }
  }
`;

export const CREATE_VENDOR_ORDER = gql`
  mutation CreateVendorOrder($vendorOrder: VendorOrderInput!) {
    createVendorOrder(vendorOrder: $vendorOrder) {
      ...VendorOrderFields
    }
  }
`;

export const MARK_VENDOR_ORDER_SENT = gql`
  mutation MarkVendorOrderSent($id: ID!, $estimatedDeliveryDate: String!) {
    markVendorOrderSent(vendorOrderId: $id, estimatedDeliveryDate: $estimatedDeliveryDate) {
      ...VendorOrderFields
    }
  }
`;

export const MARK_VENDOR_ORDER_DELIVERED = gql`
  mutation MarkVendorOrderSent($id: ID!) {
    markVendorOrderDelivered(vendorOrderId: $id) {
      ...VendorOrderFields
    }
  }
`;
