import { useQuery } from "@apollo/client";
import { Grid, FormControl, FormGroup, TextField, Button, Typography, Box } from "@mui/material";
import { useState } from "react";
import { GET_ITEM_DATA_FROM_URL } from "./item.admin.queries";
import ItemImportForm from "./ItemImportForm";

/**
 *
 */
export default function ItemImportFromUrlForm() {
  const [ url, setUrl ] = useState('');

  const { data, loading, error, refetch } = useQuery(GET_ITEM_DATA_FROM_URL, { skip: url.length < 10, variables: { url } });
  
  const item = data?.getItemDataFromUrl ?? null;

  function updateUrl(e: any) {
    const value = e.target.value;
    setUrl(value);
  }

  return (
    <Grid container rowSpacing={1}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth={true}>
            <FormGroup>
              <TextField
                variant="outlined"
                id="url"
                label="Vendor URL"
                name="url"
                value={url}
                required={true}
                onChange={(e) => updateUrl(e)}
              ></TextField>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {/* <Button fullWidth variant="contained" color="primary">Extract Item Info</Button> */}
        </Grid>
      </Grid>

      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          {
            loading && <Typography>Loading...</Typography>
          }

          {
            error && <Typography>Error: { error.message }</Typography>
          }

          {
            data?.getItemDataFromUrl && (<ItemImportForm item={data?.getItemDataFromUrl} />)
          }
        </Grid>
      </Grid>
    </Grid>
  );
}
