import { useQuery } from "@apollo/client";
import { Container, Typography, Grid, Divider, Link } from "@mui/material";
import { useParams } from "react-router-dom";

import LoadingError from "../Layout/LoadingError";
import Loading from "../Layout/Loading";

import { GET_ORGANIZATION_PUBLIC } from "./organization.queries";
import NotFound from "../pages/not-found";
import { Verified } from "@mui/icons-material";
import logoSquarePink from "../logo-d-white-on-pink-square-100x100.png";
import Fund from "../Funds/Fund";

/**
 * Public profile page for Org.
 */
export default function OrganizationDonaticPage() {
  const params = useParams();
  const { organizationId } = params;

  const { loading, error, data } = useQuery(GET_ORGANIZATION_PUBLIC, { variables: { uuid: organizationId } });

  if (loading) return <Loading />;
  if (error || !organizationId) return <LoadingError />;

  const organization = data.organization;

  if (!organization) {
    return (<NotFound />);
  }

  return (
    <Container sx={{ pt: 2 }}>
      <Grid container rowSpacing={3} columnSpacing={3} maxWidth='md' sx={{ margin: { md: '0 auto !important', lg: '0 auto !important' } }}>
        <Grid item xs={12} textAlign="center">
          <img src={organization.logoUrl ?? logoSquarePink} height="60" />
          <Typography component="h5" variant="h5">
            {organization.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Typography component="p" variant="body1">
            {
              organization.description ? organization.description : 'No description yet.'
            }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography component="p" variant="body1">
            Website: <Link color="secondary" href={organization.website} target="_blank">{organization.website}</Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h5" variant="h5" color="primary" sx={{ mb: 2 }}>
            Campaigns
          </Typography>
          <Grid container spacing={4}>
          {
            organization.campaigns.map((campaign: any, index: number) => {
              return (
                <Grid key={index} item xs={12} sm={12} md={4}>
                  <Fund fund={campaign} />
                </Grid>
              )
            })
          }
          {
            organization.campaigns?.length < 1 && (
              <Grid item xs={12}>
                <Typography component="p" variant="body1">
                  No campaigns yet.
                </Typography>
              </Grid>
            )
          }
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
