import { Box, Grid, FormGroup, MenuItem, Select, Typography } from "@mui/material";
import { useState } from "react";
import CreateVendorOrderDonationFormComponent from "./CreateVendorOrderDonationFormComponent";

export default function CreateVendorOrderDonationComponent({ donationItems, latestVendorOrders, donationId }: { donationItems: any[], latestVendorOrders: any[], donationId: string }) {
  const [ vendorOrderId, setVendorOrderId ] = useState<string|undefined>('');

  if (donationItems.length < 1) {
    return (
      <Box>
        <Typography>No items to order!</Typography>
      </Box>
    )
  }
  
  return (
    <Box>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <FormGroup>
            <Select
              variant="outlined"
              value={vendorOrderId}
              labelId="vendorOrderId"
              label="Vendor Order ID"
              name="vendorOrderId"
              required
              onChange={(e) => setVendorOrderId(e.target.value)}
            >
              {
                latestVendorOrders?.map((vendorOrder: any, index: number) => {
                  return (<MenuItem key={index} value={vendorOrder._id}>{vendorOrder.vendor} - {vendorOrder.orderNumber}</MenuItem>)
                })
              }
            </Select>
          </FormGroup>
        </Grid>

        {
          vendorOrderId && (
            <Grid item xs={12}>
              <Typography>Vendor Order ID: {vendorOrderId}</Typography>

              <CreateVendorOrderDonationFormComponent donationItems={donationItems} donationId={donationId} vendorOrderId={vendorOrderId!} />
            </Grid>
          )
        }
      </Grid>
    </Box>
  )
}