/**
 *
 * Pagination buttons.
 */
import { Button, Grid } from "@mui/material";

export default function PaginationButtons({ hasMore, sx, loadMore }: { hasMore: boolean, loadMore: any, sx?: Record<string, any> }) {
  return (
    <Grid container spacing={4} sx={sx ?? {}}>
        {
          hasMore ?
          (
            <Grid item xs={12} textAlign="center">
              <Button variant="contained" color="secondary" onClick={() => loadMore()}>Load More</Button>
            </Grid>
          ): ''
        }
    </Grid>
  );
}
