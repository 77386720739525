/**
 *
 */
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { TRANSLATIONS } from "../lang/translations";
import { homeStyles } from "./home-styles";

export default function HowItWorksIntro() {
  return (
    <Box component="div" sx={homeStyles.titleSection}>
      <Typography component="h5" variant="h5" sx={{ mb: 5 }}>
        <FormattedMessage id="how-it-works.title" defaultMessage={TRANSLATIONS.en['how-it-works.title']} />
      </Typography>
      <Typography paragraph variant="body1">
        Our goal is to empower individuals to donate physical items rather than
        monetary donations, giving them{" "}
        <Typography color="secondary" component="span">
          <strong>100% transparency</strong>
        </Typography>{" "}
        of the process and where their money is going. Items donated through our
        platform are sent directly to the person or organization fundraising for
        them.
      </Typography>
    </Box>
  );
}
