import { useQuery } from "@apollo/client";
import { Typography, Grid, Modal, Box, Button } from "@mui/material";
import { useParams } from "react-router-dom";

import LoadingError from "../Layout/LoadingError";
import Loading from "../Layout/Loading";

import { GET_ORGANIZATION } from "./organization.queries";
import { saveAddressModalStyle } from "../Address/address.styles";
import { useState } from "react";
import SaveOrganization from "./SaveOrganization";
import FileUploader from "../File/FileUploader";

/**
 *
 */
export default function OrganizationSettingsPage() {
  const params = useParams();
  const { organizationId } = params;

  const { loading, error, data, refetch } = useQuery(GET_ORGANIZATION, { variables: { uuid: organizationId } });

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  if (loading) return <Loading />;
  if (error || !organizationId) return <LoadingError />;

  const organization = data.organization;

  // TODO: Add form to update this.
  // TODO: Authorization.
  // TODO: Default address.

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>Organization Name:</strong> {organization.name}
        </Typography>
        <Typography variant="body1">
        <strong>Description:</strong>  {organization.description}
        </Typography>
        <Typography variant="body1">
        <strong>Website:</strong>  {organization.website ?? '--'}
        </Typography>
        <Typography variant="body1">
        <strong>Email:</strong>  {organization.email ?? '--'}
        </Typography>

        <Button sx={{ mt: 1}} variant="outlined" onClick={handleOpenModal}>Edit Organization</Button>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={saveAddressModalStyle}>
            <SaveOrganization organization={organization} closeModal={handleCloseModal} />
          </Box>
        </Modal>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1"><strong>Logo:</strong></Typography>
        {
          organization.logoUrl ? <img height="60" src={organization.logoUrl} /> : ''
        }

        <FileUploader promptText="Logo" property="logoUrl" organizationId={organization._id} refetch={refetch} />  
      </Grid>
    </Grid>
  );
}
