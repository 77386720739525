import { Typography, Grid, Divider, Card, CardContent, CardMedia } from "@mui/material";
import { IOrganization } from "../Organizations/organization.model";
import { useNavigate } from "react-router-dom";
import { buildDashPath } from "./build-dash-path";

/**
 *
 */
export default function DashboardOrgsList({ organizations }: { organizations: IOrganization[] }) {
  const navigate = useNavigate();

  if (organizations.length < 1) {
    return (<div></div>);
  }

  function goToOrgDash(orgUUID: string) {
    navigate(`/dashboard/organizations/${orgUUID}`);
  }

  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Typography variant="body2"><strong>Your Organizations:</strong> Click below to go to view the dashboard for your organization{ organizations.length > 1 ? 's' : '' }.</Typography>
      </Grid>
      {
        organizations.map((organization: IOrganization, index: number) => {
          return (
            <Grid item xs={12} sm={6}key={index}>
              <Card sx={{ mb: 2, cursor: 'pointer' }} onClick={() => goToOrgDash(organization.uuid)}>
                <CardMedia
                  component="img"
                  sx={{ width: 151, height: 151 }}
                  image={organization.logoUrl}
                />
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography component="p" color="primary" variant="body1">
                        { organization.name }
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
        })
      }
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
