import { Container, Typography, Link, Grid, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import SocialShare from "../Social/SocialShare";
import { ExpandMore } from "@mui/icons-material";
import { FaqContent } from "./faq-content";
/**
 *
 * FAQ Page
 */
export default function FaqPage() {
  const shareURL = window.location.protocol + '//' + window.location.host;
  const shareTitle = 'Donatic: Give things, not money. Donate with us today.';
  const shareEmailSubject = 'Checkout Donatic, a platform for donating (new) items.';
  const shareEmailBody = 'Donatic is a platform where fundraisers can create campaigns to receive items in new condition. Check it out!';
  
  return (
    <Container sx={{ pt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography component="h2" variant="h5" sx={{ marginBottom: 1 }}>
            Frequently Asked Questions
          </Typography>

          <Typography paragraph sx={{ typography: "body1" }}>
            If you can't find the answer to your question here, feel free to send us a message at{" "}
            <Link color="primary" href="mailto:donate@donatic.app">
              donate@donatic.app
            </Link>.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {
            FaqContent.map((faq, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {faq.question}
                </AccordionSummary>
                <AccordionDetails>
                  {faq.content}
                </AccordionDetails>
              </Accordion>
            ))
          }
        </Grid>

        <Grid item xs={12}>
          <Typography paragraph>
            Enjoying Donatic? Help us spread the word!
          </Typography>
          <SocialShare url={shareURL} title={shareTitle} quote={shareEmailBody} emailSubject={shareEmailSubject} emailBody={shareEmailBody}  />
        </Grid>
      </Grid>
    </Container>
  );
}
