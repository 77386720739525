interface IQuantityProps {
  quantity: number;
  delimiter: string;
}

export default function Quantity({ quantity, delimiter }: IQuantityProps) {
  const reversed = String(quantity).split("").reverse().join("");
  const matches = reversed.match(/.{1,3}/g);
  const formatted = matches
    ?.map((item) => item.split("").reverse().join(""))
    .reverse()
    .join(delimiter);

  return <span>{formatted}</span>;
}
