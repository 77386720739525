import { Box, Grid, Typography } from "@mui/material";

export default function CheckoutConfirmationNotFound() {
  return (
    <Grid item xs={12} md={8}>
      <Box>
        <Typography component="h2" variant="h2">
          Something went wrong...
        </Typography>
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <Typography paragraph sx={{ typography: "body1" }} color="primary">
          We can't seem to find what you're looking for. Why not check out some
          funds to donate to instead?
        </Typography>
      </Box>
    </Grid>
  );
}
