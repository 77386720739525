import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { StyledTableCell, StyledTableRow, IColumn } from "../shared";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Paper, Stack, styled, TableCell, Typography } from '@mui/material';
import Price from '../../shared/Price';
import { CURRENCY } from '../../Currency/currencies';
import { useState } from 'react';
import { VendorOrder } from '../../VendorOrder/vendor-order.model';

interface IValueParams {
  orderedItem: any;
  exchangeRate: number;
  vendorOrder?: VendorOrder;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const columns: IColumn[] = [
  {
    header: 'Image',
    getValue: ({ orderedItem }: IValueParams) => {
      return orderedItem?.item.imageUrl ? (
        <img src={orderedItem?.item.imageUrl} alt={orderedItem?.item.name} style={{ width: '50px', height: '50px' }} />
      ) : (
        <img src="https://via.placeholder.com/50" alt={orderedItem?.item.name} style={{ width: '50px', height: '50px' }} />
      )
    }
  },
  {
    header: 'Name',
    getValue: ({ orderedItem }: IValueParams) => orderedItem?.item?.name ?? ''
  },
  {
    header: 'Quantity',
    getValue: ({ orderedItem }: IValueParams) => orderedItem?.quantity ?? ''
  },
  {
    header: 'Donatic Price',
    getValue: ({ orderedItem }: IValueParams) => {
      // TODO - set to Donation currency
      return (<Price price={orderedItem?.item?.price} />)
    }
  },
  {
    header: 'Vendor Price',
    getValue: ({ orderedItem, vendorOrder }: IValueParams) => {
      return (<Price value={orderedItem.vendorValue} overrideCurrency={vendorOrder?.currency} />)
    }
  },

  // TODO: this is very hacky. Use an actual exchange rate based on the currencies.
  {
    header: 'Vendor Price (USD)',
    getValue: ({ orderedItem, exchangeRate }: IValueParams) => {
      return (<Price value={Math.floor(orderedItem.vendorValue * exchangeRate )} />)
    }
  },

  {
    header: 'Profit (USD)',
    getValue: ({ orderedItem, exchangeRate }: IValueParams) => {
      const value = orderedItem?.item?.price?.USD - Math.floor(orderedItem.vendorValue * exchangeRate);
      return (<Price value={value} overrideCurrency={CURRENCY.USD} />)
    }
  },
];

export default function VendorOrderDonationOrderedItemsListComponent({ vendorOrderDonations }: { vendorOrderDonations: any[] }) {
  const [exchangeRate] = useState<number>(0.05);

  const itemsByVendorOrderId = vendorOrderDonations.reduce((acc, vendorOrderDonation) => {
    const vendorOrder = vendorOrderDonation.vendorOrder;
    const orderedItems = vendorOrderDonation.orderedItems;

    if (!acc[vendorOrder._id]) {
      acc[vendorOrder._id] = orderedItems
    } else {
      acc[vendorOrder._id].orderedItems = acc[vendorOrder._id].orderedItems.concat(orderedItems);
    }

    return acc;
  }, {});

  const vendorOrderIds = Object.keys(itemsByVendorOrderId);

  const vendorOrders = vendorOrderIds?.map(vendorOrderId => vendorOrderDonations?.find(vendorOrderDonation => vendorOrderDonation.vendorOrder._id === vendorOrderId)?.vendorOrder);

  return (
  <Box>
    {
      vendorOrders.map((vendorOrder: any, index: number) => {
      return (
        <Box key={index}>
          <Stack direction="row" spacing={2}>
            <Item>
              <Typography color="primary">Vendor: {vendorOrder.vendor}</Typography>
            </Item>
            <Item>
              <Typography color="primary">Order Number: {vendorOrder.orderNumber}</Typography>
            </Item>
            <Item>
              <Typography color="primary">Approx. Profit: &nbsp;
                {
                  <Price value={
                  itemsByVendorOrderId[vendorOrder._id]?.reduce((acc: number, orderedItem: any) => {
                    return orderedItem?.item?.price?.USD - Math.floor(orderedItem.vendorValue * exchangeRate) + acc
                  }, 0)} overrideCurrency={CURRENCY.USD} />
                }
              </Typography>
            </Item>
          </Stack>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {
                    columns.map((column: any, index: number) => {
                      return (<StyledTableCell key={index}>{column.header}</StyledTableCell>)
                    })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {itemsByVendorOrderId[vendorOrder._id]?.map((orderedItem: any[], index: number) => {
                  return (
                    <StyledTableRow key={index}>
                      {
                        columns.map((column: any, i: number) => {
                          return (<StyledTableCell key={i}>{column.getValue({ orderedItem, vendorOrder, exchangeRate })}</StyledTableCell>)
                        })
                      }
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>)
      })
    }
  </Box>
  );
}