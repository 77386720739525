import React from "react";
import Box from "@mui/material/Box";
import { Avatar, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import './NavBarButton.css';

import LogoutButton from "../Login/LogoutButton";

export default function UserMenu() {
  const navigate = useNavigate();
 
  const settings = [
    { title: "Dashboard", path: "/dashboard" },
  ];

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (path?: string) => {
    setAnchorElUser(null);
    if (path) {
      navigate(path);
    }
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt="Settings" src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={() => handleCloseUserMenu()}
      >
        {settings.map((setting) => (
          <MenuItem key={setting.title} onClick={() => handleCloseUserMenu(setting.path)}>
            <Typography color="primary" className="NavBarButton">
            { setting.title }
            </Typography>
          </MenuItem>
        ))}
        <MenuItem onClick={() => handleCloseUserMenu()}>
          <LogoutButton />
        </MenuItem>
      </Menu>
    </Box>
  );
}
