import { Container, Grid, Button, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

/**
 *
 * Contact
 */

export default function CartEmpty() {
  return (
    <Container sx={{ pt: 4 }}>
      <Grid container>
        <Grid item xs={12} sx={{ marginTop: 2 }} textAlign="center">
          <Typography>Your cart is empty! Browse funds to donate.</Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 2 }} textAlign="center">
          <Button variant="contained" color="secondary">
            <Link component={RouterLink} to="/donate" sx={{ color: "#fff" }}>
              Browse Funds
            </Link>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
  // TODO: add suggested funds.
}
