import { Container, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { IFundOptions } from "../fund.model";
import { useMutation } from "@apollo/client";
import { ADD_ITEM_TO_FUND } from "../fund.queries";
import SetItemQuantityComponent from "./SetItemQuantityComponent";
import Loading from "../../Layout/Loading";
import LoadingError from "../../Layout/LoadingError";
/**
 *
 */
export default function SetFundItemContainer({ fund, closeModal, fundType, item, fundItem }: { fund: IFundOptions, closeModal: any, fundType: string, item: any, fundItem: any }) {
  const [addItemToFund, { loading, error }] = useMutation(ADD_ITEM_TO_FUND);

  function addFundItem(totalGoal: number) {
    if (!item) return;
    addItemToFund({ variables: { fundId: fund._id!, itemId: item._id, quantity: Number(totalGoal) },
      onCompleted: () => closeModal()
    });
  }

  const currentStep = 0;

  const steps = [
    {
      stepTitle: fund.isWishlist ? 'Confirm Item' : 'Set Goal',
      component: (<SetItemQuantityComponent isWishlist={fund.isWishlist} item={item} addFundItem={addFundItem} initialQuantity={fundItem.totalGoal} />)
    }
  ];

  // TODO: handle loading & error.
  if (loading) return (<Loading />);
  if (error) return (<LoadingError />);

  return (
    <Container>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Stepper activeStep={currentStep}>
            { steps.map((step: any, index: number) => (
              <Step key={index} completed={index < currentStep}>
                <StepLabel>
                  {step.stepTitle}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h5" variant="body1">
            Add an item to your {fundType}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {
            steps[currentStep].component
          }
        </Grid>
      </Grid>
    </Container>
  );
}
