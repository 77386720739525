import { makeVar, gql } from "@apollo/client";
import { ICart } from "../Cart/cart.interface";
import { DEFAULT_CURRENCY_SETTING, ICurrencySetting } from "../Currency/currencies";

export const typeDefs = gql`
  extend type Query {
    currentCart: Cart
    cartNotification: String
  }
`;

export const cartVar = makeVar<ICart>({
  _id: undefined,
  items: [],
  totals: { subtotal: 0, taxes: 0 },
  fundId: undefined
});

export const currencyVar = makeVar<ICurrencySetting>(DEFAULT_CURRENCY_SETTING);
