import { useQuery } from "@apollo/client";
import { Button, Grid, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { GET_DONATIONS_BY_USER } from "./donation.queries";

import LoadingError from "../Layout/LoadingError";
import Loading from "../Layout/Loading";
import { DonationModel } from "./donation.model";
import DonationSummary from "./DonationSummary";

/**
 * User's own donations. Like a "My Orders" page. The donations the user has made.
 */
export default function UserDonationsPage() {
  const { loading, error, data } = useQuery(GET_DONATIONS_BY_USER);

  if (loading) return <Loading />;
  if (error) return <LoadingError />;

  const donations = data.donationsByUser ?? [];

  // TODO: CTA to donate if no donations yet.

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5">Your Donations</Typography>
      </Grid>
      {
        donations.length < 1 ?
        (
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ mb: 2 }}>You haven't donated anything yet. Why not check out some current campaigns?</Typography>

          <Button
            variant="contained"
            color="secondary"
            component={RouterLink} to="/donate"
          >Make a positive impact</Button>
        </Grid>
        ) : (
          <Grid item xs={12}>
            { donations.map((d: any) => new DonationModel(d)).map((donation: DonationModel, index: number) => {
              return <DonationSummary key={index} donation={donation} />
            }) }
          </Grid>
        )
      }
    </Grid>
  );
}
