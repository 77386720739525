import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { StyledTableCell, StyledTableRow, IColumnWithElement } from "../shared";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Checkbox, Paper, TextField, Typography } from '@mui/material';
import Price from '../../shared/Price';

const columns: IColumnWithElement[] = [
  {
    header: 'Image',
    getValue: (donationItem: any) => {
      return donationItem.item.imageUrl ? (
        <img src={donationItem.item.imageUrl} alt={donationItem.item.name} style={{ width: '50px', height: '50px' }} />
      ) : (
        <img src="https://via.placeholder.com/50" alt={donationItem.item.name} style={{ width: '50px', height: '50px' }} />
      )
    }
  },
  {
    header: 'Name',
    getValue: (donationItem: any) => donationItem.item?.name ?? ''
  },
  {
    header: 'Quantity',
    getValue: (donationItem: any) => donationItem?.quantity ?? ''
  },
  {
    header: 'Donatic Price',
    getValue: (donationItem: any) => {
      // TODO - show price in the currency of the donation. For now, is only USD
      return (<Price price={donationItem.item.price} />)
    }
  },
]

export default function AdminDonationItemTablePending({ donationItems }: { donationItems: any[], vendorOrders: any[] }) {
  if (donationItems?.length === 0) {
    return (
      <Box>
        <Typography paragraph>🙌 All items have been ordered!</Typography>
    </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          {
            columns.map((column: any, index: number) => {
              return (<StyledTableCell key={index}>{column.header}</StyledTableCell>)
            })
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {donationItems?.map((donationItem: any, index: number) => {
        return (
          <StyledTableRow key={index}>
            {
              columns.map((column: any, i: number) => {
                return (<StyledTableCell key={i}>{column.getValue(donationItem)}</StyledTableCell>)
              })
            }
          </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
  );
}