import { List, ListItem, ListItemText } from "@mui/material";
import { IDonation } from "../Donations/donation.model";
import Price from "./Price";
import { CURRENCY } from "../Currency/currencies";

export interface IPaymentSummaryParams {
  currency: CURRENCY;
  subtotal: number;
  serviceFee: number;
  taxes: number;
  total: number;
}

export default function PaymentSummaryList({ currency, subtotal, serviceFee, taxes, total }: IPaymentSummaryParams) {
  return (
    <List disablePadding dense sx={{ li: { padding: 0 } }}>
      <ListItem>
        <ListItemText>
          Subtotal: <Price overrideCurrency={currency} value={subtotal} />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          Convenience Fee: <Price overrideCurrency={currency} value={serviceFee}/>
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          Taxes: <Price overrideCurrency={currency} value={taxes}/>
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          Total: <Price overrideCurrency={currency} value={total}/>
        </ListItemText>
      </ListItem>
    </List>
  );
}
