import React, { ReactElement, ReactNode } from "react";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  split,
  from,
  HttpLink,
} from "@apollo/client";
import { createFragmentRegistry } from "@apollo/client/cache";
import { getMainDefinition } from "@apollo/client/utilities";
// import { onError } from "@apollo/client/link/error";
import { apiUrl, wsBaseUrl } from "./client";
import { useAuth0 } from "@auth0/auth0-react";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { cartVar, currencyVar, typeDefs } from "./cache";
import { ICart } from "../Cart/cart.interface";
import { CURRENCY } from "../Currency/currencies";
import { ITEM_FIELDS } from "../Items/item.queries";
import { CART_TOTALS_FIELDS } from "../Cart/cart.queries";
import { FUND_FIELDS } from "../Funds/fund.queries";
import { ADDRESS_FIELDS } from "../Address/address.queries";
import { VENDOR_ORDER_FIELDS } from "../VendorOrder/vendor-order.queries";
import { VENDOR_ORDER_DONATION_FIELDS } from "../VendorOrderDonation/vendor-order-donation.queries";

/**
 * ApolloProvider with the client
 */
export default function CustomApolloProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const { getAccessTokenSilently, user } = useAuth0();

  const httpLink = new HttpLink({
    uri: `${apiUrl}/graphql`,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${wsBaseUrl}/graphql`,
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const client = React.useMemo(() => {
    const authLink = setContext(async (_, { headers, connectionParams }) => {
      const defaultVal = {
        headers: {
          ...headers,
          authorization: "",
        },
        connectionParams: {
          ...connectionParams,
          authToken: "",
        },
      };

      if (user) {
        try {
          const token = await getAccessTokenSilently();

          return {
            headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : "",
            },
            connectionParams: {
              ...connectionParams,
              authToken: token,
            },
          };
        } catch (error) {
          return defaultVal;
        }
      } else {
        return defaultVal;
      }
    });

    const client = new ApolloClient({
      link: from([authLink, splitLink]),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              currency: {
                read() {
                  return currencyVar();
                },
                merge (_: CURRENCY, incoming: CURRENCY) {
                  return incoming;
                }
              },
              currentCart: {
                read() {
                  return cartVar();
                },
                merge(existing: ICart, incoming: ICart) {
                  const items = [...incoming.items];
                  return { ...existing, ...incoming, items };
                },
              },
              publicCampaigns: {
                // Don't cache separate results based on
                // any of this field's arguments.
                keyArgs: false,
      
                // Concatenate the incoming list items with the existing list items.
                merge(existing, incoming) {
                  return {
                    ...existing,
                    ...incoming,
                    page: incoming.page,
                    funds: [...existing?.funds ?? [], ...incoming?.funds ?? [] ]
                  }
                },
              },
              publicWishlists: {
                // Don't cache separate results based on
                // any of this field's arguments.
                keyArgs: false,
      
                // Concatenate the incoming list items with the existing list items.
                merge(existing, incoming) {
                  return {
                    ...existing,
                    ...incoming,
                    page: incoming.page,
                    funds: [...existing?.funds ?? [], ...incoming?.funds ?? [] ]
                  }
                },
              },
              searchItem: {
                keyArgs: false,
                merge(existing, incoming) {
                  return {
                    ...existing,
                    ...incoming,
                    page: incoming.page,
                    items: [...existing?.items ?? [], ...incoming?.items ?? [] ]
                  }
                },
              },
            },
          },
        },
        fragments: createFragmentRegistry(
          ADDRESS_FIELDS,
          CART_TOTALS_FIELDS,
          FUND_FIELDS,
          ITEM_FIELDS,
          VENDOR_ORDER_DONATION_FIELDS,
          VENDOR_ORDER_FIELDS
        )
      }),
      typeDefs,
    });

    return client;
  }, [user, getAccessTokenSilently, splitLink]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
