import { CURRENCY } from "../Currency/currencies";
import { VENDOR_SOURCES, CURRENCIES } from "../utils/currencies-by-country";

export enum VENDOR_ORDER_STATUS {
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  REFUNDED = 'REFUNDED'
}

export enum VENDOR_ORDER_DELIVERY_STATUS {
  PENDING = 'PENDING',
  ORDERED = 'ORDERED',
  IN_PROGRESS = 'IN_PROGRESS',
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED'
}

export interface IVendorOrderUpdate {
  notes: string;
  type: string;
  date: Date;
  user: string;
}

export class VendorOrder {
  vendor?: typeof VENDOR_SOURCES;
  orderNumber?: string;
  shippingCost: number;
  currency?: CURRENCY;

  orderStatus?: VENDOR_ORDER_STATUS;
  deliveryStatus?: VENDOR_ORDER_DELIVERY_STATUS;

  updates?: IVendorOrderUpdate[];

  createdAt?: Date
  updatedAt?: Date

  constructor(options: Record<string, any>) {
    this.vendor = options.vendor ?? undefined;
    this.orderNumber = options.vendorOrderNumber ?? undefined;
    this.shippingCost = options.shippingCost ?? undefined;
    this.currency = options.currency ?? undefined;

    this.orderStatus = options.orderStatus ?? VENDOR_ORDER_STATUS.PAID;
    this.deliveryStatus = options.deliveryStatus ?? VENDOR_ORDER_DELIVERY_STATUS.PENDING;

    this.updates = options.upates ?? [];

    this.createdAt = options.createdAt ?? undefined;
    this.updatedAt = options.updatedAt ?? undefined;
  }
}