import { Box, Button, ButtonGroup, Card, CardContent, CardMedia, Grid, Modal, Typography } from "@mui/material";
import FundItemPrice from "../FundItemPrice";
import { IFundItemOptions } from "../fund-item.model";
import { fundItemStyles } from "../../Items/item.styles";
import { DeleteForever, Edit } from "@mui/icons-material";
import SetFundItemContainer from "../Steps/SetItemQuantityContainer";
import { addFundItemModalStyle } from "../Steps/add-fund-item.styles";
import { useState } from "react";
import FundModel from "../fund.model";
import { useMutation } from "@apollo/client";
import { REMOVE_ITEM_FROM_FUND } from "../fund.queries";

/**
 *
 */
export default function FundItemSummary({ fundItem, fund, refetchFund }: { fundItem: IFundItemOptions, fund: FundModel, refetchFund?: any }) {
  const { item } = fundItem;
  const isWishlist = fund.isWishlist;
  const isGoalCompleted = !isWishlist && fundItem.raised >= fundItem.totalGoal;
  const fundType = fund.isWishlist ? 'wishlist' : 'campaign';

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    if (refetchFund) {
      refetchFund()
        .then(() => setOpenModal(false));
    } else {
      setOpenModal(false);
    }
  };

  const [ removeItemFromFund, { loading, error } ] = useMutation(REMOVE_ITEM_FROM_FUND, { variables: { fundId: fund._id, fundItemId: fundItem._id } });

  function changeGoal() {
    handleOpenModal();
    // TODO: on click, open SetItemQuantity
    // TODO: handle reloading...
  }

  function removeItem() {
    removeItemFromFund()
      .then(() => {
        if (refetchFund) {
          return refetchFund();
        }
      });
  }

  return (
    <Card sx={{ display: "flex", marginBottom: 2 }}>
      <CardMedia
        component="img"
        sx={fundItemStyles}
        src={item.imageUrl}
        alt="Donation item"
      />
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography paragraph>
              { item.name }
            </Typography>

            <FundItemPrice price={item.price}textAlign="left" />
          </Grid>
          <Grid item xs={12} sm={6} textAlign="right">
            {
              isWishlist ? '' :
              (
              <Typography sx={{ mb: 2 }} paragraph color={ isGoalCompleted ? 'secondary' : 'primary' }>
                <strong>
                  Goal: { fundItem.totalGoal }
                </strong><br />
                <strong>
                  Raised: { fundItem.raised }
                </strong><br />
                { isGoalCompleted ? '🎉 This goal is completed.': '' }
              </Typography>
              )
            }

            <ButtonGroup>
              {
                isWishlist ? '' : (<Button onClick={changeGoal} variant="contained"><Edit /></Button>)
              }
              <Button variant="outlined" disabled={fundItem.raised > 0} onClick={removeItem}><DeleteForever /></Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </CardContent>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={addFundItemModalStyle}>
          <SetFundItemContainer fundType={fundType} fund={fund} closeModal={handleCloseModal} item={fundItem.item} fundItem={fundItem} />
        </Box>
      </Modal>
    </Card>
  );
}
