import { Container, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { USER_SUMMARY } from "../Users/user.queries";
import DashboardHeader from "./DashboardHeader";

/**
 *
 * The User Dashboard Page.
 */
export default function DashboardLayout() {
  const { data, refetch } = useQuery(USER_SUMMARY);

  const organizations = data?.userSummary?.organizations ?? [];
  const isAdmin = data?.userSummary?.isAdmin ?? false;

  return (
    <Container>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ pt: 1, margin: { md: '0 auto !important', lg: '0 auto !important' } }} maxWidth="md">
        <Grid item xs={12}>
          <DashboardHeader organizations={organizations} refreshOrgs={refetch} isAdmin={isAdmin} />
        </Grid>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
}
