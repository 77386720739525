import { gql } from "@apollo/client";

export const USER_SUMMARY = gql`
  query UserSummary {
    userSummary {
      isAdmin
      donation {
        _id
        items {
          fund {
            name
          }
          item {
            name
            description
            value
            valueCurrency
            imageUrl
          }
          quantity
        }
        total
      }

      organizations {
        uuid
        name
        logoUrl
      }
    }
  }
`;

export const GET_LOGGED_IN_USER = gql`
  query GetLoggedInUser {
    me {
      givenName
      familyName
      email
      organizations {
        _id
        name
      }
    }
  }
`;

export const START_RESET_PASSWORD = gql`
  mutation StartResetPassword($email: String) {
    startResetPassword(email: $email) {
      success
      errorMessage
    }
  }
`;

export const UPDATE_USER_NAME = gql`
  mutation UpdateUserName($givenName: String, $familyName: String) {
    updateName(givenName: $givenName, familyName: $familyName) {
      familyName
      givenName
    }
  }
`;

export const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail($email: String) {
    updateEmail(email: $email) {
      email
    }
  }
`;