import { Box, Button, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { VENDOR_SOURCES } from "../utils/currencies-by-country";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useMutation } from "@apollo/client";
import { CREATE_VENDOR_ORDER } from "./vendor-order.queries";
import Loading from "../Layout/Loading";
import LoadingError from "../Layout/LoadingError";
import { VENDOR_ORDER_DELIVERY_STATUS, VENDOR_ORDER_STATUS } from "./vendor-order.model";
import Price from "../shared/Price";

export interface ICreateVendorOrderFormComponentProps {
  onVendorOrderCreated?: (result: any) => void;
}

export default function CreateVendorOrderFormComponent({ onVendorOrderCreated }: ICreateVendorOrderFormComponentProps) {
  const [ orderDate, setOrderDate ] = useState<Date|null>(new Date());
  const [vendor, setVendor] = useState(VENDOR_SOURCES[0]);
  const [orderStatus, setOrderStatus] = useState(VENDOR_ORDER_STATUS.PAID);
  const [deliveryStatus, setDeliveryStatus] = useState(VENDOR_ORDER_DELIVERY_STATUS.PENDING);
  const [vendorOrderNumber, setVendorOrderNumber] = useState('');
  const [shippingCost, setShippingCost] = useState('');

  const [ createVendorOrder, { data, loading, error} ] = useMutation(CREATE_VENDOR_ORDER, { variables: { vendorOrder: { orderNumber: vendorOrderNumber, orderDate, vendor, shippingCost: Number(shippingCost), orderStatus, deliveryStatus } }});

  function updateVendor(e: any) {
    const value = e.target.value;
    setVendor(value);
  }

  async function attemptCreateVendorOrder() {
    const result = await createVendorOrder();

    if (onVendorOrderCreated) {
      onVendorOrderCreated(result);
    }
  }

  if (loading) return <Loading />;
  if (error) return <LoadingError />;

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      {
        data?.createVendorOrder && (
          <Grid item xs={12}>
            <Typography>Vendor Order Created! ID: {data.createVendorOrder._id} | Order Number: {data.createVendorOrder.orderNumber}</Typography>
          </Grid>
        )
      }
      <Grid item xs={12} md={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <InputLabel id="vendor">Vendor</InputLabel>
            <Select
              labelId="vendor"
              id="vendor"
              value={vendor}
              label="Vendor"
              onChange={(e) => updateVendor(e)}
              required={true}
            >
              {
                VENDOR_SOURCES.map((region: string) => (
                  <MenuItem key={region} value={region}>{region}</MenuItem>
                ))
              }
            </Select>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <InputLabel id="vendor">Vendor Order Status</InputLabel>
            <Select
              labelId="vendorOrderStatus"
              id="vendorOrderStatus"
              value={orderStatus}
              label="Vendor Order Status"
              onChange={(e) => setOrderStatus(e.target.value as VENDOR_ORDER_STATUS)}
              required={true}
            >
              {
                Object.keys(VENDOR_ORDER_STATUS).map((status: string) => (
                  <MenuItem key={status} value={status}>{status}</MenuItem>
                ))
              }
            </Select>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <InputLabel id="vendor">Vendor Order Status</InputLabel>
            <Select
              labelId="deliveryStatus"
              id="deliveryStatus"
              value={deliveryStatus}
              label="Delivery Status"
              onChange={(e) => setDeliveryStatus(e.target.value as VENDOR_ORDER_DELIVERY_STATUS)}
              required={true}
            >
              {
                Object.keys(VENDOR_ORDER_DELIVERY_STATUS).map((status: string) => (
                  <MenuItem key={status} value={status}>{status}</MenuItem>
                ))
              }
            </Select>
          </FormGroup>
        </FormControl>
      </Grid>
      
      <Grid item xs={12} md={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <TextField
              id="vendorOrderNumberInput"
              value={vendorOrderNumber}
              label="Vendor Order #"
              onChange={(e) => setVendorOrderNumber(e.target.value)}
            />
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <Typography id="orderDate">Order Date</Typography>
            <ReactDatePicker selected={orderDate} onChange={(date) => setOrderDate(date)} />
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <TextField
              id="vendorOrderNumberInput"
              label="Shipping Charges"
              value={shippingCost}
              onChange={(e) => setShippingCost(e.target.value)}
            />
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        Shipping Cost: <Price value={Number(shippingCost)} />
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Button variant="outlined" onClick={attemptCreateVendorOrder}>Create Vendor Order</Button>
        </Box>
      </Grid>
    </Grid>
  )
}