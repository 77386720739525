import { gql } from "@apollo/client";

export const LIST_ORGANIZATIONS = gql`
  query Organizations($page: Int, $keywords: String) {
    organizations (page: $page, keywords: $keywords) {
      page
      hasMore
      organizations {
        _id
        name
        description
        website
        logoUrl
        qrCode
        isVerified
        uuid
        user {
          givenName
          email
        }
      }
    }
  }
`;

export const ADD_USER_TO_ORGANIZATION = gql`
  mutation AddUserToOrganization($organizationId: ID!, $userId: ID!) {
    addUserToOrganization(organizationId: $organizationId, userId: $userId) {
      _id
      name
      description
      website
      logoUrl
      qrCode
      isVerified
      uuid
      user {
        givenName
        email
      }
    }
  }
`;