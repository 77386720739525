import { useMutation } from "@apollo/client";
import { Alert, AlertTitle, Button, FormControl, FormGroup, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { isEmailValid } from "../utils/is-email-valid";
import { UPDATE_USER_EMAIL } from "./user.queries";

export default function EditEmail({ email, onUserUpdated, closeModal } : { email: string; onUserUpdated: any, closeModal: any }) {
  const [newEmail, setNewEmail] = useState(email);
  // TODO: handle duplicate email/email exists error.

  const [mutateUpdateUser, { error }] = useMutation(UPDATE_USER_EMAIL, { variables: { email: newEmail } });

  function updateUser() {
    mutateUpdateUser()
      .then(() => {
        onUserUpdated();
        closeModal();
      });
  }

  function updateNewEmail(e: any) {
    e.preventDefault();
    setNewEmail(e.target.value);
  }

  function isFormValid() {
    return newEmail && isEmailValid(newEmail);
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography>Change Your Email</Typography>
      </Grid>
      <Grid display={error ? 'block' : 'none'} item xs={12} sx={{ mb: 2 }}>
        <Alert severity="error" sx={{ display: error ? 'block' : 'none' }}>
          <AlertTitle>There was an error.</AlertTitle>
          We could not update your email at this time. Please contact support if this issue persists.
        </Alert>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <FormControl fullWidth>
          <FormGroup>
            <TextField variant="standard" label="Email" id="email" name="email" type="email" value={newEmail} onChange={updateNewEmail} required></TextField>
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Button variant="outlined" disabled={!isFormValid()} onClick={() => updateUser()}>Save</Button>
        <Button variant="outlined" onClick={() => closeModal()}>Cancel</Button>
      </Grid>
    </Grid>
  );
};
