import FundModel from "../Funds/fund.model";
import UserModel, { IUserOptions } from "../Users/user.model";
import { DonationItemModel } from "./donation-item.model";

export interface IDonation {
  _id?: string;
  items: DonationItemModel[]
  totals: IDonationTotals;
  createdAt: string;
  fundId?: string;
  fund: FundModel;
  subtotal: number;
  serviceFee: number;
  taxes: number;
  total: number;
  currency: string;
  user: IUserOptions;
  deliveryStatus: string;
  status: string
  thankYouNoteSentAt: string;
}

export interface IDonationTotals {
  
}

export class DonationModel {
  _id?: string;
  items: DonationItemModel[];
  createdAt: string;
  fundId?: string;
  fund?: FundModel;
  subtotal: number;
  serviceFee: number;
  taxes: number;
  total: number;
  currency: string;
  user: UserModel;
  deliveryStatus: string
  thankYouNoteSentAt?: string;

  constructor(options: IDonation) {
    const { _id, items, createdAt, fundId, fund, subtotal, serviceFee, taxes, total, currency, user, deliveryStatus, thankYouNoteSentAt } = options;

    this._id = _id ?? undefined;
    this.items = items.map(item => new DonationItemModel(item));
    this.createdAt = createdAt;
    this.fundId = fundId ?? undefined;
    this.fund = fund ?? undefined;
    this.subtotal = subtotal;
    this.serviceFee = serviceFee;
    this.taxes = taxes;
    this.total = total;
    this.currency = currency;
    this.user = new UserModel(user);
    this.deliveryStatus = deliveryStatus;
    this.thankYouNoteSentAt = thankYouNoteSentAt ?? undefined;
  }
}
