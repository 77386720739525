/**
 *
 * About Page
 */
import { Link as RouterLink } from "react-router-dom";
import { Box, Container, Grid, Typography, Link } from "@mui/material";
import SocialShare from "../Social/SocialShare";

import headshot from '../assets/headshot-founder-amy.png';
import InstagramLink from "../shared/InstagramLink";

export default function AboutPage() {
  const shareURL = window.location.protocol + '//' + window.location.host;
  const shareTitle = 'Donatic: Give things, not money. Donate with us today.';
  const shareEmailSubject = 'Checkout Donatic, a platform for donating (new) items.';
  const shareEmailBody = 'Donatic is a platform where fundraisers can create campaigns to receive items in new condition. Check it out!';

  return (
    <Container sx={{ pt: 4 }}>
      <Grid container columnSpacing={5}>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography component="h2" variant="h5">
              Company
            </Typography>
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <Typography paragraph sx={{ typography: "body1" }}>
              Donatic is a social enterprise that’s transforming the way people give by providing transparency and control over donations. Unlike traditional platforms that focus on cash donations, Donatic enables donors to contribute <strong>specific items</strong> directly to nonprofits, ensuring that every gift meets a defined need and allowing donors to see exactly how their contribution is used.
            </Typography>

            <Typography paragraph sx={{ typography: "body1" }} color="primary">
              <u>
                Our goal is to give you, the donor,{" "}
                <strong>
                  the power to choose exactly where your money goes
                </strong>
                .
              </u>
            </Typography>

            <Typography paragraph sx={{ typography: "body1" }}>
              While we are a for-profit business, Donatic’s core mission is social impact. Our 5% service fee supports the ongoing development and maintenance of the platform, as well as essential operations such as shipping costs. This fee is part of a comprehensive service provided to donors, covering the purchase and delivery of donation items. By managing these logistics, we make it easier for nonprofits to receive the exact resources they need, and we ensure that donors experience a seamless, impactful giving process.
            </Typography>

            <Typography paragraph sx={{ typography: "body1" }}>
              Our service (convenience) fee of 5% means that when you donate $100.00's worth of items, it costs $5.00 for us to purchase and deliver those items
              on your behalf.
            </Typography>

            <Typography paragraph sx={{ typography: "body1" }}>
              At Donatic, we believe that giving should be personal, transparent, and meaningful. By connecting donors with tangible needs, we're building a bridge between communities and the causes that inspire them, one item at a time.
            </Typography>

            <Typography paragraph sx={{ typography: "body1" }} color="primary">
              This also <u>streamlines the process</u> for any fundraisers and
              non-profits, shipping items directly to them when donors donate
              through out platform.{" "}
              <strong>
                Donatic is always free for nonprofits and individuals that are collecting supplies on our platform
              </strong>
              .
            </Typography>

            <Typography paragraph sx={{ typography: "body1" }}>
              Please{" "}
              <Link component={RouterLink} color="secondary" to="/contact">
                contact us
              </Link>{" "}
              at any time with any concerns or feedback you may have, we'd love
              to hear from you.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} textAlign="center">
          <img src={headshot} alt="logo" width="200px" height="200px" max-width="100%" />
          <Typography sx={{ mt: 2 }} paragraph align="left">
            "The goal of Donatic is to provide donors with transparency, while streamlining the process of item collection for fundraisers. We try our best to create a win-win situation while driving a positive impact."
          </Typography>
          <Typography paragraph align="right">
            -- Amy Soetopo, Founder
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography paragraph>
            Enjoying Donatic? Help us spread the word!
          </Typography>
          <SocialShare url={shareURL} title={shareTitle} quote={shareEmailBody} emailSubject={shareEmailSubject} emailBody={shareEmailBody}  />

          <Typography paragraph>You can also follow us to stay up-to-date:</Typography>

          <InstagramLink />
      </Grid>
    </Grid>
    </Container>
  );
}
