/**
 *
 * How it works fundraisers
 */
import { Container, Box, Typography, Button, Grid, Link } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { TRANSLATIONS } from "../lang/translations";
import { homeStyles } from "./home-styles";

export default function HowItWorksFundraisers() {
  const { loginWithRedirect, user } = useAuth0();

  return (
    <Container sx={homeStyles.sectionContainer}>
      <Box component="div" className="HowItWorksWrapper">
        <Typography component="h5" variant="h5">
          <FormattedMessage id="how-it-works-fundraisers.title" defaultMessage={TRANSLATIONS.en['how-it-works-fundraisers.title']} />
        </Typography>
      </Box>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={8}>
          <Typography paragraph variant="body1" sx={{ mb: 5 }}>
            <FormattedMessage id="how-it-works.description" defaultMessage={TRANSLATIONS.en['how-it-works-fundraisers.description']} />
          </Typography>
          {user ? (
            <Button variant="contained" color="primary">
              <Link
                component={RouterLink}
                to="/dashboard"
                sx={{ color: "#fff" }}
              >
                <FormattedMessage id="button.go-to-dashboard" defaultMessage={TRANSLATIONS.en['button.go-to-dashboard']} />
              </Link>
            </Button>
          ) : (
            <Button
              onClick={() => loginWithRedirect({ redirectUri: `${process.env.REACT_APP_BASE_URL}/dashboard` })}
              variant="contained"
              color="primary"
            >
               <FormattedMessage id="button.sign-up" defaultMessage={TRANSLATIONS.en['button.sign-up']} />
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={4} sx={{ marginTop: { xs: 3, sm: 3, md: 0 } }}>
          <Typography paragraph variant="body1">
            <FormattedMessage id="how-it-works-fundraisers.questions" defaultMessage={TRANSLATIONS.en['how-it-works-fundraisers.questions']} />{" "}
            <Link component={RouterLink} to="/contact">
              <FormattedMessage id="button.contact-us" defaultMessage={TRANSLATIONS.en['button.contact-us']} />
            </Link>{" "}
            <FormattedMessage id="how-it-works-fundraisers.post-contact" defaultMessage={TRANSLATIONS.en['how-it-works-fundraisers.post-contact']} />
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
