import { gql } from "@apollo/client";

export const CART_TOTALS_FIELDS = gql`
  fragment CartTotalFields on Cart {
    totals {
      subtotal
      serviceFee
      taxes
      total
    }
  }
`;

export const CURRENCY_QUERY = gql`
  query Currency {
    currency @client
  }
`;

export const CURRENT_CART = gql`
  query CurrentCart {
    currentCart @client
  }
`;

export const FIND_OR_CREATE_CART = gql`
  mutation FindOrCreateCart($cartId: ID) {
    findOrCreateCart(cartId: $cartId) {
      _id
      fundId
      fund {
        ...FundFields
      }
      items {
        item {
          ...ItemFields
        }
        quantity
      }
      ...CartTotalFields
    }
  }
`;

export const RESET_CART = gql`
mutation ResetCart($cartId: ID!, $fundId: ID!) {
  resetCart(cartId: $cartId, fundId: $fundId) {
    _id
    fundId
    fund {
      ...FundFields
    }
    items {
      item {
        ...ItemFields
      }
      quantity
    }
    ...CartTotalFields
  }
}
`;

export const ADD_TO_CART = gql`
  mutation AddToCart($cartId: ID, $itemId: ID, $quantity: Int) {
    addToCart(
      cartId: $cartId
      itemId: $itemId
      quantity: $quantity
    ) {
      _id
      fundId
      fund {
        ...FundFields
      }
      items {
        item {
          ...ItemFields
        }
        quantity
      }
    }
  }
`;

export const SET_CART_ITEM_QUANTITY = gql`
  mutation SetCartItemQuantity($cartId: ID, $itemId: ID, $quantity: Int) {
    setCartItemQuantity(
      cartId: $cartId
      itemId: $itemId
      quantity: $quantity
    ) {
      _id
      fundId
      fund {
        ...FundFields
      }
      items {
        item {
          ...ItemFields
        }
        quantity
      }
    }
  }
`;

export const REMOVE_FROM_CART = gql`
  mutation RemoveFromCart(
    $cartId: ID
    $itemId: ID
    $quantity: Int
  ) {
    removeFromCart(
      cartId: $cartId
      itemId: $itemId
      quantity: $quantity
    ) {
      _id
      fundId
      fund {
        ...FundFields
      }
      items {
        item {
          ...ItemFields
        }
        quantity
      }
    }
  }
`;

export const CART_UPDATE_SUBSCRIPTION = gql`
  subscription OnCartUpdated($cartId: ID!) {
    cartUpdated(cartId: $cartId) {
      type
      cart {
        _id
        fundId
        fund {
          ...FundFields
        }
        items {
          item {
            ...ItemFields
          }
          quantity
        }
        ...CartTotalFields
      }
    }
  }
`;

export const DONATE = gql`
  mutation Donate(
    $cartId: ID!
    $email: String
    $name: String
    $currency: SupportedPaymentCurrency!
  ) {
    donate(
      cartId: $cartId
      email: $email
      name: $name
      currency: $currency
      updateUserInfo: true
    ) {
      clientSecret
    }
  }
`;

export const GET_PAYMENT_INTENT_STATUS = gql`
  query PaymentIntentStatus($paymentIntentId: ID) {
    paymentIntentStatus(paymentIntentId: $paymentIntentId) {
      id
      status
    }
  }
`;

export const SET_CART_CURRENCY = gql`
  mutation SetCartCurrency(
    $cartId: ID!
    $currency: SupportedPaymentCurrency!
  ) {
    setCartCurrency(
      cartId: $cartId
      currency: $currency
    ) {
      _id
      fundId
      fund {
        ...FundFields
      }
      items {
        item {
          ...ItemFields
        }
        quantity
      }
    }
  }
`;
