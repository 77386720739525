import { Container } from "@mui/material";
import { ApolloSandbox } from '@apollo/sandbox/react';
import NotFound from "../pages/not-found";

export default function EmbeddedSandbox() {
  if (process.env.REACT_APP_ENV !== 'development') {
    return <NotFound />
  }

  return (
    <Container sx={{
      maxWidth: '100%',
      pt: 4, minHeight: 100,
      '> div': {
        height: '1000px'
      }
    }}>
      <ApolloSandbox
        initialEndpoint='http://localhost:3000/graphql'
        includeCookies={false}
      />
    </Container>
  );
}