import { Container, Grid, Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";

import { CURRENT_CART } from "./cart.queries";
import CartEmpty from "./CartEmpty";
import LoadingError from "../Layout/LoadingError";
import Loading from "../Layout/Loading";
import CartSummary from "./CartSummary";
import CheckoutItem from "./CheckoutItem";
import CheckoutDetails from "./CheckoutDetails";

if (!process.env.REACT_APP_STRIPE_PK) {
  throw new Error("Stripe PK missing.");
}

export default function CheckoutPage() {
  const { loading, error, data } = useQuery(CURRENT_CART);

  const currentCart = data.currentCart;

  if (loading) return <Loading />;
  if (error) return <LoadingError />;

  if (!currentCart || currentCart?.items?.length < 1) {
    return <CartEmpty />;
  }

  return (
    <Container sx={{ pt: 4 }}>
      <Grid container>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Typography variant="overline" color="primary">
            You are currently donating to <strong>{currentCart.fund?.name}</strong>.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid sx={{ display: { xs: "block", sm: "none" } }} item xs={12} sm={6}>
          <Stack>
            {currentCart.items?.map((item: any, index: number) => {
              return <CheckoutItem key={index} cartItem={item} />;
            })}
          </Stack>
          <CartSummary />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CheckoutDetails />
        </Grid>
        <Grid sx={{ display: { xs: "none", sm: "block" } }} item xs={12} sm={6}>
          <Stack>
            {currentCart.items?.map((item: any, index: number) => {
              return <CheckoutItem key={index} cartItem={item} />;
            })}
          </Stack>
          <CartSummary />
        </Grid>
      </Grid>
    </Container>
  );
}
