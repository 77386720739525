export function getOrderedAndPendingItems({ donation }:{ donation: any }) {
  const { orderedItems, pendingItems } = donation?.items?.reduce((acc: any, donationItem: any) => {
    const { quantity, itemId } = donationItem;

    const vendorOrderDonations = donation.vendorOrderDonations?.filter((vod: any) => vod.orderedItems.find((oi: any) => oi.itemId === itemId));

    const quantityOrdered = vendorOrderDonations.reduce((acc: any, vo: any) => {
      const total = vo.orderedItems.reduce((acc: any, oi: any) => {
        return acc + oi.quantity;
      }, 0);

      return acc + total;
    }, 0);

    const orderedItems = [...acc.orderedItems];
    const pendingItems = [...acc.pendingItems];

    if (quantityOrdered === quantity || quantityOrdered > 0) {
      orderedItems.push(donationItem);
    }

    if (quantityOrdered < quantity || quantityOrdered < 1) {
      pendingItems.push(donationItem);
    }

    return {
      orderedItems,
      pendingItems,
    }
  }, { orderedItems: [], pendingItems: []});

  return { orderedItems, pendingItems };
}