import { format } from "@astopo/price-formatter";
import { IItemPrice } from "../Items/item.model";
import { CURRENCY_QUERY } from "../Cart/cart.queries";
import { useQuery } from "@apollo/client";
import { CURRENCY } from "../Currency/currencies";

interface IPriceProps {
  price?: IItemPrice;
  value?: number;
  overrideCurrency?: CURRENCY;
}

export default function Price({ price, value, overrideCurrency }: IPriceProps) {
  const { data } = useQuery(CURRENCY_QUERY);
  const currency = overrideCurrency ?? data.currency.value;

  if (price !== undefined && price !== null && currency) {
    const priceString = format(price[currency as CURRENCY], { currency });
  
    return <span>{priceString} ({currency})</span>;
  }

  // If we are rendering a straight up value rather than a Price which is a map of values by currency code, use the value.
  if (value || value === 0) {
    const priceString = format(value, { currency });
  
    return <span>{priceString} ({currency})</span>;
  }

  return <span></span>;
}
