import { Box, Grid, Typography } from "@mui/material";

export default function CheckoutConfirmationError() {
  return (
    <Grid item xs={12} md={8}>
      <Box>
        <Typography component="h2" variant="h2">
          Something went wrong...
        </Typography>
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <Typography paragraph sx={{ typography: "body1" }} color="primary">
          We weren't able to process your payment. Please try again later, or
          contact us if this problem persists.
        </Typography>
      </Box>
    </Grid>
  );
}
