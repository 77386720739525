import FundItem from "./fund-item.model";
import User from "../Users/user.model";
import { Organization } from "../Organizations/organization.model";
import { IAddress } from "../Address/address.model";

export interface IFundOptions {
  _id?: string;
  addressId?: string;
  userId?: string;
  name?: string;
  imageUrl?: string;
  description?: string;
  items?: FundItem[];
  user?: User;
  organizationId?: string;
  organization?: Organization;
  isWishlist?: boolean
  isPublished?: boolean
  canDelete?: boolean
  address?: IAddress
  region?: string
}

export default class FundModel {
  _id: string | undefined;
  addressId: string | undefined;
  userId: string | undefined;
  name: string | undefined;
  imageUrl: string | undefined;
  description: string | undefined;
  isWishlist: boolean = false;
  isPublished: boolean = false;
  items: FundItem[] = [];
  user: User | undefined;
  organization: Organization | undefined;
  canDelete: boolean = false;
  region?: string
  address?: IAddress;

  static MAX_ITEM_GOAL = 100;
  static MAX_ITEM_COUNT = 15;

  constructor(options: IFundOptions) {
    const { _id, addressId, userId, name, imageUrl, description, items, user, organization, isWishlist, isPublished, canDelete, address, region } =
      options;

    this._id = _id || undefined;
    this.addressId = addressId || undefined;
    this.userId = userId || undefined;
    this.name = name || '';
    this.imageUrl = imageUrl || undefined;
    this.description = description || '';
    this.items = items || [];
    this.isWishlist = isWishlist || false;
    this.isPublished = isPublished || false;
    this.canDelete = canDelete || false;
    this.user = user || undefined;
    this.organization = organization || undefined;
    this.address = address || undefined;
    this.region = region || undefined;
  }

  toFundInput() {
    return {
      _id: this._id,
      name: this.name,
      description: this.description,
      isPublished: this.isPublished,
      addressId: this.addressId
    }
  }
}
