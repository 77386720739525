import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { StyledTableCell, StyledTableRow, IColumn } from "../shared";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Paper, Typography } from '@mui/material';
import Price from '../../shared/Price';

const columns: IColumn[] = [
  {
    header: 'Image',
    getValue: (donationItem: any) => {
      return donationItem.item.imageUrl ? (
        <img src={donationItem.item.imageUrl} alt={donationItem.item.name} style={{ width: '50px', height: '50px' }} />
      ) : (
        <img src="https://via.placeholder.com/50" alt={donationItem.item.name} style={{ width: '50px', height: '50px' }} />
      )
    }
  },
  {
    header: 'Name',
    getValue: (donationItem: any) => donationItem.item?.name ?? ''
  },
  {
    header: 'Quantity',
    getValue: ({ orderedItem }: any) => orderedItem?.quantity ?? ''
  },
  {
    header: 'Donatic Price',
    getValue: (donationItem: any) => {
      return (<Price price={donationItem.item.price} />)
    }
  },
  {
    header: 'Vendor',
    getValue: (vendorOrder: any) => vendorOrder.vendor
  },
  {
    header: 'Vendor Price',
    getValue: ({ orderedItem, vendorOrder }: any) => {
      return (<Price value={orderedItem.vendorValue} overrideCurrency={vendorOrder.currency} />)
    }
  },
  {
    header: 'Vendor Order #',
    getValue: (vendorOrder: any) => vendorOrder.orderNumber
  },
];

export default function AdminDonationItemTableOrdered({ donationItems, vendorOrderDonations }: { donationItems: any[], vendorOrderDonations: any[] }) {
  if (donationItems?.length === 0) {
    return (
      <Box>
        <Typography paragraph color="secondary">Nothing ordered yet...</Typography>
    </Box>
    );
  }

  return (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          {
            columns.map((column: any, index: number) => {
              return (<StyledTableCell key={index}>{column.header}</StyledTableCell>)
            })
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {donationItems?.map((donationItem: any, index: number) => {
          const vod = vendorOrderDonations?.find(vendorOrderDonation => vendorOrderDonation.orderedItems?.find((oi: any) => oi.donationItemId === donationItem._id));

          const vendorOrder = vod?.vendorOrder;

          const orderedItem = vod?.orderedItems?.find((oi: any) => oi.donationItemId === donationItem._id);
        return (
          <StyledTableRow key={index}>
            {
              columns.map((column: any, i: number) => {
                if (column.header === 'Vendor Order #' || column.header === 'Vendor') {
                  return (<StyledTableCell key={i}>{column.getValue(vendorOrder)}</StyledTableCell>)
                }

                if (column.header === 'Vendor Price' || column.header === 'Quantity') {
                  return (<StyledTableCell key={i}>{column.getValue({ orderedItem, vendorOrder })}</StyledTableCell>)
                }


                return (<StyledTableCell key={i}>{column.getValue(donationItem)}</StyledTableCell>)
              })
            }
          </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
  );
}