import { useMutation } from "@apollo/client";
import { Typography, Button, Grid, FormGroup, TextField, Alert } from "@mui/material";
import { useState } from "react";
import Loading from "../Layout/Loading";
import { SET_FUND_USER_AND_ORGANIZATION } from "./admin.fund.queries";

/**
 *
 */
export default function AdminCampaignModal({ fund, closeModal }: { fund: any, closeModal: any }) {
  const [userId, setUserId] = useState('');
  const [organizationId, setOrganizationId] = useState('');

  const [addUserToOrg, { loading, error }] = useMutation(SET_FUND_USER_AND_ORGANIZATION);

  function attemptSaveFund() {
    addUserToOrg({ variables: { fundId: fund._id, userId, organizationId: organizationId.length > 0 ? organizationId: undefined } })
      .then(() => closeModal());
  }

  if (loading) return <Loading />

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5" paragraph>Edit Campaign User and Organization</Typography>
        <Typography variant="body1" paragraph>This will set both the Fund User and Organization. If Organization is empty, it will remove any existing Organizations on the fund.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" fontWeight="bold">You are modifying the user for the fund: {fund.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <TextField onChange={(e) => setUserId(e.target.value)} label="User ID" value={userId} />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <TextField onChange={(e) => setOrganizationId(e.target.value)} label="Organization ID" value={organizationId} />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <Button type="button" variant="contained" disabled={!userId} onClick={attemptSaveFund}>Save</Button>
      </Grid>
      <Grid item xs={12} sx={{ display: error ? 'block': 'none' }}>
        <Alert severity="error">Something went wrong.</Alert>
      </Grid>
    </Grid>
  );
}
