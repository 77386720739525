import { Grid, FormControl, FormGroup, TextField, Button, Typography, Select, MenuItem, InputLabel } from "@mui/material";
import { useState } from "react";
import { CURRENCIES, REGIONS, VENDOR_SOURCES } from "../utils/currencies-by-country";
import { useMutation } from "@apollo/client";
import { IMPORT_ITEM } from "./item.admin.queries";

/**
 *
 */
export default function ItemImportForm({ item }: { item?: any }) {
  const [ itemImportData, setItemImportData ] = useState({
    name: item?.name ?? '',
    description: item?.description ?? '',
    imageUrl: item?.imageUrl ?? '',
    vendorUrl: item?.vendorUrl ?? '',
    source: item?.source ?? '',
    sourceId: item?.sourceId ?? '',
    value: item?.value ?? '',
    valueCurrency: item?.valueCurrency ?? '',
    region: item?.region ?? '',
  });

  const [ importItem, { loading, data, error } ] = useMutation(IMPORT_ITEM, { variables: { item: {...itemImportData, value: Number(itemImportData.value)} } });

  // TODO: Vendor and ValueCurrency should be from SupportedItemCurrencies and SupportedVendors. They should be dropdowns, not text fields.

  function updateItemImportData(key: string, e: any) {
    const value = e.target.value;
    setItemImportData({ ...itemImportData, [key]: value });
  }

  function attemptImportItem() {
    importItem()
      .then(r => console.log('r', r));
  }

  if (data?.importItem) {
    return (
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography>Item Imported!</Typography>
          <Typography>Item ID: { data.importItem._id }</Typography>
          <Typography>Price: { JSON.stringify(data.importItem.price) }</Typography>
          <Typography>Value: { data.importItem.value }</Typography>
          <Typography>Currency: { data.importItem.valueCurrency }</Typography>
          <Typography>Region: { data.importItem.region }</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <TextField
              variant="outlined"
              id="name"
              label="Name"
              name="name"
              value={itemImportData.name}
              required={true}
              onChange={(e) => updateItemImportData('name', e)}
            ></TextField>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <TextField
              variant="outlined"
              id="description"
              label="Description"
              name="description"
              value={itemImportData.description}
              required={true}
              onChange={(e) => updateItemImportData('description', e)}
            ></TextField>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <TextField
              variant="outlined"
              id="imageUrl"
              label="Image URL"
              name="imageUrl"
              value={itemImportData.imageUrl}
              required={true}
              onChange={(e) => updateItemImportData('imageUrl', e)}
            ></TextField>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <TextField
              variant="outlined"
              id="vendorUrl"
              label="Vendor URL"
              name="vendorUrl"
              value={itemImportData.vendorUrl}
              required={true}
              onChange={(e) => updateItemImportData('vendorUrl', e)}
            ></TextField>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <InputLabel id="source">Source</InputLabel>
            <Select
              labelId="source"
              id="source"
              value={itemImportData.source}
              label="Region"
              onChange={(e) => updateItemImportData('source', e)}
              required={true}
            >
              {
                VENDOR_SOURCES.map((region) => (
                  <MenuItem value={region}>{region}</MenuItem>
                ))
              }
            </Select>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <TextField
              variant="outlined"
              id="sourceId"
              label="Source ID"
              name="sourceId"
              value={itemImportData.sourceId}
              required={true}
              onChange={(e) => updateItemImportData('sourceId', e)}
            ></TextField>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <TextField
              variant="outlined"
              id="value"
              label="Value"
              name="value"
              value={itemImportData.value}
              required={true}
              onChange={(e) => updateItemImportData('value', e)}
            ></TextField>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <InputLabel id="valueCurrency">Currency</InputLabel>
            <Select
              labelId="valueCurrency"
              id="valueCurrency"
              value={itemImportData.valueCurrency}
              label="Region"
              onChange={(e) => updateItemImportData('valueCurrency', e)}
              required={true}
            >
              {
                CURRENCIES.map((region) => (
                  <MenuItem value={region}>{region}</MenuItem>
                ))
              }
            </Select>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth={true}>
          <FormGroup>
            <InputLabel id="region">Region</InputLabel>
            <Select
              labelId="region"
              id="region"
              value={itemImportData.region}
              label="Region"
              onChange={(e) => updateItemImportData('region', e)}
              required={true}
            >
              {
                REGIONS.map((region) => (
                  <MenuItem value={region}>{region}</MenuItem>
                ))
              }
            </Select>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>Image Preview:</Typography>
        {
          itemImportData.imageUrl && (
            <img src={itemImportData.imageUrl} alt={itemImportData.name} style={{ maxWidth: '200px' }} />
          )
        }
      </Grid>

      <Grid item xs={12}>
        <Button fullWidth variant="contained" color="primary" onClick={() => attemptImportItem()}>Import Item</Button>

        {
          loading && <Typography>Loading...</Typography>
        }

        {
          error && <Typography>Error: { error.message }</Typography>
        }
      </Grid>

    </Grid>
  );
}
