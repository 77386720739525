import { Typography, Grid } from "@mui/material";
import SearchItemComponent from "../Funds/Steps/SearchItemComponent";
import ItemImportFromUrlForm from "../Items/ItemImportFromUrlForm";

/**
 *
 */
export default function AdminItemsComponent() {
  // TODO: Query for Items
  // TODO: filtering and pagination of Items.

  return (
    <Grid container columnSpacing={2} rowSpacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={6}>
        <Typography component="h5" variant="h5" paragraph>Import Item</Typography>

        <ItemImportFromUrlForm />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography component="h5" variant="h5" paragraph>Search for existing item</Typography>

        <SearchItemComponent item={null} setItem={() => {}} />
      </Grid>
    </Grid>
  );
}
