import { useQuery } from "@apollo/client";
import { Container, Grid, Stack, Link, Typography, List, ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import { CalendarMonth, CreditCard, LocalShipping } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import { GET_DONATION } from "./donation.queries";

import LoadingError from "../Layout/LoadingError";
import Loading from "../Layout/Loading";
import DonationItem from "./DonationItem";
import { DonationItemModel } from "./donation-item.model";
import { capitalizeString } from "../shared/capitalizeString";
import PaymentSummaryList from "../shared/PaymentSummaryList";
import FormatDate from "../shared/FormatDate";
import { displayNewLineStyles } from "../shared/displayNewLine.styles";

/**
 * View a single donation (as Donor).
 */
export default function DonationPage() {
  const params = useParams();
  const { donationId } = params;

  // TODO: render donor info if the viewing from org page?

  const { loading, error, data } = useQuery(GET_DONATION, { variables: { donationId } });

  if (loading) return <Loading />;
  if (error || !donationId) return <LoadingError />;

  const donation = data?.donation;

  const link = donation?.fund?.isWishlist ? `/o/${donation.fund?.organization?.uuid}` : `/donate/${donation.fund?._id}`;

  return (
    <Container>
      <Grid container sx={{ mb: 2 }} columnSpacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="overline"
            color="text.secondary"
            component="div"
          >
            Your donation to: &nbsp;
            <Link
                component={RouterLink}
                to={link}
                color="secondary"
              >
                  {donation.fund.isWishlist ? donation.fund.organization?.name : donation.fund.name}
              </Link>
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <List>
            <ListItem>
              <ListItemIcon><CalendarMonth /></ListItemIcon>
              <ListItemText>Donated On: <FormatDate date={donation.createdAt} /></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><LocalShipping /></ListItemIcon>
              <ListItemText>Delivery Status: {capitalizeString(donation.deliveryStatus)}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><CreditCard /></ListItemIcon>
              <ListItemText>Payment Status: {capitalizeString(donation.status)}</ListItemText>
            </ListItem>
          </List>

          <Divider />

          <Typography sx={{ mt: 2 }} variant="body1" paragraph>Payment Summary</Typography>

          <PaymentSummaryList
            currency={donation.currency}
            subtotal={donation.subtotal}
            serviceFee={donation.serviceFee}
            taxes={donation.taxes}
            total={donation.total}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack>
            {donation?.items?.map((item: DonationItemModel, index: number) => {
              return <DonationItem key={index} donationItem={item} />;
            })}
          </Stack>
        </Grid>
        {
          donation.thankYouNote ?
          (
            <Grid item xs={12} sm={6}>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Typography variant="body1" paragraph color="secondary">You've received a thank you note from the organizer:</Typography>

              <Typography sx={displayNewLineStyles} variant="body2" paragraph>{donation.thankYouNote}</Typography>
            </Grid>
          ) : ''
        }
        
      </Grid>
    </Container>
  );
}
