import { gql } from "@apollo/client";

export const LIST_USERS = gql`
  query Users($page: Int, $keywords: String) {
    users (page: $page, keywords: $keywords) {
      page
      hasMore
      users {
        _id
        givenName
        familyName
        createdAt
        email
        organizations {
          _id
          name
        }
      }
    }
  }
`;