/**
 *
 * A User's account
 */
import Profile from "../Users/Profile";
import { Typography, Grid } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_LOGGED_IN_USER } from "../Users/user.queries";
import UserModel from "../Users/user.model";
import Loading from "../Layout/Loading";
import LoadingError from "../Layout/LoadingError";

export default function AccountPage() {
  const { data, loading, error, refetch } = useQuery(GET_LOGGED_IN_USER);

  if (loading) return <Loading />
  if (error) return <LoadingError />

  const user = new UserModel(data.me);

  function onUserUpdated() {
    return refetch();
  }

  return (
    <Grid container>
      <Grid item sx={{ mt: 2 }}>
        <Typography component="h5" variant="h5">
          Account Settings
        </Typography>

        <Profile user={user} onUserUpdated={onUserUpdated} />

      </Grid>
    </Grid>
  );
}
