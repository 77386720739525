import { gql } from "@apollo/client";


export const GET_SALES_PAGE = gql`
  query GetSalesPage($identifier: String) {
    salesPage(identifier: $identifier) {
      identifier
      organizations {
        name
        uuid
        logoUrl

        latestCampaign {
          _id
          name
          description
          imageUrl
        }
      }
    }
  }
`;