import { IItemPrice } from "../Items/item.model";
import { useQuery } from "@apollo/client";
import { CURRENCY_QUERY } from "../Cart/cart.queries";
import { CURRENCY } from "../Currency/currencies";
import Price from "../shared/Price";

export default function CartItemQuantity({
  quantity,
  price
}: { quantity: number, price: IItemPrice }) {
  const { data } = useQuery(CURRENCY_QUERY);
  const currency = data.currency.value;

  const value = quantity * price[currency as CURRENCY];

  return (<Price value={value} />);
}
