import { useLocation, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { user, isLoading } = useAuth0();

  let location = useLocation();

  if (!user && !isLoading) {
    // Go to Auth0 login page 
    // return loginWithRedirect();

    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
