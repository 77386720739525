import { Box, Grid, Modal, Typography } from "@mui/material";
import { useState } from "react";

import Loading from "../../Layout/Loading";
import LoadingError from "../../Layout/LoadingError";
import FundDonationSummary from "../../Donations/FundDonationSummary";
import { IDonation } from "../../Donations/donation.model";
import DonationDetailModal from "../../Donations/DonationDetailModal";
import { GET_DONATIONS_BY_FUND } from "../../Donations/donation.queries";
import { useQuery } from "@apollo/client";
import { addFundItemModalStyle } from "../Steps/add-fund-item.styles";

export default function CampaignDetailDonationsComponent({ campaignId }: { campaignId: string|undefined }) {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [donation, setDonation] = useState<any>(null);
  function viewDonation(d: IDonation) {
    setDonation(d);
    handleOpenModal();
  }

  const { data: donationData, loading: donationsLoading, error: donationsError, refetch: refetchDonations } = useQuery(GET_DONATIONS_BY_FUND, { variables: { fundId: campaignId } });

  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      {
        donationsLoading ? (<Loading />) : (
          <Grid item xs={12}>
            {
              donationData?.donationsByFund?.length > 0 ?
              (
                donationData?.donationsByFund.map((donation: any, index: number) => (<FundDonationSummary key={index} donation={donation} viewDonation={viewDonation} />))
              ) :
              (<Typography variant="body1" component="p">No donations yet.</Typography>)
            }
          </Grid>
        )
      }
      {
        donationsError ? (<LoadingError />) : ''
      }

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={addFundItemModalStyle}>
          <DonationDetailModal donationId={donation?._id} closeModal={handleCloseModal} />
        </Box>
      </Modal>
    </Grid>
  )
}