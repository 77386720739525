import { Box, Card, CardContent, Typography } from "@mui/material";
import { IAddress } from "./address.model";

export default function DisplayAddress({ address }: { address: IAddress }) {

  return (
    <Box sx={{ width: '100%' }}>
      <Card sx={{ width: '100%' }}>
        <CardContent>
          <Typography variant="body1">{address.addressee}</Typography>
          <Typography variant="body1">{address.line1}</Typography>
          <Typography variant="body1">{address.line2}</Typography>
          <Typography variant="body1">{address.city}</Typography>
          <Typography variant="body1">{address.country}</Typography>
          <Typography variant="body1">{address.zipcode}</Typography>
          <Typography variant="body1">{address.province}</Typography>
          <Typography variant="body1">{address.specialInstructions}</Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
