import { Box, Button, FormControl, FormGroup, Grid, TextField, Typography, Select, MenuItem, InputLabel } from "@mui/material";
import { useQuery } from "@apollo/client";
import { SEARCH_ITEM } from "../../Items/item.queries";
import { useEffect, useState } from "react";
import { IItemOptions } from "../../Items/item.model";
import ItemSearchSummary from "../../Items/ItemSearchSummary";
import Loading from "../../Layout/Loading";
import LoadingError from "../../Layout/LoadingError";
import PaginationButtons from "../components/PaginationButtons";
import { REGIONS } from "../../utils/currencies-by-country";
/**
 *
 */
export default function SearchItemComponent({ item, setItem, region }: { item?: IItemOptions|null, setItem: any , region?: string}) {
  const [ search, setSearch ] = useState('');
  const [ apiSearch, setApiSearch ] = useState('');
  const [ page, setPage ] = useState(0);
  const [searchRegion, setSearchRegion] = useState(region ?? 'mx');

  const { data, refetch, fetchMore, loading, error } = useQuery(SEARCH_ITEM, { variables: { keywords: apiSearch, region: searchRegion, page } });

  const results = data?.searchItem?.items ?? [];

  function loadMore() {
    const nextPage = page + 1;
    fetchMore({ variables: { page: nextPage } });
    setPage(page + 1);
  }

  function setSearchValue(e: any) {
    e.preventDefault();
    const value = e.target.value;

    setSearch(value);
  }

  function runSearch(e: React.SyntheticEvent | Event) {
    e.preventDefault();
    setApiSearch(search);
    setPage(0);
  }

  function selectItem(item: any) {
    setItem(item);
  }

  function onSubmit(e: any) {
    e.preventDefault();
  }

  useEffect(() => {
    refetch();
  }, [apiSearch]);

  if (loading) return <Loading />
  if (error) return <LoadingError />

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Box sx={{
            '& > :not(style)': { m: 1 },
          }}>
            <FormControl onSubmit={onSubmit} fullWidth={true}>
              <TextField
                variant="outlined"
                id="search"
                label="Search for a product..."
                name="search"
                value={search}
                onChange={setSearchValue}
              ></TextField>
              <Button type="button" disabled={search.length < 1} variant="contained" onClick={runSearch}>Search</Button>
            </FormControl>
          </Box>
          {/* <Box sx={{
            '& > :not(style)': { m: 1 },
          }}>
            <FormControl fullWidth={true}>
              <FormGroup>
                <InputLabel id="region">Region</InputLabel>
                <Select
                  labelId="region"
                  id="region"
                  value={searchRegion}
                  label="Region"
                  onChange={(e) => setSearchRegion(e.target.value)}
                  required={true}
                >
                  {
                    REGIONS.map((region) => (
                      <MenuItem value={region}>{region}</MenuItem>
                    ))
                  }
                </Select>
              </FormGroup>
            </FormControl>
          </Box> */}
        </Grid>

      <Grid item sx={{ width: '100%', minHeight: '350px', height: '350px', overflow: 'scroll' }}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          {
            results.map((searchItem: IItemOptions, index: number) => (
              <Grid item key={index}>
                <ItemSearchSummary item={searchItem} onSelectItem={selectItem} itemId={item?._id} />
              </Grid>
            ))
          }
          {
            search.length < 2 && results.length < 1 ?
            (<Grid item><Typography paragraph>Type something above to start searching for items.</Typography></Grid>) : ''
          }
          {
            search.length > 1 && results.length < 1 ?
            (<Grid item><Typography paragraph>We couldn't find anything that matches that search, please try another keyword.</Typography></Grid>) : ''
          }
        </Grid>
        <PaginationButtons loadMore={loadMore} hasMore={data?.searchItem?.hasMore} sx={{ mt: 2 }} />
      </Grid>
    </Grid>
  );
}
