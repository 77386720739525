/**
 *
 * Home Page
 */
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography, Button, Divider } from "@mui/material";
import "./home.css";
import HowItWorks from "../Home/HowItWorks";
import HowItWorksFundraisers from "../Home/HowItWorksFundraisers";
import { FormattedMessage } from "react-intl";

export default function Home() {
  return (
    <main>
      <div className="HomeContainer">
        <Box component="div" className="HomeCTA">
          <Box component="div" className="HomeCTAContent">
            <Typography
              component="h5"
              variant="h5"
              align="center"
              color="white"
              sx={{ pb: 2, mb: 3.5 }}
            >
              <FormattedMessage id="home.motto" defaultMessage={"Give things, not money."} />
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              component={RouterLink} to="/donate" 
              sx={{
                my: 2,
                display: "inline",
                mb: 2,
                pt: 2,
                pb: 2,
                borderRadius: 0,
                color: "#fff",
              }}
            >
              <FormattedMessage id="home.cta" defaultMessage={"Donate Now"} />
            </Button>
          </Box>
        </Box>
      </div>

      <HowItWorks />
      <Divider />
      <HowItWorksFundraisers />
    </main>
  );
}
