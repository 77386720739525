import { Alert, AlertTitle } from "@mui/material";

export default function ErrorMessage({ error, message } : { error: boolean; message: string; }) {
  return (
    <Alert severity="error" sx={{ display: error ? 'block' : 'none' }}>
      <AlertTitle>There was an error.</AlertTitle>
      {message} Please contact support if this issue persists.
    </Alert>
  );
};
