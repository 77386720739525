import { gql } from "@apollo/client";

export const ITEM_FIELDS = gql`
  fragment ItemFields on Item {
    _id
    name
    description
    imageUrl
    value
    valueCurrency
    vendorUrl
    region
    price {
      CAD
      USD
    }
  }
`;

export const SEARCH_ITEM = gql`
  ${ITEM_FIELDS}
  query SearchItem($keywords: String, $region: String, $page: Int) {
    searchItem(keywords: $keywords, region: $region, page: $page) {
      page
      hasMore
      items {
        ...ItemFields
      }
    }
  }
`;