/**
 *
 */
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Link,
} from "@mui/material";

// Assets
import defaultImage from "../shoppingbag.webp";
import { DonationItemModel } from "./donation-item.model";
import Price from "../shared/Price";
import { donationItemStyles } from "../Items/item.styles";
import CartItemQuantity from "../Cart/CartItemQuantity";

interface IDonationItemProps {
  donationItem: DonationItemModel;
  vendorOrders: any[];
}

export default function AdminDonationItem({ donationItem }: IDonationItemProps) {

  return (
    <Card sx={{ display: { xs: 'block', md: "flex"}, marginBottom: 2 }}>
      {donationItem.item.imageUrl ? (
        <CardMedia
          sx={donationItemStyles}
          image={donationItem.item.imageUrl}
        />
      ) : (
        <CardMedia
          sx={donationItemStyles}
          image={defaultImage}
        ></CardMedia>
      )}
      <Box sx={{ display: "block", flexDirection: "column", width: "100%" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography component="div" variant="body1">
                {donationItem.item.name}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item sx={{ pt: { xs: 2, sm: 0 } }} xs={6}>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Price:{" "}
                    <Price
                      price={donationItem.item.price}
                    />
                  </Typography>
                </Grid>

                <Grid item sx={{ pt: { xs: 2, sm: 0 } }} xs={6}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      textAlign="center"
                    >
                    <strong>
                      Quantity: {donationItem.quantity}
                    </strong>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sx={{ pt: { xs: 2, sm: 0 } }}
                  xs={12}
                  textAlign="right"
                >
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    component="div"
                    textAlign="right"
                  >
                    <strong>
                      Total:{" "}
                      <CartItemQuantity quantity={donationItem.quantity} price={donationItem.item.price} />
                    </strong>
                  </Typography>
                </Grid>
                
                <Grid
                  item
                  sx={{ pt: { xs: 2, sm: 0 } }}
                  xs={12}
                  textAlign="left"
                >
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                  ><Link href={donationItem.item.vendorUrl} target="_blank" color="secondary">Vendor Link</Link></Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                  >ID: {donationItem.item._id}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </Card>
  );
}
