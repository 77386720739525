import { Box, Grid, Stack, Typography } from "@mui/material"
import VendorOrderDonationOrderedItemsListComponent from "./VendorOrderDonationOrderedItemsListComponent"

export default function VendorOrderDonationListComponent({ vendorOrderDonations }: { vendorOrderDonations: any[] }) {
  return (
    <Grid item xs={12}>
        <VendorOrderDonationOrderedItemsListComponent vendorOrderDonations={vendorOrderDonations} />

      {
        vendorOrderDonations?.length < 1 && (
          <Grid item xs={12}>
            <Typography component="p" paragraph>No vendor orders found.</Typography>
          </Grid>
        )
      }
    </Grid>
  )
}