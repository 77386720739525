import { Button, Box, Grid, Modal, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import AddressList from "./AddressList";
import { GET_ADDRESSES_BY_ORG, GET_ADDRESSES_BY_USER } from "./address.queries";
import Loading from "../Layout/Loading";
import LoadingError from "../Layout/LoadingError";
import SaveAddress from "./SaveAddress";
import { useState } from "react";
import { saveAddressModalStyle } from "./address.styles";

export default function AddressesPage() {
  const params = useParams();
  const { organizationId } = params;

  const query = organizationId ? GET_ADDRESSES_BY_ORG : GET_ADDRESSES_BY_USER;
  const variables: Record<string, any> = organizationId ? { orgId: organizationId } : {};

  const { loading, error, data, refetch } = useQuery(query, {
    variables,
  });

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  if (loading) return <Loading />
  if (error) return <LoadingError />

  const addresses = data.addressesByOrg ?? data.addressesByUser;

  async function refreshAddresses() {
    refetch();
  }

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12} sm={6} md={8}>
        <Typography component="h5" variant="h5" sx={{ mb: 2 }}>
          { organizationId ? 'Your Organization' : 'Your' } Addresses
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}  textAlign={{ xs: 'left', sm: 'right' }}>
        <Button onClick={() => handleOpenModal()} variant="outlined" color="secondary">Add a new address</Button>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={saveAddressModalStyle}>
            <SaveAddress organizationId={organizationId} closeModal={handleCloseModal} refreshAddresses={refreshAddresses} />
          </Box>
        </Modal>
      </Grid>

      <Grid item xs={12}>
        <AddressList addresses={addresses} refreshAddresses={refreshAddresses} />
      </Grid>
    </Grid>
  );
}
