import { format } from 'date-fns';

interface IDateProps {
  date: string;
}

export default function FormatDate({ date }: IDateProps) {
  const dateObject = new Date(date);

  const formatted = format(dateObject, 'MMMM d, yyyy');
  return <span>{formatted}</span>;
}
