export enum CURRENCY {
  CAD = 'CAD',
  USD = 'USD'
}

export enum CountryCode {
  CA = 'CA',
  MX = 'MX',
  US = 'US',
}

// TODO: better validation.
// countryCode, i.e. region ('CA', 'MX', 'US')
export const getCountryFlagEmoji = (countryCode: CountryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0))
  return String.fromCodePoint(...codePoints)
}

export interface ICurrencySetting {
  flag: string;
  value: CURRENCY
}

export const DEFAULT_CURRENCY = CURRENCY.USD;

export const DEFAULT_CURRENCY_SETTING: ICurrencySetting = {
    flag: getCountryFlagEmoji(CountryCode.US),
    value: CURRENCY.USD
}

// Supported currencies that the user can pay in.
export const ACCEPTED_CURRENCIES: ICurrencySetting[] = [
  {
    flag: getCountryFlagEmoji(CountryCode.CA),
    value: CURRENCY.CAD
  },
  {
    flag: getCountryFlagEmoji(CountryCode.US),
    value: CURRENCY.USD
  },
]
