import { Typography, Box, Button, FormGroup, TextField, FormControl } from "@mui/material";
import { useState } from "react";

/**
 *
 */
export default function ThankYouNoteForm({ attemptSendDonorThankYou, loading, error }: { loading: boolean, error: any, attemptSendDonorThankYou: (message: string) => void }) {
  const DEFAULT_MESSAGE = `I wanted to take a moment to express my heartfelt gratitude for your generous donation to our cause. Your support means the world to us, and it is a true testament to your kindness and belief in the mission we are working towards.

Your contribution will make a significant impact on our efforts and help us move one step closer to our goals. We are genuinely touched by your generosity, and your willingness to make a difference in the lives of those we aim to serve.`;

  const [ message, setMessage ] = useState<string>(DEFAULT_MESSAGE);

  return (
    <Box>
      <Typography variant="body1" paragraph>You haven't sent a thank you note yet.</Typography>
      <Box sx={{ mb: 2, minHeight: '200px', overflow: 'scroll' }}>
        <FormControl fullWidth={true}>
          <FormGroup sx={{ mt: 2 }}>
            <TextField
              multiline={true}
              variant="outlined"
              id="description"
              label="Message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required={true}
            ></TextField>
          </FormGroup>
        </FormControl>
      </Box>
      <Button variant="contained" color="secondary" disabled={message.length < 2 || loading} onClick={() => attemptSendDonorThankYou(message)}>{ loading ? 'Sending...': 'Send Thank You Note' }</Button>
      {
        error && (
          <Typography color="warning">There was an error sending the note, please try again.</Typography>
        )
      }
    </Box>
  );
}
