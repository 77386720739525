import { Box, Button, Grid } from "@mui/material";
import VendorOrderDonationItemTableForm from "./VendorOrderDonationItemTableForm";
import { useMutation } from "@apollo/client";
import { CREATE_VENDOR_ORDER_DONATION } from "../vendor-order-donation.queries";
import Loading from "../../Layout/Loading";
import LoadingError from "../../Layout/LoadingError";

export default function CreateVendorOrderDonationFormComponent({ donationItems, vendorOrderId, donationId }: { donationItems: any[], vendorOrderId: string, donationId: string }) {
  const [ createVendorOrderDonation, { data, loading, error }] = useMutation(CREATE_VENDOR_ORDER_DONATION);

  let vendorOrderItems: any[] = [];

  function onSelectedItems(selectedItems: any[]) {
    vendorOrderItems = selectedItems;
  }

  async function attemptCreateVendorOrder() {
    await createVendorOrderDonation({ variables: { vendorOrderId, donationId, orderedItems: vendorOrderItems } });
    // TODO - accept onVendorOrderDonationCreated callback.
  }

  if (loading) return <Loading />

  // TODO: if created, show _id or something. Allow for form reset.

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      {
        error && (<LoadingError />)
      }
      <Grid item xs={12}>
        <VendorOrderDonationItemTableForm donationItems={donationItems} onSelectedItems={onSelectedItems} />
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Button variant="outlined" onClick={attemptCreateVendorOrder}>Add To Vendor Order</Button>
        </Box>
      </Grid>
    </Grid>
  )
}