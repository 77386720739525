import { useMutation, useQuery } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DELETE_FUND, GET_ORG_FUNDS, GET_USER_FUNDS, UNPUBLISH_FUND } from "./fund.queries";
import Loading from "../Layout/Loading";
import LoadingError from "../Layout/LoadingError";
import CampaignDashboardSummary from "./CampaignDashboardSummary";
import FundModel from "./fund.model";
import { buildDashPath } from "../Dashboard/build-dash-path";
/**
 *
 * The Campaign CRUD.
 */
export default function CampaignManagementPage() {
  const navigate = useNavigate();
  const params = useParams();
  const { organizationId } = params;

  const query = organizationId ? GET_ORG_FUNDS : GET_USER_FUNDS;
  const variables = organizationId ? { orgId: organizationId }: {};

  const { data, loading, error, refetch } = useQuery(query, { variables });

  const [ deleteFund ] = useMutation(DELETE_FUND);
  const [ unpublishFund ] = useMutation(UNPUBLISH_FUND);

  const campaigns: any[] = data?.fundsByUser ?? data?.fundsByOrg ?? [];

  function goToCreatePage() {
    const path = buildDashPath({ params, nextPath: 'campaigns/create' });
    navigate(path);
  }

  // Try to delete the Fund if we can, otherwise unpublish it.
  function attemptDeleteFund(fund: FundModel) {
    if (fund.canDelete) {
      deleteFund({ variables: { fundId: fund._id } })
        .then(() => refetch());
    } else {
      unpublishFund({ variables: { fundId: fund._id } })
        .then(() => refetch());
    }
  }

  if (loading) return <Loading />
  if (error) return <LoadingError />

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={8}>
        <Typography component="h5" variant="h5">
          Campaigns
        </Typography>
      </Grid>

      {/* TODO: Show if admin... <Grid item xs={12} md={4} textAlign="right">
        <Button variant="contained" color="secondary" onClick={goToCreatePage}>Create a campaign</Button>
      </Grid> */}

      <Grid item xs={12}>
        {
          campaigns.length < 1 ?
          ( <Typography>
            You don't have any campaigns, yet. Please contact support to get started.
          </Typography>) :
          (<Grid container rowSpacing={2} columnSpacing={2}>
          {
            campaigns.map((campaign: any, index: number) => (
              <Grid item key={index} xs={12} sm={12} md={4}>
                <CampaignDashboardSummary fund={campaign} />
              </Grid>
            ))
          }
          </Grid>)
        }
      </Grid>
    </Grid>
  );
}
