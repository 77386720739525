import { useMutation } from "@apollo/client";
import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useEffect, useState } from "react";
import { IAddress, newAddress, Address } from "./address.model";
import { SAVE_ADDRESS } from "./address.queries";
import { COUNTRIES_ARRAY } from "../lang/countries";
import { addressFormGroupStyles } from "./address.styles";

export default function SaveAddress({ address: existingAddress, organizationId, closeModal, refreshAddresses }: { address?: IAddress, organizationId?: string, closeModal: any, refreshAddresses?: any }) {

  const [address, setAddress] = useState(existingAddress ?? newAddress());

  const [ saveAddress, { loading, error } ] = useMutation(SAVE_ADDRESS, { variables: { address: new Address(address).withoutId(), addressId: address._id ?? null } });

  function updateAddress(prop: string, e: any) {
    e.preventDefault();
    setAddress({ ...address, [prop]: e.target.value });
  }

  useEffect(() => {
    if (organizationId) {
      setAddress({ ...address, organizationId });
    }
  }, []);

  if (error) {
    // TODO: handle.
  }

  async function save() {
    saveAddress()
      .then(() => {
        if (!error) {
          if (!address._id && refreshAddresses) {
            refreshAddresses();
          }

          closeModal();
        }
      });
  }

  const isFormValid = () => {
    return !!address.addressee && !!address.line1 && !!address.city && !!address.zipcode && !!address.country;
  };

  const apiKey = process.env.REACT_APP_GOOGLE_PLACES_KEY;

  const [value, setValue] = useState(null);

  function setAddressValue(nextVal: any) {
    setValue(nextVal);
    const line1 = nextVal.value.structured_formatting.main_text;
    const country = nextVal.value.terms[nextVal.value.terms.length - 1].value;
    const province = nextVal.value.terms[nextVal.value.terms.length - 2].value;
    setAddress({ ...address, line1, country, province, city: province });
  }

  return (
    <Grid item>
      <Grid item sx={{ mb: 2 }}>
       <Typography>{ address._id ? 'Save' : 'Add a new ' } address</Typography>
      </Grid>
      <Grid hidden={!error} item sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ color: 'red' }}>There was an error saving your address. Please contact us if the problem persists.</Typography>
      </Grid>
      <Grid item sx={{ mb: 4 }}>
        <GooglePlacesAutocomplete
          apiKey={apiKey}
          autocompletionRequest={{
            componentRestrictions: {
              country: ['mx','ca','us']
            }
          }}
          selectProps={{
            value,
            onChange: setAddressValue,
            placeholder: 'Start typing an addresss...'
          }}
        />
      </Grid>
      <FormControl fullWidth>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <FormGroup sx={addressFormGroupStyles}>
              <TextField
                variant="outlined"
                id="addressee"
                label="Name"
                name="addressee"
                value={address.addressee}
                required={true}
                onChange={(e) => updateAddress("addressee", e)}
              ></TextField>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup sx={addressFormGroupStyles}>
              <TextField
                variant="outlined"
                id="line1"
                label="Address Line 1"
                name="line1"
                value={address.line1}
                required={true}
                onChange={(e) => updateAddress("line1", e)}
              ></TextField>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup sx={addressFormGroupStyles}>
              <TextField
                variant="outlined"
                id="line2"
                label="Address Line 2"
                name="line2"
                value={address.line2}
                required={false}
                onChange={(e) => updateAddress("line2", e)}
              ></TextField>
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <FormGroup sx={addressFormGroupStyles}>
              <TextField
                variant="outlined"
                id="city"
                label="City"
                name="city"
                value={address.city}
                required={true}
                onChange={(e) => updateAddress("city", e)}
              ></TextField>
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <FormGroup sx={addressFormGroupStyles}>
              <TextField
                variant="outlined"
                id="province"
                label="State"
                name="province"
                value={address.province}
                required={true}
                onChange={(e) => updateAddress("province", e)}
              ></TextField>
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <FormGroup sx={addressFormGroupStyles}>
              <TextField
                variant="outlined"
                id="zipcode"
                label="Zip Code"
                name="zipcode"
                value={address.zipcode}
                required={true}
                onChange={(e) => updateAddress("zipcode", e)}
              ></TextField>
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <FormGroup sx={addressFormGroupStyles}>
              <Select
                variant="outlined"
                value={address.country}
                labelId="country"
                label="Country"
                name="country"
                required={true}
                onChange={(e) => updateAddress("country", e)}
              >
                {
                  COUNTRIES_ARRAY.map((val: any, index: number) => {
                    return (
                      <MenuItem key={index} value={val.value}>{ val.name }</MenuItem>
                    )
                  })
                }
              </Select>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup sx={addressFormGroupStyles}>
              <TextField
                variant="outlined"
                id="specialInstructions"
                label="Special Instructions"
                name="specialInstructions"
                value={address.specialInstructions}
                required={false}
                onChange={(e) => updateAddress("specialInstructions", e)}
              ></TextField>
              <FormHelperText>
                For example, a buzzer number, or any other instructions to help
                the driver.
              </FormHelperText>
            </FormGroup>
          </Grid>
        </Grid>
      </FormControl>
      <Grid item sx={{ mt:2 }} textAlign="right">
        <Button disabled={!isFormValid() || loading} variant="contained" onClick={() => save()}>Save</Button>
      </Grid>
    </Grid>
  );
}
