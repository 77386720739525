import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { useAuth0 } from "@auth0/auth0-react";

// Components
import MainMenu from "./MainMenu";
import UserMenu from "./UserMenu";
import LoginButton from "../Login/LoginButton";

export default function HeaderAppBar() {
  const { isAuthenticated } = useAuth0();

  return (
    <div>
      <AppBar position="fixed">
        <Container>
          <Toolbar>
            <MainMenu />
            {isAuthenticated ? (
              <UserMenu />
            ) : (
              <Box sx={{ flexGrow: 0 }}>
                <LoginButton />
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </div>
  );
}
