/**
 *
 * AddFundItem to Cart from the FundPage
 */
import {
  Box,
  Typography,
  Grid,
  Button,
  FormControl,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

// Assets
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { REMOVE_FROM_CART, SET_CART_ITEM_QUANTITY } from "../Cart/cart.queries";
import NumberInput from "../shared/NumberInput";

interface IAddFundItemToCartProps {
  itemId: string;
  fundId: string;
  max: number;
  inCart: number;
}

export default function AddFundItemToCart({
  itemId,
  fundId,
  max,
  inCart,
}: IAddFundItemToCartProps) {
  const cartId = localStorage.getItem("dcid");
  const [quantity, setQuantity] = useState(inCart);
  const [buttonColor, setButtonColor] = useState<"primary"|"secondary">('primary');

  const buttonText = inCart > 0 ? 'UPDATE BASKET' : 'ADD TO BASKET';

  // This is actually setting the quantity instead of add to cart.
  const [addToCart, { data: addToCartResult }] = useMutation(SET_CART_ITEM_QUANTITY, {
    variables: { itemId, quantity, cartId },
  });

  const [mutateRemoveCompletelyFromCart] = useMutation(REMOVE_FROM_CART, {
    variables: {
      cartId,
      fundId,
      itemId,
      quantity: inCart,
    },
  });

  useEffect(() => {
    setQuantity(inCart);
    if (inCart > 0) {
      setButtonColor('secondary');
    }
  }, [inCart]);

  function updateCartItemQuantity() {
    // Don't allow negative values.
    if (quantity < 0) {
      return;
    }

    // If we're updating the cart to 0, remove it from the cart.
    if (quantity === 0) {
      removeFromCart();
    }

    addToCart();
  }

  function removeFromCart() {
    mutateRemoveCompletelyFromCart();
    setButtonColor('primary');
  }

  function onQuantityChange(e: any, value: any) {
    e.preventDefault();

    try {
      const nextValue = Number(value);
      // Do nothing if we got an invalid number or anything below 1.
      if (Number.isNaN(nextValue) || nextValue < 0 || nextValue > max) {
        return;
      }
      setQuantity(nextValue);
      setButtonColor('secondary');
    } catch {}
  }

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <Box pl={2} textAlign="center">
          <FormControl sx={{ width: '100%' }}>
            <NumberInput min={0} max={max} value={quantity} onChange={onQuantityChange} />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box pl={2} mt={2} textAlign="center">
          <Button
            variant="contained"
            disabled={quantity < 1}
            color={buttonColor}
            fullWidth
            sx={{ color: "white" }}
            onClick={() => updateCartItemQuantity()}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              component="span"
              sx={{ color: "white", display: { xs: "none", md: "inline" } }}
              pr={1}
            >
              {buttonText}
            </Typography>
            <AddShoppingCartIcon sx={{ color: "white" }} />
          </Button>
          <div hidden={inCart < 1}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => removeFromCart()}
              sx={{ mt: 1 }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                component="span"
                pr={1}
                sx={{ display: { xs: "none", md: "inline" } }}
              >
                Remove from basket
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                component="span"
                pr={1}
                sx={{ display: { xs: "inline", md: "none" } }}
              >
                Remove
              </Typography>
            </Button>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
}
