import { Box } from "@mui/material";
import FacebookShare from "./FacebookShare";
import TwitterShare from "./TwitterShare";
import EmailShare from "./EmailShare";
import WhatsAppShare from "./WhatsAppShare";

export interface ISocialShareProps {
  url: string;
  quote: string;
  title: string;
  emailSubject: string;
  emailBody: string;
}

const buttonStyles = { display: 'inline-block', margin: '0.25rem' };

export default function SocialShare({ url, quote, title, emailSubject, emailBody } : ISocialShareProps) {
  const buttons = [
    (<FacebookShare url={url} quote={quote} />),
    (<TwitterShare url={url} title={title} />),
    (<WhatsAppShare url={url} title={title} />),
    (<EmailShare url={url} subject={emailSubject} body={emailBody} />)
  ];

  return (
    <Box width="100%" sx={{ mt: 1, mb: 1 }}>
      <Box width="100%" textAlign="left">
        {
          buttons.map((button: any, index: number) => (
            <Box key={index} sx={buttonStyles}>
              {button}
            </Box>
          ))
        }
      </Box>
    </Box>
  );
};
