import { WhatsappShareButton, WhatsappIcon } from 'react-share';

export default function WhatsAppShare({ url, title, body }: { url: string, title: string , body?: string}) {

  return (
    <WhatsappShareButton
      url={url}
      title={title}
    >
      <WhatsappIcon size={32} round />
    </WhatsappShareButton>
  );
};
