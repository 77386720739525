import { gql } from "@apollo/client";

export const ADDRESS_FIELDS = gql`
  fragment AddressFields on Address {
    _id
    addressee
    line1
    line2
    city
    province
    country
    zipcode
    region
    specialInstructions
  }
`;

export const GET_ADDRESSES_BY_ORG = gql`
  query GetAddressesByOrg($orgId: ID) {
    addressesByOrg(orgId: $orgId) {
      _id
      addressee
      line1
      line2
      city
      country
      zipcode
      province
      specialInstructions
      organizationId
    }
  }
`;

export const GET_ADDRESSES_BY_USER = gql`
  query GetAddressesByUser {
    addressesByUser {
      _id
      addressee
      line1
      line2
      city
      country
      zipcode
      province
      specialInstructions
      userId
    }
  }
`;

export const SAVE_ADDRESS = gql`
  mutation SaveAddress($address: AddressInput, $addressId: ID) {
    saveAddress(address: $address, addressId: $addressId) {
      _id
      addressee
      line1
      line2
      city
      country
      zipcode
      province
      specialInstructions
      userId
      organizationId
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($addressId: ID!) {
  deleteAddress(addressId: $addressId) {
    success
    errorMessage
  }
}
`;
