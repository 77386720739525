/**
 *
 * HowItWorksChooseFund
 */
import { Box, Typography } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { homeStyles } from "./home-styles";

export default function HowItWorksChooseItemsSent() {
  return (
    <div>
      <Box sx={homeStyles.icons}>
        <MailOutlineIcon fontSize="large" />
      </Box>
      <Typography color="primary" component="h5" variant="h5" sx={homeStyles.titles}>
        Items are sent to the fundraiser.
      </Typography>
      <Typography paragraph variant="body1" sx={homeStyles.descriptions}>
        You pay on our platform, we handle the rest.
      </Typography>
    </div>
  );
}
