/**
 *
 * A ListItem for the Funds list.
 */
import { Typography, CardActionArea, CardHeader, Avatar } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link as RouterLink } from "react-router-dom";

import FundModel from "./fund.model";
import MaxLengthText from "../shared/MaxLengthText";
import { displayNewLineStyles } from "../shared/displayNewLine.styles";

interface IFundItemProps {
  fund: FundModel;
}

// FundCard
export default function Fund({ fund }: IFundItemProps) {
  const defaultUrl: string =
    "https://mui.com/static/images/cards/contemplative-reptile.jpg";
  const imageUrl: string = fund.imageUrl || defaultUrl;

  const defaultDescription: string = "No description.";
  const description: string = fund.description || defaultDescription;

  return (
    <Card sx={{ maxWidth: 345, margin: "0 auto" }}>
      <CardHeader
        avatar={
          <Avatar src={fund?.organization?.logoUrl} aria-label="recipe">
          </Avatar>
        }
        title={fund?.organization?.name ?? fund?.user?.givenName}
        subheader={fund?.name}
        titleTypographyProps={{ color: "#1a2877" }}
        subheaderTypographyProps={{ color: "#1a2877", fontWeight: "bold" }}
      />
      <CardActionArea component={RouterLink} to={`/donate/${fund._id}`}>
        <CardMedia
          component="img"
          height="140"
          image={imageUrl}
          alt={fund.name}
        />
        <CardContent>
          <Typography gutterBottom variant="body2" color="text.secondary" sx={displayNewLineStyles}>
            <MaxLengthText maxLength={200} text={description} />
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
