export function capitalizeString(value: string|undefined|null) {
  if (!value) return;
  return value.toLowerCase().replace(/([^a-z]|^)([a-z])(?=[a-z]{1})/g, function(_, g1, g2) {
    return g1.toUpperCase() + g2.toUpperCase(); } );
}

export function capitalizeStringTwo(value: string|undefined|null) {
  if (!value) return;
  return value.toLowerCase().replace(/([^a-z]|^)([a-z])(?=[a-z]{1})/g, function(_, g1, g2) {
    return g1.toUpperCase() + g2.toUpperCase(); } );
}