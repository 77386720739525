import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import '../Layout/NavBarButton.css';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Typography className="NavBarButton" onClick={() => logout({ returnTo: window.location.origin })} color="primary">
      Log Out
    </Typography>
  );
};

export default LogoutButton;
