import { Box, Typography } from "@mui/material";
import { IDonation } from "../Donations/donation.model";

// import LoadingError from "../Layout/LoadingError";
// import Loading from "../Layout/Loading";

/**
 *
 */
export default function LatestDonation({ donation }: { donation?: IDonation }) {
  const noDonation = (<Typography component="p" variant="body1">You haven't received any donations, yet.</Typography>);

  const donationSummary = (
    <Box>
      <Typography>Total items: { donation?.items?.length }</Typography>
      <Typography>Donation Date: { donation?.createdAt }</Typography>
      <Typography>Donor: </Typography>
    </Box>
  );

  return (
    <Box>
      <Typography variant="h5" color="primary">Latest Donation</Typography>
      {
        donation ? donationSummary : noDonation
      }
    </Box>
  );
}
