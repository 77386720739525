import { gql } from "@apollo/client";

export const FUND_FIELDS = gql`
  fragment FundFields on Fund {
    _id
    name
    isWishlist
    address {
      country
    }
    organization {
      _id
      name
      uuid
    }
    region
  }
`;

export const GET_CAMPAIGNS = gql`
  query GetCampaigns($page: Int) {
    publicCampaigns(page: $page) {
      page
      hasMore
      funds {
        _id
        name
        imageUrl
        description
        region
        user {
          givenName
        }
        organization {
          name
          uuid
          logoUrl
        }
      }
    }
  }
`;

export const GET_WISHLISTS = gql`
  query GetWishlists($page: Int) {
    publicWishlists(page: $page) {
      page
      hasMore
      funds {
        _id
        name
        imageUrl
        description
        region
        user {
          givenName
        }
        organization {
          name,
          logoUrl,
          description,
          uuid
        }
      }
    }
  }
`;

export const GET_FUND = gql`
  query GetFund($fundId: ID) {
    fund(fundId: $fundId) {
      _id
      name
      imageUrl
      description
      isPublished
      region
      address {
        ...AddressFields
      }
      items {
        _id
        raised
        totalGoal
        item {
          ...ItemFields
        }
      }
      user {
        givenName
      }
      organization {
        name
        uuid
      }
    }
  }
`;

export const GET_WISHLIST = gql`
  query GetWishlist($region: String, $orgUuid: ID) {
    wishlist(region: $region, orgUuid: $orgUuid) {
      _id
      addressId
      isWishlist
      region
      items {
        _id
        item {
          ...ItemFields
        }
      }
    }
  }
`;

export const CREATE_WISHLIST = gql`
  mutation CreateWishlist($region: String, $orgUuid: ID) {
    createWishlist(region: $region, orgUuid: $orgUuid) {
      _id
      addressId
      isWishlist
      region
      items {
        _id
        item {
          ...ItemFields
        }
      }
    }
  }
`;

export const SAVE_CAMPAIGN = gql`
  mutation SaveCampaign($campaign: FundInput, $orgUuid: ID) {
    saveCampaign(campaign: $campaign, orgUuid: $orgUuid) {
      _id
      uuid
      name
      imageUrl
      description
      addressId
      isPublished
      region
      items {
        _id
        raised
        totalGoal
        item {
          ...ItemFields
        }
      }
    }
  }
`;

export const ADD_ITEM_TO_FUND = gql`
  mutation AddItemToFund($fundId: ID, $itemId: ID, $quantity: Int) {
    addItemToFund(fundId: $fundId, itemId: $itemId, quantity: $quantity) {
      success,
      errorMessage
    }
  }
`;

export const REMOVE_ITEM_FROM_FUND = gql`
  mutation RemoveItemFromFund($fundId: ID, $fundItemId: ID) {
    removeItemFromFund(fundId: $fundId, fundItemId: $fundItemId) {
      success,
      errorMessage
    }
  }
`;

export const GET_USER_FUNDS = gql`
  query GetUserFunds {
    fundsByUser {
      _id
      name
      imageUrl
      description
      isPublished
      region
      canDelete
      items {
        _id
        raised
        totalGoal
        item {
          ...ItemFields
        }
      }
    }
  }
`;

export const GET_ORG_FUNDS = gql`
  query GetFundsByOrg($orgId: ID) {
    fundsByOrg(orgId: $orgId) {
      _id
      name
      imageUrl
      description
      isPublished
      canDelete
      items {
        _id
        raised
        totalGoal
        item {
          ...ItemFields
        }
      }
      user {
        givenName
      }
    }
  }
`;

export const DELETE_FUND = gql`
  mutation DeleteFund($fundId: ID) {
    deleteFund(fundId: $fundId) {
      success
      errorMessage
    }
  }
`;

export const PUBLISH_FUND = gql`
  mutation PublishFund($fundId: ID) {
    publishFund(fundId: $fundId) {
      success
      errorMessage
    }
  }
`;

export const UNPUBLISH_FUND = gql`
  mutation UnpublishFund($fundId: ID) {
    unpublishFund(fundId: $fundId) {
      success
      errorMessage
    }
  }
`;