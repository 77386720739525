/**
 *
 * Donate Page - lists some campaigns and wishlists.
 */
import { Container, Grid, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import CampaignList from "./CampaignList";
// import WishlistList from "./WishlistList";
import OrganizationList from "../Organizations/OrganizationList";

export default function DonatePage() {
  const [ tab, setTab ] = useState(0);

  function onSwitchTab(_: React.SyntheticEvent, newValue: number) {
    setTab(newValue);
  }

  return (
    <Container sx={{ pt: 4 }}>
      <Grid container>
        <Tabs value={tab} onChange={onSwitchTab}>
          <Tab label="Campaigns" id="campaigns" />
          <Tab label="Organizations" id="organizations" />
        </Tabs>
      </Grid>

      <Grid container sx={{ mb: 2 }}>
        {
          tab === 0 ?
          (<CampaignList />) :
          (<OrganizationList />)
        }
      </Grid>
    </Container>
  );
}
