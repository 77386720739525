import { Button, Grid, Typography } from "@mui/material";
import {
  AddressElement,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";

// Docs: https://stripe.com/docs/payments/quickstart?client=react&lang=node

export default function CheckoutPayment({
  clientSecret,
  billingName
}: {
  clientSecret: string;
  billingName: string;
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe, clientSecret]);

  async function payNow(e: any) {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded. Disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/confirm`,
        // SAMPLE:
        //http://localhost:3000/?payment_intent=pi_3M1CZJHJw0bFi6xF1tgcHzft&payment_intent_client_secret=pi_3M1CZJHJw0bFi6xF1tgcHzft_secret_2MBJxruZzjpXiFB30Q1Ir9WqD&redirect_status=succeeded
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(
        error.message ??
          "Something went wrong with your payment method. Please try again, or contact support."
      );
    } else {
      setMessage("An unexpected error occurred. Please contact support.");
    }

    setIsLoading(false);
  }

  return (
    <Grid item sx={{ mt: 2, padding: 2 }} xs={12}>
      <Typography paragraph>Billing Address</Typography>
      <AddressElement
        options={{ mode: 'billing', defaultValues: { name: billingName } }}
        id="address-element"
      />
      <Typography sx={{ mt: 3 }} paragraph>Payment</Typography>
      <PaymentElement id="payment-element" />
      <Button
        sx={{ mt: 2 }}
        disabled={isLoading || !stripe || !elements}
        onClick={payNow}
        variant="contained"
        color="secondary"
      >
        {isLoading ? "Processing..." : "Pay Now"}
      </Button>
      {message ? <Typography sx={{ mt: 2 }}>{message}</Typography> : ""}
    </Grid>
  );
}
