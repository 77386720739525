/**
 *
 * A ListItem for the Funds list.
 */
import LinearProgress, {
} from "@mui/material/LinearProgress";

interface IFundItemProps {
  raised: number;
  totalGoal: number;
  inCart: number;
}

export default function FundItemProgress({
  raised,
  totalGoal,
  inCart
}: IFundItemProps) {
  const value = Math.floor((raised / totalGoal) * 100);
  const buffer = Math.floor((inCart / totalGoal) * 100);

  return <LinearProgress variant="buffer" value={value} valueBuffer={buffer} color="primary" sx={{ height: '10', '.MuiLinearProgress-dashed': { animation: '0s' } }} />;
}
