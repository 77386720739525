import { Typography, Container, Grid, CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <Container sx={{ pt: 4 }}>
      <Grid container>
        <Grid item xs={12} textAlign="center" sx={{  height: '100%' }}>
          <Typography color="primary" align="center" textAlign="center" sx={{ mt: 4, mb: 4 }}>
            Loading...
          </Typography>
          <CircularProgress color="primary" disableShrink />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Loading;
