import { Typography, Box, Button } from "@mui/material";

import { IDonation } from "../Donations/donation.model";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

/**
 *
 * Summary on the Donation Page.
 */
export default function UserLatestDonation({ donation }: {donation?: IDonation}) {
  const navigate = useNavigate();
  // Use a negative number.
  const [dateDiff, setDateDiff] = useState<number>(-1);
  const text = getCTAText();

  useEffect(() => {
    if (donation) {
      const diff = Date.now() - new Date(donation.createdAt).getTime();
      setDateDiff(diff);
    }
  }, [donation]);


  function getCTAText(): string {
    if (!donation) return "You haven't donated anything, yet. Click the pink Donate button above and browse some current campaigns.";

    const thirtyDays = 1000 * 60 * 60 * 60 * 24 * 30;

    if (dateDiff > thirtyDays) {
      return "You haven't donated in a while, would you like to check out current drives or browse some wishlists?"
    }

    // TODO - add social media shares?
    return "🙌 Woohoo! Thank you for making a positive impact with your recent donation.";
  }

  function handleClick() {
    if (!donation) {
      return  navigate('/donate');
    }

    // Go to donation page.
    navigate(`/dashboard/donations/${donation._id}`);
  }

  return (
    <Box>
      <Typography sx={{ mb: 2 }} component="p" variant="body1">{text}</Typography>

      <Button color="secondary" variant="contained" onClick={handleClick}>
        {
          donation ? 'View Donation' : 'Donate Now'
        }
      </Button>
    </Box>
  );
}
