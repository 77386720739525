import { useQuery } from "@apollo/client";
import { Button, Box, Grid, Typography } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";

import { GET_DONATIONS_BY_ORG } from "./donation.queries";

import LoadingError from "../Layout/LoadingError";
import Loading from "../Layout/Loading";
import { IDonation } from "./donation.model";
import FundDonationSummary from "./FundDonationSummary";
import { useState } from "react";
import { Modal } from "@mui/base";
import { addFundItemModalStyle } from "../Funds/Steps/add-fund-item.styles";
import DonationDetailModal from "./DonationDetailModal";

/**
 * A list of donations the Org has received, regardless of campaign.
 */
export default function OrganizationReceivedDonationsPage() {
  const params = useParams();
  const { organizationId } = params;
  const { loading, error, data } = useQuery(GET_DONATIONS_BY_ORG, { variables: { orgId: organizationId }  });

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [donation, setDonation] = useState<any>(null);
  function viewDonation(d: IDonation) {
    setDonation(d);
    handleOpenModal();
  }

  if (loading) return <Loading />;
  if (error) return <LoadingError />;

  const donations = data.donationsByOrg ?? [];

  // TODO: CTA to donate if no donations yet.

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5">Your Donations</Typography>
      </Grid>
      {
        donations.length < 1 ?
        (
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ mb: 2 }}>You haven't received any donations, yet. Why not share your campaigns or Donatic page?</Typography>

          <Button
            variant="contained"
            color="secondary"
            component={RouterLink} to="/donate"
          >TODO: Social share.</Button>
        </Grid>
        ) : (
          <Grid item xs={12}>
            {(
              donations.map((donation: any, index: number) => (<FundDonationSummary key={index} donation={donation} viewDonation={viewDonation} />))
            )}
          </Grid>
        )
      }
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={addFundItemModalStyle}>
          <DonationDetailModal donationId={donation?._id} closeModal={handleCloseModal} />
        </Box>
      </Modal>
    </Grid>
  );
}
