import { gql } from "@apollo/client";

export const LIST_DONATIONS = gql`
  query Donations($page: Int, $keywords: String) {
    donations (page: $page, keywords: $keywords) {
      page
      hasMore
      donations {
        _id
        currency
        createdAt
        serviceFee
        total
        status
        deliveryStatus
        shortCode
        user {
          _id
          givenName
          email
        }
        fund {
          ...FundFields
        }
      }
    }
  }
`;

export const ADMIN_DONATION_DETAIL = gql`
  query Donations($donationId: ID) {
    donation(donationId: $donationId) {
      _id
      createdAt
      shortCode
      currency
      subtotal
      serviceFee
      taxes
      total
      status
      deliveryStatus
      thankYouNoteSentAt
      thankYouNote

      user {
        _id
        givenName
        email
      }
      items {
        _id
        itemId
        item {
          ...ItemFields
        }
        quantity
      }
      fund {
      ...FundFields
        address {
          ...AddressFields
        }
      }

      vendorOrderDonations {
        _id
        vendorOrder {
          ...VendorOrderFields
        }
        ...VendorOrderDonationFields
      }
    }
  }
`;

export const MARK_DONATION_SENT = gql`
mutation MarkDonationSent($donationId: ID!, $date: String!) {
  markDonationSent(donationId: $donationId, date: $date) {
    _id
    createdAt
    fund {
      _id
      name
      isWishlist
      organization {
        name
        uuid
      }
    }
    items {
      item {
        ...ItemFields
      }
      quantity
      value
    }
    currency,
    subtotal,
    serviceFee,
    taxes,
    total,
    status,
    deliveryStatus
  }
}
`;

export const MARK_DONATION_DELIVERED = gql`
mutation MarkDonationDelivered($donationId: ID!) {
  markDonationDelivered(donationId: $donationId) {
    _id
    createdAt
    fund {
      _id
      name
      isWishlist
      organization {
        name
        uuid
      }
    }
    items {
      item {
        ...ItemFields
      }
      quantity
    }
    currency,
    subtotal,
    serviceFee,
    taxes,
    total,
    status,
    deliveryStatus
  }
}
`;