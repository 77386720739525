/**
 *
 * A ListItem for the Funds list.
 */
import { Typography, CardActions, Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import FundModel from "./fund.model";
import { FiberManualRecord, Warning } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { buildDashPath } from "../Dashboard/build-dash-path";
import MaxLengthText from "../shared/MaxLengthText";
import { displayNewLineStyles } from "../shared/displayNewLine.styles";

interface IFundItemProps {
  fund: FundModel;
}

export default function CampaignDashboardSummary({ fund }: IFundItemProps) {
  const navigate = useNavigate();
  const params = useParams();

  const defaultUrl: string =
    "https://mui.com/static/images/cards/contemplative-reptile.jpg";
  const imageUrl: string = fund.imageUrl || defaultUrl;

  const defaultDescription: string = "No description.";
  const description: string = fund.description || defaultDescription;

  function goToViewCampaign() {
    const path = buildDashPath({ params, nextPath: 'campaigns' });
    
    navigate(`${path}/${fund._id}`);
  }

  return (
    <Card sx={{ maxWidth: 345, margin: "0 auto" }}>
      <CardMedia
        component="img"
        height="140"
        image={imageUrl}
        alt={fund.name}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" color="primary" component="div">
          {fund.name} { fund.isPublished ? (<FiberManualRecord color="success" />) : '' }
        </Typography>
        <Typography gutterBottom variant="body2" color="text.secondary" sx={displayNewLineStyles}>
          <MaxLengthText text={description} maxLength={200} />
        </Typography>
        {
          fund.isPublished ? '' : (
            <Typography paragraph gutterBottom variant="body2">
              <Warning color="warning" /> This campaign is not live.
            </Typography>
          )
        }
      </CardContent>
      <CardActions>
        <Button fullWidth variant="outlined" size="small" color="primary" onClick={goToViewCampaign}>
          View
        </Button>
      </CardActions>
    </Card>
  );
}
